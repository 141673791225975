import { RangeFilter } from '../types'
import moment from 'moment'

export const cleanRangeFilter = (filter: RangeFilter): Object | null => {
  if (filter.lte || filter.gte) {
    return {
      ...(filter.lte && { lte: filter.lte }),
      ...(filter.gte && { gte: filter.gte }),
    }
  }
  return null
}

export const doesFilterMatch = (a: RangeFilter, b: RangeFilter): boolean => {
  if (a.lte === b.lte && a.gte === b.gte) {
    return true
  }
  return false
}

export const getYearRange = (min: number, max: number) => {
  const years = []
  const dateStart = moment(min, 'YYYY')
  const dateEnd = moment(max, 'YYYY')

  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(Number(dateStart.format('YYYY')))
    dateStart.add(1, 'year')
  }
  
  return years
}
