import React from 'react'
import { Collapse as AntCollapse } from 'antd'
import { CollapseProps } from 'antd/lib/collapse'
import styled from 'styled-components'

const Panel = AntCollapse.Panel

const Collapse: React.FC<CollapseProps> = ({ ...rest }) => {
  return <Wrapper {...rest}></Wrapper>
}

const Wrapper = styled(AntCollapse)`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 0;
  background-color: transparent;

  .ant-collapse {
    &-item {
      background-color: #1d1e1e;
      padding: 20px;
      border-bottom: none;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      &:last-child {
        border: none;

        & > .ant-collapse-header,
        & > .ant-collapse-content {
          border-radius: 0 !important;
        }
      }
    }

    &-header {
      padding: 0 !important;
      color: #fff !important;
    }

    &-content {
      margin-top: 15px;
      border-top-color: #2c2c2c;
      background-color: #1d1e1e;
      color: #fff;
    }

    &-icon-position-right {
      .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        right: 0;
        font-size: 18px;
      }
    }
  }
`

export { Collapse, Panel }
