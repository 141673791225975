import React from 'react'
import { Tabs } from 'antd'
import { FormattedMessage } from 'react-intl'
import { OptionProps } from 'antd/lib/select'
import { useIntl } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { TabPane } from '../TabPane'
import { Select } from '../'
import { useObserver } from 'mobx-react'

interface VehicleTypeFilterProps {
  type: 'dropdown' | 'tabs'
  children?: any
}

const VehicleTypeFilter: React.FC<VehicleTypeFilterProps> = ({ type, children }) => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onTabChange = (key: string) => {
    filterStore.setUtilityVehicleFilter(key === 'utilityVehicle')
  }

  const options: OptionProps[] = [
    { label: 'car', value: 'pkw' },
    { value: 'nfz', label: 'utilityVehicle' },
  ].map((o) => ({
    ...o,
    ...{
      label: formatMessage({ id: o.label }),
    },
  }))

  return useObserver(() => {
    return type === 'dropdown' ? (
      <Select<string>
        style={{ width: '100%' }}
        onChange={(type: any) => (filterStore.filters.utilityVehicle = type === 'nfz')}
        value={filterStore.filters.utilityVehicle ? 'nfz' : 'pkw'}
        placeholder={<FormattedMessage id="vehicleType" />}
        options={options}
      />
    ) : (
      <Tabs type="card" onChange={onTabChange}>
        <TabPane tab={<FormattedMessage id="car" />} key="car">
          {children}
        </TabPane>
        <TabPane tab={<FormattedMessage id="utilityVehicle" />} key="utilityVehicle">
          {children}
        </TabPane>
      </Tabs>
    )
  })
}

export { VehicleTypeFilter }
