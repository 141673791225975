import styled from 'styled-components'
import { Badge as AntBadge } from 'antd'

const Badge = styled(AntBadge)`
  display: block;

  & > .custom-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;

    i {
      position: relative;
      transform: none;
    }
  }

  & > .custom-icon,
  .ant-badge-count {
    box-shadow: none;
    background-color: #1daeee;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
  }
`

export { Badge }
