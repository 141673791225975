import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { PowerType } from '../../types'
import { useFilterStore } from '../../hooks'
import { Page, Header, PowerFilterTab, Tabs, TabPane, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }
`

interface PowerFilterPageProps {}

const PowerFilterPage: React.FC<PowerFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onTabChange = (key: string) => {
    let payload: PowerType
    switch (key) {
      case 'KW':
        payload = PowerType.KW
        break
      case 'PS':
        payload = PowerType.PS
        break
      default:
        payload = PowerType.PS
        break
    }

    if (payload) {
      filterStore.setPowerFilterType(payload)
    }
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="powerFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <Tabs type="card" onChange={onTabChange}>
          <TabPane tab={<FormattedMessage id="ps" />} key={PowerType.PS}>
            <PowerFilterTab />
          </TabPane>
          <TabPane tab={<FormattedMessage id="kw" />} key={PowerType.KW}>
            <PowerFilterTab />
          </TabPane>
        </Tabs>
      </Wrapper>
    </Page>
  ))
}

export { PowerFilterPage }
