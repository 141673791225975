import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { OptionProps } from 'antd/lib/select'

import { Col, Row } from 'antd'
import { Form, InputGroup, FormItem, Select } from '../'
import { RangeFilter } from '../../types'
export interface RangeFilterFormDropdownProps extends FormComponentProps {
  onChange: (value: RangeFilter) => void
  unit?: string
  value?: RangeFilter
  values: number[]
  label: string | React.ReactNode
  formatValue?: boolean
}

const RangeFilterFC = forwardRef<FormComponentProps, RangeFilterFormDropdownProps>(
  ({ form, onChange, value, values, unit, label, formatValue }, ref) => {
    useImperativeHandle(ref, () => ({
      form,
      onChange,
    }))

    const { formatMessage, formatNumber } = useIntl()

    const options: OptionProps[] = values
      .map((opt) => ({ value: opt, label: opt }))
      .map((o) => ({ ...o, ...{ label: `${formatValue ? formatNumber(o.label) : o.label} ${unit ? unit : ''}` } }))

    options.unshift({ value: undefined, label: formatMessage({ id: 'filterAny' }) })

    useEffect(() => {
      if (value) {
        const current = form.getFieldsValue()
        if (value.lte !== current.lte || value.gte !== current.gte) {
          form.setFieldsValue(value)
        }
      }
    })

    return (
      <Wrapper>
        <Form>
          <InputGroup>
            <Row type="flex" gutter={12}>
              <Col xs={12}>
                <FormItem label={label}>
                  {form.getFieldDecorator('gte')(<Select<number> placeholder={formatMessage({ id: 'from' })} options={options} />)}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem>{form.getFieldDecorator('lte')(<Select placeholder={formatMessage({ id: 'to' })} options={options} />)}</FormItem>
              </Col>
            </Row>
          </InputGroup>
        </Form>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  .ant-row-flex {
    .ant-col {
      &:last-child {
        .ant-form-item {
          padding-top: 39px;
        }
      }
    }
  }
`

const RangeFilterFormDropdown = Form.create<RangeFilterFormDropdownProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(RangeFilterFC)

RangeFilterFC.defaultProps = {
  formatValue: false,
}

export { RangeFilterFormDropdown }
