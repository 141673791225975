import React from 'react'
import styled from 'styled-components'
import { Icon as AntIcon } from 'antd'

interface IconProps {
  type: string
  rotate?: number
  className?: string
}

const Icon: React.FC<IconProps> = ({ type, className, rotate }) => {
  const svgStyle = rotate
    ? {
        msTransform: `rotate(${rotate}deg)`,
        transform: `rotate(${rotate}deg)`,
      }
    : undefined

  const arrowRight = () => (
    <svg className="arrow-right" style={svgStyle} width="17" height="28" viewBox="0 0 17 28" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" d="M2.848 0L0 2.878l10.587 10.691L0 24.262l2.849 2.877L16.284 13.57v-.002L2.85 0z" fillRule="evenodd" />
    </svg>
  )

  const arrowLeft = () => (
    <svg className="arrow-left" style={svgStyle} width="17px" height="28px" viewBox="0 0 17 28" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(8.500000, 14.000000) rotate(-180.000000) translate(-8.500000, -14.000000) " fill="#fff" fillRule="nonzero">
        <polygon points="2.848 0 0 2.878 10.587 13.569 0 24.262 2.849 27.139 16.284 13.57 16.284 13.568 2.85 0"></polygon>
      </g>
    </svg>
  )

  const trash = () => (
    <svg className="trash" style={svgStyle} width="33px" height="38px" viewBox="0 0 33 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <polygon points="0.000293587713 0.5597 33 0.5597 33 9.3417 0.000293587713 9.3417"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.000000, -1.000000)">
          <g transform="translate(0.000000, 0.440700)">
            <mask fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>

            <path
              d="M32.0893,5.7337 C31.5002936,5.1457 30.7163,4.8207 29.8823,4.8197 L21.9563,4.8057 L21.8453,3.3727 C21.8353,2.6297 21.5393,1.9317 21.0113,1.4037 C20.4733,0.8667 19.7553,0.5687 18.9913,0.5677 L14.0343,0.5597 L14.0293,0.5597 C13.2673,0.5597 12.5513,0.8537 12.0113,1.3887 C11.4813,1.9147 11.1843,2.6107 11.1713,3.3547 L11.0553,4.7877 L3.1243,4.7737 C1.4043,4.7737 0.0033,6.1657 0.0013,7.8767 L0.000293587713,8.1867 C-0.0007,8.4787 0.1153,8.7587 0.3223,8.9647 C0.5283,9.1717 0.8093,9.2877 1.1033,9.2887 L31.8923,9.3417 L31.8943,9.3417 C32.5033,9.3417 32.9973,8.8507 32.9993,8.2447 L33.0003092,7.9337 C33.0023,7.1037 32.6773,6.3227 32.0893,5.7337 L32.0893,5.7337 Z M13.2113,3.4827 C13.2143,3.4557 13.2153,3.4287 13.2153,3.4027 C13.2153,3.1857 13.3003,2.9817 13.4543,2.8287 C13.6083,2.6767 13.8123,2.5927 14.0293,2.5927 L14.0313,2.5927 L18.9893,2.6017 C19.2073,2.6017 19.4113,2.6867 19.5643,2.8397 C19.7183,2.9927 19.8033,3.1967 19.8023,3.4137 C19.8023,3.4407 19.8033,3.4667 19.8053,3.4937 L19.9073,4.8037 L13.1053,4.7907 L13.2113,3.4827 Z"
              fill="#FFFFFF"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M30.3761,10.9177 L2.4121,10.9177 C1.5511,10.9177 0.8531,11.6127 0.8531,12.4687 C0.8531,13.3257 1.5511,14.0207 2.4121,14.0207 L2.6771,14.0207 L5.6841,37.0747 C5.7861,37.8567 6.4541,38.4407 7.2451,38.4407 L25.4901,38.4407 C26.2821,38.4407 26.9501,37.8547 27.0511,37.0737 L30.0151,14.0207 L30.3761,14.0207 C31.2371,14.0207 31.9351,13.3267 31.9351,12.4697 C31.9341,11.6127 31.2361,10.9177 30.3761,10.9177 L30.3761,10.9177 Z M10.3701,34.9087 C10.3531,34.9097 10.3351,34.9097 10.3181,34.9097 C9.8351,34.9097 9.4311,34.5337 9.4041,34.0477 L8.6701,20.9947 C8.6421,20.4907 9.0291,20.0607 9.5341,20.0327 C10.0401,20.0047 10.4721,20.3897 10.5011,20.8927 L11.2341,33.9467 C11.2621,34.4487 10.8761,34.8797 10.3701,34.9087 L10.3701,34.9087 Z M17.3111,33.9967 C17.3111,34.5007 16.9001,34.9097 16.3941,34.9097 C15.8871,34.9097 15.4771,34.5007 15.4771,33.9967 L15.4771,20.9437 C15.4771,20.4397 15.8871,20.0317 16.3941,20.0317 C16.9001,20.0317 17.3111,20.4397 17.3111,20.9437 L17.3111,33.9967 Z M24.1181,20.9947 L23.3851,34.0477 C23.3571,34.5337 22.9531,34.9097 22.4701,34.9097 C22.4531,34.9097 22.4351,34.9097 22.4181,34.9087 C21.9131,34.8797 21.5251,34.4487 21.5531,33.9457 L22.2871,20.8917 C22.3151,20.3887 22.7491,20.0027 23.2541,20.0317 C23.7601,20.0607 24.1461,20.4907 24.1181,20.9947 L24.1181,20.9947 Z"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  )

  const sort = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#5d5e5f" width="186.48" height="156.06" viewBox="0 0 186.48 156.06">
      <rect x="53.31" y="133.14" width="79.87" height="22.92" />
      <rect x="26.65" y="66.57" width="133.18" height="22.92" />
      <rect width="186.48" height="22.92" />
    </svg>
  )

  const customFilter = () => (
    <svg
      className="filter"
      width="32px"
      height="32px"
      style={svgStyle}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        d="M25,11.9c0-1.7-1.1-3.1-2.7-3.5V5.6h-1.9v2.9c-1.5,0.4-2.7,1.8-2.7,3.5c0,1.7,1.1,3.1,2.7,3.5v11.9h1.9V15.4
    C23.9,15,25,13.6,25,11.9z M21.4,14.1c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C23.5,13.1,22.6,14.1,21.4,14.1
    z"
      />
      <path
        d="M14.8,21c0-1.7-1.1-3.1-2.7-3.5V5.6h-1.9v11.9c-1.5,0.4-2.7,1.8-2.7,3.5c0,1.7,1.1,3.1,2.7,3.5v2.9h1.9v-2.9
    C13.7,24,14.8,22.6,14.8,21z M11.2,23.1C10,23.1,9,22.2,9,21s1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2S12.4,23.1,11.2,23.1z"
      />
    </svg>
  )

  const telephone = () => (
    <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" width="181" height="181" viewBox="0 0 181 181">
      <path
        d="M97.2,138.19a158.29,158.29,0,0,0,66.61,66.61L186,182.6a9.4,9.4,0,0,1,4.83-2.9,8.21,8.21,0,0,1,5.55.49q15.93,5.55,35.72,5.54a9.9,9.9,0,0,1,9.89,9.9v35.48a9.88,9.88,0,0,1-9.89,9.89,169,169,0,0,1-66.61-13.51A171.2,171.2,0,0,1,61,69.9,9.88,9.88,0,0,1,70.9,60h35.47a9.88,9.88,0,0,1,9.9,9.9q0,19.78,5.55,35.71a12.91,12.91,0,0,1,.24,5.55A8.1,8.1,0,0,1,119.4,116Z"
        transform="translate(-61 -60)"
      />
    </svg>
  )

  const mail = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="192.73" height="154.74" viewBox="0 0 192.73 154.74">
      <path
        d="M227,111.52V92.06l-77.37,48.41L72.26,92.06v19.46l77.37,48Zm0-38.92a18,18,0,0,1,13.44,5.79A19.07,19.07,0,0,1,246,92.06V207.88a19.07,19.07,0,0,1-5.56,13.67A18,18,0,0,1,227,227.34H72.26a17.94,17.94,0,0,1-13.43-5.79,19.07,19.07,0,0,1-5.56-13.67V92.06a19.07,19.07,0,0,1,5.56-13.67A18,18,0,0,1,72.26,72.6Z"
        transform="translate(-53.27 -72.6)"
      />
    </svg>
  )

  const finance = () => (
    <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" width="128.8" height="128.8" viewBox="0 0 128.8 128.8">
      <path
        d="M147.16,189.1a45.8,45.8,0,0,0,40.7,4,47.7,47.7,0,0,0,13.91-8.33l12.71,12.7a61.71,61.71,0,0,1-19.66,12.2A63.92,63.92,0,0,1,171.55,214a64.3,64.3,0,0,1-60.63-42.93H85.68V156.64h21.81a53.61,53.61,0,0,1-.34-7,53.61,53.61,0,0,1,.34-7H85.68V128.13h25.24A63.37,63.37,0,0,1,124.15,106a61.58,61.58,0,0,1,21.12-15.11,63,63,0,0,1,26.28-5.67,63.92,63.92,0,0,1,23.27,4.29,61.88,61.88,0,0,1,19.66,12.2l-12.71,12.7a48.78,48.78,0,0,0-14-8.41,43.83,43.83,0,0,0-16.23-3.09,45.42,45.42,0,0,0-24.39,7,46,46,0,0,0-16.83,18.2h41.22v14.43h-46l-.34,3.43c-.12,1.49-.17,2.7-.17,3.61s0,2.06.17,3.43l.34,3.61h46v14.43H130.33a44.79,44.79,0,0,0,16.83,18"
        transform="translate(-85.68 -85.2)"
      />
    </svg>
  )

  const contact = () => (
    <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" width="128.8" height="128.8" viewBox="0 0 128.8 128.8">
      <path
        d="M201.79,85.2a12.68,12.68,0,0,1,12.69,12.69V175.3a12.73,12.73,0,0,1-3.72,9.13,12,12,0,0,1-9,3.87H111.38L85.68,214V97.89A12.68,12.68,0,0,1,98.37,85.2Z"
        transform="translate(-85.68 -85.2)"
      />
    </svg>
  )

  const print = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="142.88" height="128.8" viewBox="0 0 142.88 128.8">
      <path
        d="M200.06,149.6a7.28,7.28,0,0,0,5.23-2.06,6.66,6.66,0,0,0,2.15-5,7.21,7.21,0,0,0-2.15-5.15,7,7,0,0,0-5.23-2.24,6.57,6.57,0,0,0-5.07,2.15,7.45,7.45,0,0,0-2,5.24,6.7,6.7,0,0,0,2.06,5.06,7,7,0,0,0,5,2M178.59,164h-57v35.55h57ZM193,113.71H107.15V85.2H193Zm7.05,7.38A20.76,20.76,0,0,1,210.7,124a21.84,21.84,0,0,1,7.9,7.9,20.68,20.68,0,0,1,2.92,10.65v42.93H193V214H107.15V185.49H78.64V142.56a20.68,20.68,0,0,1,2.92-10.65,21.69,21.69,0,0,1,7.9-7.9,20.77,20.77,0,0,1,10.65-2.92Z"
        transform="translate(-78.64 -85.2)"
        fill="#00adf0"
      />
    </svg>
  )

  const onlineRequest = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="143.84" height="91.19" viewBox="0 0 143.84 91.19">
      <path
        d="M170,150.34a45.39,45.39,0,0,1,6.11-.32,80.5,80.5,0,0,1,18.8,2.51q11.45,3,18.65,7.68Q222,165.85,222,172.9v16.29H182.83V172.9q0-13.32-12.85-22.56m6.11-13.17a18.8,18.8,0,0,1-9.71-2.66,19.71,19.71,0,0,1-7.21-7.21,19,19,0,0,1,0-19.43,19.76,19.76,0,0,1,7.21-7.2,19,19,0,0,1,19.43,0,20,20,0,0,1,7.2,7.2,19,19,0,0,1,0,19.43,19.91,19.91,0,0,1-7.2,7.21,18.89,18.89,0,0,1-9.72,2.66m-52,0a18.8,18.8,0,0,1-9.71-2.66,19.71,19.71,0,0,1-7.21-7.21,19,19,0,0,1,0-19.43,19.76,19.76,0,0,1,7.21-7.2A18.81,18.81,0,0,1,124.07,98a18.46,18.46,0,0,1,9.64,2.67,19.72,19.72,0,0,1,7,7.12,19.88,19.88,0,0,1,0,19.59,19.67,19.67,0,0,1-7,7.13,18.45,18.45,0,0,1-9.64,2.66m-18.8,15.36a80.5,80.5,0,0,1,18.8-2.51,78.72,78.72,0,0,1,18.65,2.51q11.43,3,18.49,7.68,8.46,5.64,8.46,12.69v16.29H78.16V172.9q0-7.05,8.46-12.69,7.2-4.71,18.65-7.68"
        transform="translate(-78.16 -98)"
        fill="#00adf0"
      />
    </svg>
  )

  const download = () => (
    <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" width="107.37" height="130.8" viewBox="0 0 107.37 130.8">
      <path
        d="M96.4,215H203.77V199.5H96.4ZM127,84.2v46.12H96.4L150.08,184l53.69-53.69H173.14V84.2Z"
        transform="translate(-96.4 -84.2)"
        fill="#00adf0"
      />
    </svg>
  )

  const park = () => (
    <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" width="118.76" height="160.8" viewBox="0 0 118.76 160.8">
      <path
        d="M112.52,133.22V72.81h40.2q17.46,0,26.07,7.81t8.62,22.51q0,30.09-34.69,30.09ZM95.75,215h11.49a5.1,5.1,0,0,0,5.28-5.28V151.83h41.35q26,0,40.77-12.41t14.82-36.29q0-23.89-14.82-36.41T153.87,54.2H95.75a4.79,4.79,0,0,0-3.68,1.49,5.75,5.75,0,0,0-1.37,4v150a5.41,5.41,0,0,0,1.37,3.79A4.79,4.79,0,0,0,95.75,215"
        transform="translate(-90.7 -54.2)"
        fill="#00adf0"
      />
    </svg>
  )

  function renderIcon(type: string): any {
    switch (type) {
      case 'arrow-left':
        return <AntIcon component={arrowLeft} className={className} />
      case 'arrow-right':
        return <AntIcon component={arrowRight} className={className} />
      case 'trash':
        return <AntIcon component={trash} className={className} />
      case 'sort':
        return <AntIcon component={sort} className={className} />
      case 'customFilter':
        return <AntIcon component={customFilter} className={className} />
      case 'telephone':
        return <AntIcon component={telephone} className={className} />
      case 'mail':
        return <AntIcon component={mail} className={className} />
      case 'finance':
        return <AntIcon component={finance} className={className} />
      case 'contact':
        return <AntIcon component={contact} className={className} />
      case 'print':
        return <AntIcon component={print} className={className} />
      case 'onlineRequest':
        return <AntIcon component={onlineRequest} className={className} />
      case 'park':
        return <AntIcon component={park} className={className} />
      case 'download':
        return <AntIcon component={download} className={className} />
      default:
        return <AntIcon className={className} type={type} rotate={rotate} />
    }
  }

  return <Wrapper className="custom-icon">{renderIcon(type)}</Wrapper>
}

const Wrapper = styled.div`
  display: inline-block;
  line-height: 0;

  svg {
    transition: all 0.24s;
    transition: all 0.24s, -webkit-transform 0.24s;

    path {
      fill: inherit;
    }
  }
`

export { Icon }
