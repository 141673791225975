import React from 'react'
import styled from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { FormattedMessage } from 'react-intl'

const CarDetailLegal: React.FC<{}> = () => {
  return (
    <Wrapper className="carDetailLegal">
      <FormattedMessage id="nefzLegalText" />
      <br />
      <br />
      <FormattedMessage id="wltpLegalText" />
      <br />
      <br />
      <FormattedMessage id="legalText" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${map({ mobile: '10px 20px', tablet: '20px 0' }, (padding) => `padding: ${padding};`)}
  font-size: 12px;
  line-height: 16px;
  color: #5d5e5f;
`

export { CarDetailLegal }
