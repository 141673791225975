import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { Page, Header, Footer, Input, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  padding: 25px;
  color: #5d5d5d;
`

interface GWNumberFilterPageProps {}

const GWNumberFilterPage: React.FC<GWNumberFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    filterStore.changeGwNumberFilter(event.target.value)
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="gwNumberFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <Input onChange={onFilterChange} value={filterStore.filters.gwNumber} />
      </Wrapper>
    </Page>
  ))
}

export { GWNumberFilterPage }
