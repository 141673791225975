import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface FuelFilterLinkProps {}

const FuelFilterLink: React.FC<FuelFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <FilterView label="fuelFilterLabel" link="/filter/fuel" badge={filterStore.getFuelFilterAsLabels.length}>
      {filterStore.getFuelFilterAsLabels.length
        ? filterStore.getFuelFilterAsLabels.map(label => (
            <div key={label}>
              <FormattedMessage id={`fuels.` + label} />
            </div>
          ))
        : null}
    </FilterView>
  ))
}

export { FuelFilterLink }
