import { observable, action, reaction } from 'mobx'
import { $enum } from 'ts-enum-util'

import { Language } from '../types'

export declare type LastActiveFilter = 'simple' | 'detail'

export declare type ConstructurParams = {
  userid: string | null
  language: Language
}

export class AppStore {
  constructor(params: ConstructurParams) {
    this.language = Language[$enum(Language).getKeyOrDefault(localStorage.getItem('language'))!] || params.language
    this.language = Language.German
    this.lastActiveFilter = 'simple'
    this.isLoggedIn = !!params.userid
  }

  @observable language: Language
  @observable lastActiveFilter: LastActiveFilter
  @observable isLoggedIn: boolean

  @observable isSaveFilterModalVisible: boolean = false
  @observable isSavedFilterFetched: boolean = false

  @observable isLoginModalOpened: boolean = false

  @action
  setLanguage = (key: Language) => {
    this.language = key
  }

  /**
   * sets the last active filter page
   *
   * @memberof AppStore
   */
  @action
  setLastActiveFilter = (type: LastActiveFilter) => {
    this.lastActiveFilter = type
  }

  /**
   * sets the visibility of save filter modal
   *
   * @memberof AppStore
   */
  @action
  setSaveFilterModalVisibility = (visible: boolean) => {
    this.isSaveFilterModalVisible = visible
  }

  /**
   * set saved filter flag is fetched
   *
   * @memberof AppStore
   */
  @action
  setSavedFilterFetched = (isDone: boolean) => {
    this.isSavedFilterFetched = isDone
  }

  @action
  setLoginModalVisible = (visible: boolean) => {
    this.isLoginModalOpened = visible
  }

  languageReaction = reaction(
    () => this.language,
    (language) => localStorage.setItem('language', language)
  )
}
