import React, { useRef } from 'react'
import { useObserver } from 'mobx-react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import styled from 'styled-components'
import 'react-image-gallery/styles/css/image-gallery.css'
import { breakpoint } from 'styled-components-breakpoint'
import { Icon } from '../'

import { useCarStore, useIsMobile } from '../../hooks'
import { DetailCarImage } from '../../types'

const CarDetailImages: React.FC<{}> = () => {
  const carStore = useCarStore()
  const isMobile = useIsMobile()

  const imageGalleryRef = useRef<ImageGallery>(null)

  const imagesForGallery = (images: DetailCarImage[]): ReactImageGalleryItem[] => {
    if (images.length) {
      return images.map((image: any) => {
        return {
          original: `https://www.sug-verwaltung.de/public/images/${image.imagepath}`,
          thumbnail: `https://www.sug-verwaltung.de/public/images/${image.imagesmallthumbpath}`,
        }
      })
    } else {
      return [
        {
          original: 'https://www.sug-verwaltung.de/public/foto_folgt_in_kuerze.jpeg',
          thumbnail: 'https://www.sug-verwaltung.de/public/foto_folgt_in_kuerze.jpeg',
        },
      ]
    }
  }

  return useObserver(() => {
    if (carStore.car && carStore.car.images) {
      return (
        <Wrapper className="carDetailImages">
          <ImageGallery
            ref={imageGalleryRef}
            items={imagesForGallery(carStore.car.images)}
            slideDuration={150}
            showBullets={!isMobile}
            thumbnailPosition="bottom"
            showPlayButton={false}
            onClick={() => (imageGalleryRef.current ? imageGalleryRef.current.fullScreen() : false)}
            renderFullscreenButton={(onClick, isFullscreen) =>
              isFullscreen ? (
                <FullscreenButtonn className="image-gallery-icon image-gallery-fullscreen-button" onClick={onClick}>
                  <Icon type="fullscreen-exit" />
                </FullscreenButtonn>
              ) : null
            }
          />
        </Wrapper>
      )
    }

    return null
  })
}

const FullscreenButtonn = styled.button`
  .anticon svg {
    width: 30px;
    height: auto;
  }
`

const Wrapper = styled.div`
  .image-gallery {
    &-bullets {
      ${breakpoint('tablet')`
        bottom: -85px;
      `}

      ${breakpoint('desktop')`
        bottom: -105px;
      `}
    }

    &-thumbnails-wrapper {
      ${breakpoint('desktop')`
        padding-bottom: 20px;
      `}

      ${breakpoint('tablet')`
        padding-bottom: 10px;
      `}
    }
  }
`

export { CarDetailImages }
