import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { map } from 'styled-components-breakpoint'

import { useRouterStore } from '../../hooks'
import { Button, Icon } from '../'
import { Breakpoint } from 'react-socks'
import { Typography } from 'antd'

interface HeaderProps {
  title: React.ReactNode
  desktopTitle?: React.ReactNode
  backTarget?: string
  backLabel?: string
  withBack?: boolean
  toolbar?: React.ReactNode
  specialFont?: boolean
}

const Header: React.FC<HeaderProps> = ({
  title,
  desktopTitle = null,
  backTarget = null,
  backLabel = null,
  withBack = false,
  toolbar,
  specialFont,
  ...rest
}) => {
  const routerStore = useRouterStore()

  function handleBackClick() {
    backTarget ? routerStore.push(backTarget) : routerStore.goBack()
  }

  return (
    <Wrapper specialFont={specialFont ? true : false} hasToolbar={toolbar ? true : false} withBack={withBack}>
      {withBack && (
        <Button className="back" type="link" onClick={() => handleBackClick()}>
          <Icon type="arrow-left" /> {backLabel ? backLabel : <FormattedMessage id="back" />}
        </Button>
      )}

      {/* only tablets and above */}
      <Breakpoint medium up>
        <Title className="desktopTitle">{desktopTitle}</Title>
      </Breakpoint>

      {/* only on mobile devices */}
      <Breakpoint small down>
        <div className="title">{title}</div>
      </Breakpoint>

      {toolbar && <div className="toolbar">{toolbar}</div>}
    </Wrapper>
  )
}

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  &.ant-typography {
    font-family: 'CorporateA', serif;
    font-size: 25px;
    line-height: 1;
    color: #fff;
    margin: 0;
    font-weight: 400;
  }
`

const Wrapper = styled.header<{ withBack: boolean; hasToolbar: boolean; specialFont: boolean }>`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${map({ mobile: '100%', desktop: '1170px' }, (width) => `width: ${width};`)}
  margin: 0px auto;

  top: 0;
  height: 75px;
  padding: 0 20px;
  background-color: #0e0e0e;
  z-index: 100;

  .back {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: auto;
    color: #5d5e5f;
    font-size: 13px;
    z-index: 5;

    .custom-icon {
      margin-right: 5px;

      svg {
        height: 15px;
      }

      g {
        fill: #5d5e5f;
        transition: all 300ms;
      }
    }

    &:hover {
      color: #1daeee;
      cursor: pointer;

      .custom-icon {
        g {
          fill: #1daeee;
        }
      }
    }
  }

  .title {
    position: absolute;
    font-size: 18px;
    text-align: center;
    line-height: 1;
    font-family: 'Roboto';

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .toolbar {
    margin-left: auto;

    span {
      color: #5d5e5f;
    }

    .custom-icon {
      svg {
        height: 25px;
        fill: #5d5e5f;
      }

      g {
        fill: #5d5e5f;
      }
    }
  }

  ${(props) =>
    props.specialFont &&
    css`
      .title,
      .desktopTitle {
        font-family: 'CorporateA';
        font-size: 30px;
      }
    `}
`

export { Header }
