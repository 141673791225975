import styled from 'styled-components'
import { Tabs as AntTabs } from 'antd'

const AntTabPane = AntTabs.TabPane

const TabPane = styled(AntTabPane)`
  color: #fff;
`

export { TabPane }
