import React from 'react'
import { useObserver } from 'mobx-react'
import { useIntl } from 'react-intl'

import { RangeFilterFormDropdown } from '../../../'
import { useFilterStore } from '../../../../hooks'
import { RangeFilter } from '../../../../types'

const values = [1000, 1200, 1400, 1600, 1800, 2000, 2600, 3000, 5000, 7500, 8000, 9000]

const CcmFilter: React.FC = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onChange = (values: RangeFilter) => {
    filterStore.setCubicCapacityFilter(values)
  }

  return useObserver(() => {
    const ccm = filterStore.filters.engine.cubicCapacity
    return (
      <RangeFilterFormDropdown
        label={formatMessage({ id: 'cubicCapacityFilterLabel' })}
        unit="cm³"
        value={ccm}
        values={values}
        onChange={onChange}
        formatValue
      />
    )
  })
}

export { CcmFilter }
