import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useObserver } from 'mobx-react'

import { useFilterStore } from '../../hooks'
import { Tabs, TabPane } from '../../components'

interface VehicleTypeTabProps {}

const VehicleTypeTab: React.FC<VehicleTypeTabProps> = ({ children }) => {
  const filterStore = useFilterStore()

  const onTabChange = (key: string) => {
    filterStore.setUtilityVehicleFilter(key === 'utilityVehicle')
  }

  return useObserver(() => (
    <Tabs type="card" onChange={onTabChange} activeKey={filterStore.filters.utilityVehicle ? 'utilityVehicle' : 'car'}>
      <TabPane tab={<FormattedMessage id="car" />} key="car">
        {children}
      </TabPane>
      <TabPane tab={<FormattedMessage id="utilityVehicle" />} key="utilityVehicle">
        {children}
      </TabPane>
    </Tabs>
  ))
}

export { VehicleTypeTab }
