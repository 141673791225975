import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col, Popover } from 'antd'
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'

import { kwToPs } from '../../helpers/power'
import { useCarStore, useAuConfigKey, useFilterStore } from '../../hooks'
import { Button, Icon, CarFinancingInfo } from '../'
import { PriceRatingWidget, AuEmbedFC } from '../AutoUncle'
import { Fuel, Gearbox } from '../../types'

import { Mileage, FirstRegistration, Power } from '../../Icon'

const CarDetailShortInfo: React.FC<{}> = () => {
  const carStore = useCarStore()
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()
  const { getKeyByLocation } = useAuConfigKey()

  const renderSwitch = (param: any) => {
    console.log('renderSwitch', param)
    
    switch (param) {
      case 'meet':
        return 'Click & Meet'
      case 'collect':
        return 'Click & Collect'
      case 'appointment':
        return 'Beratungstermin vereinbaren'
      default:
        return ''
    }
  }

  return useObserver(() => (
    <Wrapper className="carDetailShortInfo">
      <div className="details">
        <h2>{carStore.car?.brand}</h2>
        <h4>{carStore.car?.name}</h4>

        <Row justify="space-between" type="flex">
          <Col>
            <Mileage />
            <FormattedNumber value={carStore.car?.mileage || 0} /> km
          </Col>

          <Col>
            {carStore.car?.previousOwner ? <FormattedMessage id="usedCar" /> : <FormattedMessage id="newCar" />},{' '}
            {carStore.car?.maintenanceGuide && <FormattedMessage id="maintenanceGuide" />}
          </Col>
        </Row>

        <Row justify="space-between" type="flex">
          <Col>
            <FirstRegistration />
            {moment(carStore.car?.firstRegistrationDate, 'MM.YYYY').format('MM/YYYY')}
          </Col>

          <Col>{carStore.car?.previousOwner === 1 && <FormattedMessage id="firstHand" />}</Col>
        </Row>

        <Row justify="space-between" type="flex">
          <Col>
            <Power />
            {carStore.car?.power} <FormattedMessage id="kw" /> {`(${kwToPs(carStore.car?.power || 0)} ${formatMessage({ id: 'ps' })})`}
          </Col>

          <Col>
            <FormattedMessage id={`gearboxes.${Gearbox[carStore.car?.engine.gearbox || 1]}`} />,{' '}
            <FormattedMessage id={`fuels.${Fuel[carStore.car?.engine.fuel || 0]}`} />
          </Col>
        </Row>

        <Row justify="space-between" type="flex" className="location">
          <Col>
            <span>
              <FormattedMessage id="locationFilterLabel" />
            </span>
          </Col>
          <Col>
            <span>{carStore.car?.location.name ? carStore.car?.location.name : '&nbsp'}</span>
          </Col>
        </Row>

        <Row justify="space-between" type="flex" className="price">
          <Col>
            <span>
              <FormattedMessage id="buyPrice" />
            </span>
          </Col>

          <Col>
            <div>
              {filterStore.filters.utilityVehicle && carStore.car?.vatReportable ? (
                <>
                  <span className="buyout">
                    <FormattedNumber value={carStore.car?.priceWithoutTax} />
                    ,- €{' '}
                    <span className="light">
                      (<FormattedMessage id="netto" />)
                    </span>
                  </span>
                  <br />
                  <span className="brutto">
                    <FormattedNumber value={carStore.car?.price} />
                    ,- €{' '}
                    <span className="netto">
                      (<FormattedMessage id="brutto" />)
                    </span>
                  </span>
                </>
              ) : (
                <span className="buyout">
                  <FormattedNumber value={carStore.car?.price || 0} />
                  ,- €
                </span>
              )}

              <br />
              <span className="rate">
                {carStore.car?.financing && carStore.car?.financing.regulatory && (
                  <Popover content={<CarFinancingInfo data={carStore.car.financing} />} trigger="click">
                    <Button type="link" className="rate">
                      <FormattedMessage id="monthlyRate" /> <Icon type="exclamation-circle" />
                    </Button>
                  </Popover>
                )}
              </span>
            </div>
            {carStore.car?.link && carStore.car?.uid ? (
              <PriceRatingWidget link={carStore.car.link} internalNumber={carStore.car._id} />
            ) : null}
          </Col>
        </Row>
      </div>
      <div>
        <Row
          justify="space-between"
          type="flex"
          className="contact"
          gutter={[
            { xs: 0, md: 20 },
            { xs: 20, md: 0 },
          ]}
        >
          <Col xs={24} md={12}>
            {carStore.car &&
            carStore.car?.location.clickAndMeetOrClickAndCollect &&
            carStore.car?.location.clickAndMeetOrClickAndCollect !== '0' ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${
                  carStore.car?.location.clickAndMeetUrl && carStore.car?.location.clickAndMeetUrl !== ''
                    ? carStore.car?.location.clickAndMeetUrl
                    : 'https://www.sug.de/clicknmeet'
                }?internenr=${carStore.car?._id}`}
              >
                <Button style={{ width: '100%', borderColor: '#B7A903', backgroundColor: '#B7A903' }} className={'clickAndMeetButton'}>
                  {renderSwitch(carStore.car?.location.clickAndMeetOrClickAndCollect)}
                </Button>
              </a>
            ) : null}
          </Col>

          <Col xs={24} md={12}>
            {carStore.car ? (
              <AuEmbedFC
                type="cta"
                config={{ key: getKeyByLocation(carStore.car.location.name), stockId: carStore.car.uid, customData: {} }}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .details {
    h2 {
      margin-top: 0;
    }

    h2,
    h4 {
      color: #fff;
    }

    .ant-row-flex {
      padding-top: 15px;
      padding-bottom: 15px;

      &:not(:last-child) {
        border-bottom: 1px solid #383838;
      }

      .ant-col {
        &:last-child {
          font-size: 13px;
          text-align: right;
        }
      }

      &.price {
        border-bottom: none;

        &:first-child {
          line-height: 24px;
        }

        .buyout {
          font-size: 16px;
          font-weight: 600;
        }

        .rate {
          color: #5d5e5f;
          border: none;
          margin-top: 10px;

          .custom-icon {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .contact {
    a {
      transition: none;
    }

    .ant-col {
      .ant-btn {
        padding: 10px;
        border-width: 3px;
        line-height: 20px;
        vertical-align: middle;
      }
    }

    .custom-icon {
      svg {
        font-size: 12px;
        height: 15px;
        width: auto;
        margin-bottom: -3px;
      }
    }

    .telephone {
      background-color: #191919;
      line-height: 20px;

      svg {
        fill: #1daeee;
      }

      &:hover {
        background-color: #252525;
        cursor: pointer;
        color: #fff;

        .custom-icon {
          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  svg {
    height: 20px;
    width: auto;
    margin-right: 10px;
  }
`

export { CarDetailShortInfo }
