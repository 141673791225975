// https://services.mobile.de/manual/upload-interface-csv_en.html
// 1=petrol, 2=diesel, 3=LPG, 4=natural gas, 6=electric, 7=hybrid-petrol, 8=hydrogen, 9=ethanol, 10=hybrid-diesel, 0=other

export enum Fuel {
  PETROL = 1,
  DIESEL = 2,
  LPG = 3,
  NATURAL_GAS = 4,
  ELECTRIC = 6,
  HYBRID_PETROL = 7,
  HYDROGEN = 8,
  ETHANOL = 9,
  HYBRID_DIESEL = 10,
  OTHER = 0,
}
