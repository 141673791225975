import React from 'react'

const En: React.FC<{}> = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.53 5.65">
      <title>UK</title>
      <path
        fill="#41479b"
        d="M8.38,5.65H.15A.15.15,0,0,1,0,5.5V.15A.15.15,0,0,1,.15,0H8.38a.15.15,0,0,1,.15.15V5.5A.15.15,0,0,1,8.38,5.65Z"
      />
      <path
        fill="#f5f5f5"
        d="M8.53.15A.15.15,0,0,0,8.38,0H7.72L5,1.8V0H3.53V1.8L.8,0H.15A.15.15,0,0,0,0,.15V.53L2.34,2.08H0V3.57H2.34L0,5.11V5.5a.15.15,0,0,0,.15.15H.8l2.72-1.8v1.8H5V3.84l2.72,1.8h.66a.15.15,0,0,0,.15-.15V5.11L6.19,3.57H8.53V2.08H6.19L8.53.53Z"
      />
      <polygon
        fill="#ff4b55"
        points="8.53 2.38 4.7 2.38 4.7 0 3.82 0 3.82 2.38 0 2.38 0 3.27 3.82 3.27 3.82 5.65 4.7 5.65 4.7 3.27 8.53 3.27 8.53 2.38"
      />
      <path fill="#ff4b55" d="M3,3.57l-3,2a.15.15,0,0,0,.14.13h.2L3.52,3.57H3Z" />
      <path fill="#ff4b55" d="M5.77,3.57H5.23L8.39,5.64a.15.15,0,0,0,.13-.15V5.38Z" />
      <path fill="#ff4b55" d="M0,.31,2.69,2.08h.54L.09,0A.15.15,0,0,0,0,.15Z" />
      <path fill="#ff4b55" d="M5.54,2.08l3-2A.15.15,0,0,0,8.38,0H8.17L5,2.08Z" />
    </svg>
  )
}

export { En }
