import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Breakpoint } from 'react-socks'

import { useAppStore, useRouterStore } from '../../hooks'
import { Button, Icon } from '../../components'

const MobileWrapper = styled.div`
  ${Button} {
    display: flex;
    span {
      line-height: 25px;
    }
  }
`

const EditFilterButton = styled(AntButton)`
  border-radius: 0;
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  line-height: 1;
  padding: 9px 20px;
  height: 42px;
  width: 215px;

  &:hover {
    color: #fff;
    background-color: #1daeee;
    border-color: #1daeee;
  }
`

interface NavigateToFilterProps {}

const NavigateToFilter: React.FC<NavigateToFilterProps> = () => {
  const appStore = useAppStore()
  const routerStore = useRouterStore()

  const navigate = () => {
    switch (appStore.lastActiveFilter) {
      case 'simple':
        routerStore.push('/suche')
        break
      case 'detail':
        routerStore.push('/detailsuche')
        break
    }
  }

  return useObserver(() => (
    <>
      {/* only tablets and above */}
      <Breakpoint medium up>
        <EditFilterButton onClick={navigate}>
          <FormattedMessage id="editFilter" />
        </EditFilterButton>
      </Breakpoint>

      {/* only on mobile devices */}
      <Breakpoint small down>
        <MobileWrapper>
          <Button type="link" onClick={navigate}>
            <Icon type="customFilter" />
            <span>
              <FormattedMessage id="filter" />
            </span>
          </Button>
        </MobileWrapper>
      </Breakpoint>
    </>
  ))
}

export { NavigateToFilter }
