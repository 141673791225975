import React from 'react'

const Pl: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.42 43.52">
      <path d="M0,21.76H66.42V42.37a1.14,1.14,0,0,1-1.15,1.15H1.15A1.14,1.14,0,0,1,0,42.37H0Z" fill="#ff4b55" />
      <path d="M66.42,21.76H0V1.15A1.14,1.14,0,0,1,1.15,0H65.27a1.14,1.14,0,0,1,1.15,1.15h0Z" fill="#f5f5f5" />
    </svg>
  )
}

export { Pl }
