import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon, RangeFilterLabel } from '../'
import { useFilterStore } from '../../hooks'

interface RegistrationFilterLinkProps {}

const RegistrationFilterLink: React.FC<RegistrationFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const registration = filterStore.filters.firstRegistration
    return (
      <FilterView
        label="firstRegistrationLabel"
        link="/filter/registration"
        badge={registration.lte || registration.gte ? <Icon type="check" /> : 0}
      >
        <RangeFilterLabel filter={registration} unit={''} formatNumber={false} />
      </FilterView>
    )
  })
}

export { RegistrationFilterLink }
