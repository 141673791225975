import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'
import { Icon } from '../'

interface TotalWeightFilterLink {}

const TotalWeightFilterLink: React.FC<TotalWeightFilterLink> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const weight = filterStore.getTotalWeight
    return (
      <FilterView label="totalWeight" link="/filter/weight" badge={weight ? <Icon type="check" /> : 0}>
        {weight ? (
          <div>
            <FormattedMessage id={`totalWeightOptions.${weight}`} />
          </div>
        ) : null}
      </FilterView>
    )
  })
}

export { TotalWeightFilterLink }
