import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { FormattedMessage } from 'react-intl'
import { Select as AntSelect } from 'antd'

import { Col, Row } from 'antd'
import { Form, Select, InputGroup, FormItem } from '../'
import { RangeFilter } from '../../types'
import { useFilterStore } from '../../hooks'
import { getYearRange } from '../../helpers/rangeFilter'

const { Option } = AntSelect
export interface RegistrationFilterFormProps extends FormComponentProps {
  onChange: (values: RangeFilter) => void
  values?: RangeFilter
}

const RegistrationFilterFormFC = forwardRef<FormComponentProps, RegistrationFilterFormProps>(({ form, onChange, values }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
    onChange,
  }))

  const filterStore = useFilterStore()

  useEffect(() => {
    if (values) {
      const current = form.getFieldsValue()
      if (values.lte !== current.lte || values.gte !== current.gte) {
        form.setFieldsValue(values)
      }
    }
  })

  return (
    <>
      <Form>
        <InputGroup>
          <Row type="flex" gutter={12}>
            <Col xs={12}>
              <FormItem label={<FormattedMessage id="from" />}>
                {form.getFieldDecorator('gte')(
                  <Select placeholder={<FormattedMessage id="pleaseChoose" />}>
                    {getYearRange(filterStore.firstRegistrationRange.min, filterStore.firstRegistrationRange.max).map((year: number) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={12}>
              <FormItem label={<FormattedMessage id="to" />}>
                {form.getFieldDecorator('lte')(
                  <Select placeholder={<FormattedMessage id="pleaseChoose" />}>
                    {getYearRange(filterStore.firstRegistrationRange.min, filterStore.firstRegistrationRange.max).map((year: number) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </>
  )
})

const RegistrationFilterForm = Form.create<RegistrationFilterFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(RegistrationFilterFormFC)

export { RegistrationFilterForm }
