import React, { useEffect } from 'react'
import { useObserver } from 'mobx-react'
import { Breakpoint } from 'react-socks'

import { useAppStore } from '../../hooks'

import { DetailFilterMobile } from './Mobile/Mobile'
import { DetailFilterDesktop } from './Desktop/Desktop'

interface DetailFilterProps {}

const DetailFilter: React.FC<DetailFilterProps> = () => {
  const appStore = useAppStore()

  useEffect(() => {
    appStore.setLastActiveFilter('detail')
  })

  return useObserver(() => (
    <>
      <Breakpoint medium up>
        <DetailFilterDesktop />
      </Breakpoint>
      <Breakpoint small down>
        <DetailFilterMobile />
      </Breakpoint>
    </>
  ))
}

export { DetailFilter }
