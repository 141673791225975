import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { ResultListFilter } from '../../components'
import { CarFilter, RangeFilter, Gearbox, Fuel } from '../../types'

const Wrapper = styled.div`
  height: 60px;

  padding: 10px;
  background-image: linear-gradient(#222b2f, #1a2327);

  overflow: hidden;
  overflow-x: scroll;

  & .inner {
    display: inline-flex;
  }
`

interface ResultListFilterBarProps {}

const ResultListFilterBar: React.FC<ResultListFilterBarProps> = () => {
  const filterStore = useFilterStore()
  const { formatMessage, formatNumber } = useIntl()

  const renderRangeFilter = (index: string, symbol: string, callback: Function, formatAsNumber: boolean = true) => {
    let filter = filterStore.filters[index]

    if (formatAsNumber) {
      filter = {
        lte: filter.lte ? formatNumber(filter.lte) : filter.lte,
        gte: filter.gte ? formatNumber(filter.gte) : filter.gte,
      }
    }

    return (
      <>
        {filter.gte && (
          <ResultListFilter
            label={formatMessage({ id: 'filterLabel.from' }, { from: filter.gte, symbol })}
            onRemove={() => callback({ gte: null })}
          />
        )}

        {filter.lte && (
          <ResultListFilter
            label={formatMessage({ id: 'filterLabel.to' }, { to: filter.lte, symbol })}
            onRemove={() => callback({ lte: null })}
          />
        )}
      </>
    )
  }

  const carFilterLabel = (car: CarFilter) => {
    return `${car.brand} ${car.model ? car.model : ''} ${car.variant ? car.variant : ''}`
  }

  return useObserver(() =>
    filterStore.areFiltersSet ? (
      <Wrapper>
        <div className="inner">
          {/* Cars */}
          {filterStore.filters.cars.map((car) => (
            <ResultListFilter label={carFilterLabel(car)} key={JSON.stringify(car)} onRemove={() => filterStore.removeCar(car)} />
          ))}
          {/* Locations */}
          {filterStore.filters.locations.map((location) => (
            <ResultListFilter label={location} key={location} onRemove={() => filterStore.removeLocation(location)} />
          ))}
          {/* Equipment */}
          {filterStore.filters.equipment.general.map((eq) => (
            <ResultListFilter
              label={formatMessage({ id: `equipment.${eq}` })}
              key={eq}
              onRemove={() => filterStore.addOrRemoveGeneralEquipment(eq)}
            />
          ))}

          {filterStore.filters.equipment.ac.map((eq) => (
            <ResultListFilter
              label={formatMessage({ id: `equipment.${eq}` })}
              key={eq}
              onRemove={() => filterStore.addOrRemoveAcEquipment(eq)}
            />
          ))}

          {filterStore.filters.equipment.interior.map((eq) => (
            <ResultListFilter
              label={formatMessage({ id: `equipment.${eq}` })}
              key={eq}
              onRemove={() => filterStore.addOrRemoveInteriorEquipment(eq)}
            />
          ))}

          {filterStore.filters.equipment.security.map((eq) => (
            <ResultListFilter
              label={formatMessage({ id: `equipment.${eq}` })}
              key={eq}
              onRemove={() => filterStore.addOrRemoveSecurityEquipment(eq)}
            />
          ))}

          {/* QualitySeal */}
          {filterStore.filters.qualitySeal.youngStar && (
            <ResultListFilter
              label={formatMessage({ id: `qualitySeal.youngStar` })}
              onRemove={() => filterStore.toggleQualitySeal('youngStar')}
            />
          )}

          {/** price */}
          {renderRangeFilter('price', '€', (value: RangeFilter) => filterStore.setPriceFilter(value))}

          {/** financing */}
          {renderRangeFilter('financing', '€', (value: RangeFilter) => filterStore.setFinancingFilter(value))}

          {/** power */}
          {renderRangeFilter('power', filterStore.filters.powerType, (value: RangeFilter) => filterStore.setPowerFilter(value))}

          {/** engine -> fuels */}
          {filterStore.filters.engine.fuels.map((fuel) => (
            <ResultListFilter
              label={formatMessage({ id: `fuels.${Fuel[fuel]}` })}
              key={fuel}
              onRemove={() => filterStore.addOrRemoveFuel(fuel)}
            />
          ))}

          {/** engine -> gearboxes */}
          {filterStore.filters.engine.gearboxes.map((gearbox) => (
            <ResultListFilter
              label={formatMessage({ id: `gearboxes.${Gearbox[gearbox]}` })}
              key={gearbox}
              onRemove={() => filterStore.addOrRemoveGearbox(gearbox)}
            />
          ))}

          {/** engine ccm */}
          {filterStore.filters.engine.cubicCapacity.gte && (
            <ResultListFilter
              label={formatMessage({ id: 'filterLabel.from' }, { from: filterStore.filters.engine.cubicCapacity.gte, symbol: 'cm³' })}
              onRemove={() => filterStore.setCubicCapacityFilter({ gte: null })}
            />
          )}

          {filterStore.filters.engine.cubicCapacity.lte && (
            <ResultListFilter
              label={formatMessage({ id: 'filterLabel.to' }, { to: filterStore.filters.engine.cubicCapacity.lte, symbol: 'cm³' })}
              onRemove={() => filterStore.setCubicCapacityFilter({ lte: null })}
            />
          )}

          {/** firstRegistration */}
          {renderRangeFilter('firstRegistration', '', (value: RangeFilter) => filterStore.setFirstRegistrationFilter(value), false)}

          {/** Mileage */}
          {renderRangeFilter('mileage', 'km', (value: RangeFilter) => filterStore.setMileageFilter(value))}

          {/** colors */}
          {filterStore.filters.colors.map((color) => (
            <ResultListFilter
              label={formatMessage({ id: `color.${color}` })}
              key={color}
              onRemove={() => filterStore.addOrRemoveColor(color)}
            />
          ))}

          {/** gw number */}
          {filterStore.filters.gwNumber && (
            <ResultListFilter label={formatMessage({ id: 'gwNumberFilterLabel' })} onRemove={() => filterStore.changeGwNumberFilter('')} />
          )}
        </div>
      </Wrapper>
    ) : null
  )
}

export { ResultListFilterBar }
