import { observable, action } from 'mobx'
import { computedFn } from 'mobx-utils'

import { Car, LinkList } from '../types'

export class CarListStore {
  @observable loading = true
  @observable cars: Car[] = []
  @observable linkList: LinkList[] = []
  @observable total = 0
  @observable pages = 0

  @action
  setLoading(state: boolean) {
    this.loading = state
  }

  @action
  setDocs(docs: Car[]) {
    this.cars = docs
  }

  @action
  setLinkList(linkList: LinkList[]) {
    this.linkList = linkList
  }

  @action
  setTotal(value: number) {
    this.total = value
  }

  @action
  setPages(value: number) {
    this.pages = value
  }

  getPrevCar = computedFn(function getPrevCar(this: CarListStore, _id?: string) {
    const index = this.linkList.findIndex((linkList) => linkList._id === _id)

    return this.linkList[index - 1]
  })

  getNextCar = computedFn(function getNextCar(this: CarListStore, _id?: string) {
    const index = this.linkList.findIndex((linkList) => linkList._id === _id)

    return this.linkList[index + 1]
  })

  getCarIndex = computedFn(function getCarIndex(this: CarListStore, _id?: string) {
    return this.linkList.findIndex((linkList) => linkList._id === _id)
  })
}
