import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface ColorFilterLinkProps {}

const ColorFilterLink: React.FC<ColorFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const colors = filterStore.filters.colors
    return (
      <FilterView label="colorFilterLabel" link="/filter/color" badge={colors.length}>
        {colors.length
          ? colors.map(color => (
              <div key={color}>
                <FormattedMessage id={`color.${color}`} />
              </div>
            ))
          : null}
      </FilterView>
    )
  })
}

export { ColorFilterLink }
