import React from 'react'
import { useObserver } from 'mobx-react'
import { Row, Col } from 'antd'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import styled from 'styled-components'
import moment from 'moment'

import { useCarStore } from '../../hooks'
import { kwToPs } from '../../helpers/power'
import { Fuel } from '../../types'

const CarDetailVehicleData: React.FC<{}> = () => {
  const carStore = useCarStore()
  const { formatMessage } = useIntl()

  return useObserver(() => (
    <Wrapper className="carDetailEqTab">
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="firstRegistration" />
        </Col>
        <Col>{moment(carStore.car?.firstRegistrationDate, 'MM.YYYY').format('MM/YYYY')}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="mileage" />
        </Col>
        <Col>
          <FormattedNumber value={carStore.car?.mileage || 0} /> km
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="powerFilterLabel" />
        </Col>
        <Col>
          {carStore.car?.power} <FormattedMessage id="kw" /> {`(${kwToPs(carStore.car?.power || 0)} ${formatMessage({ id: 'ps' })})`}
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="fuelFilterLabel" />
        </Col>
        <Col>
          <FormattedMessage id={`fuels.${Fuel[carStore.car?.engine.fuel || 1]}`} />
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="colorFilterLabel" />
        </Col>
        <Col>{carStore.car?.color}</Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="carCategory" />
        </Col>
        <Col>{carStore.car?.categories[0]}</Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="warranty" />
        </Col>
        <Col>{carStore.car?.warranty ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="vatReportable" />
        </Col>
        <Col>{carStore.car?.vatReportable ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="gwNumberFilterLabel" />
        </Col>
        <Col>{carStore.car?._id}</Col>
      </Row>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  .ant-row-flex {
    padding-top: 15px;
    padding-bottom: 15px;

    &:not(:last-child) {
      border-bottom: 1px solid #383838;
    }

    .ant-col {
      &:last-child {
        font-size: 13px;
      }
    }
  }
`

export { CarDetailVehicleData }
