import styled from 'styled-components'

const Tile = styled.div`
  padding: 25px;
  background-color: #0e0e0e;
  margin-bottom: 20px;

  &.fullHeight {
    height: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .ant-tabs .ant-tabs-bar .ant-tabs-tab {
    &:first-child {
      padding-left: 0px;
    }
  }
`

export { Tile }
