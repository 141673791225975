import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { $enum } from 'ts-enum-util'

import { useFilterStore } from '../../hooks'
import { CheckedButton } from '../../components'

import { InteriorEquipment, InteriorEquipmentUtilityVehicle } from '../../types'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  color: #5d5d5d;
`

interface InteriorEquipmentFilterProps {}

const InteriorEquipmentFilter: React.FC<InteriorEquipmentFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: InteriorEquipment | InteriorEquipmentUtilityVehicle) => {
    filterStore.addOrRemoveInteriorEquipment(value)
  }

  return useObserver(() => (
    <Wrapper>
      <FormattedMessage id="interior" />
      {$enum(InteriorEquipment)
        .getValues()
        .map((filter) => (
          <CheckedButton
            onClick={() => onFilterChange(filter)}
            label={<FormattedMessage id={`equipment.${InteriorEquipment[filter]}`} />}
            active={filterStore.filters.equipment.interior.includes(filter)}
            key={filter}
          />
        ))}
      {filterStore.filters.utilityVehicle
        ? $enum(InteriorEquipmentUtilityVehicle)
            .getValues()
            .map((filter) => (
              <CheckedButton
                onClick={() => onFilterChange(filter)}
                label={<FormattedMessage id={`equipment.${InteriorEquipmentUtilityVehicle[filter]}`} />}
                active={filterStore.filters.equipment.interior.includes(filter)}
                key={filter}
              />
            ))
        : null}
    </Wrapper>
  ))
}

export { InteriorEquipmentFilter }
