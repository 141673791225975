import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { $enum } from 'ts-enum-util'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'
import { Icon } from '../'
import { CarEnvBadge } from '../../types'

interface CarEnvBadgeFilterLinkProps {}

const CarEnvBadgeFilterLink: React.FC<CarEnvBadgeFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const filter = filterStore.filters.carEnvBadge
    return (
      <FilterView label="carEnvBadge" link="/filter/carenvbadge" badge={filter ? <Icon type="check" /> : 0}>
        {filter ? (
          <div>
            <FormattedMessage id={`carEnvBadgeOptions.${$enum(CarEnvBadge).getKeyOrDefault(filter)}`} />
          </div>
        ) : null}
      </FilterView>
    )
  })
}

export { CarEnvBadgeFilterLink }
