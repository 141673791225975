import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

interface VatFilterProps {}

const VatFilterPage: React.FC<VatFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: boolean | undefined) => {
    filterStore.setVatReportableFilter(value);
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="locationFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <CheckedButton
          onClick={() => onFilterChange(undefined)}
          label={<FormattedMessage id='filterAny' />}
          active={filterStore.filters.isVatReportable === undefined}
          key='any'
        />

        <CheckedButton
          onClick={() => onFilterChange(true)}
          label={<FormattedMessage id='yes' />}
          active={filterStore.filters.isVatReportable === true}
          key='true'
        />

        <CheckedButton
          onClick={() => onFilterChange(false)}
          label={<FormattedMessage id='no' />}
          active={filterStore.filters.isVatReportable === false}
          key='false'
        />
      </Wrapper>
    </Page>
  ))
}

export { VatFilterPage }
