import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'antd'

import { useFilterStore } from '../../../../hooks'
import { $enum } from 'ts-enum-util'

import { Checkbox } from '../../../../components'
import { Color } from '../../../../types'

const ColorFilter: React.FC = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: Color[]) => {
    filterStore.filters.colors = value
  }

  return useObserver(() => (
    <Wrapper>
      <Checkbox.Group value={filterStore.filters.colors} onChange={(e: any) => onFilterChange(e)}>
        <Row type="flex" gutter={[12, 12]}>
          {$enum(Color)
            .getValues()
            .map(filter => (
              <Col span={6} key={filter}>
                <Checkbox key={filter} value={filter} color={$enum(Color).getKeyOrDefault(filter)}>
                  <FormattedMessage id={`color.${filter}`} />
                </Checkbox>
              </Col>
            ))}
        </Row>
      </Checkbox.Group>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  margin-top: 20px;
`

export { ColorFilter }
