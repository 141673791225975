import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { Button, Icon, Badge } from '../'
import { useRouterStore } from '../../hooks/useStore'

const Wrapper = styled.div`
  .ant-badge {
    & > .custom-icon {
      margin-top: 0 !important;
      transform: translate(55%, -55%);
      top: 4px;
      right: 4px;
    }
  }
`

const StyledButton = styled(Button as any)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  padding: 19.5px 25px !important;
  font-size: 13px !important;
  letter-spacing: 0.05em;
  border-radius: 0;
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
  box-shadow: none;

  & .inner {
    display: flex;
    flex-direction: column;
  }

  & .head {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
  }

  & .filters {
    color: #5d5e5f;
    text-align: left;

    & > :first-child {
      margin-top: 10px;
    }
  }

  :hover,
  :focus,
  :active {
    color: #fff;
    background-color: #1e1e1e !important;
    border-color: #1e1e1e !important;
  }

  .anticon {
    margin-right: 0px !important;
    font-size: 23px;
    color: #5b5f60;
    fill: #5b5f60;

    svg {
      width: auto;
      height: 15px;
    }
  }
`

interface FilterViewProps {
  link: string
  label: string
  badge: number | React.ReactNode
}

const FilterView: React.FC<FilterViewProps> = ({ link, label, badge, children, ...rest }) => {
  const routerStore = useRouterStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return (
    <>
      <Wrapper onClick={() => navigate(link)}>
        <Badge count={badge} offset={[0, 0]}>
          <StyledButton block type="primary">
            <div className="inner">
              <div className="head">
                <span>
                  <FormattedMessage id={label} />
                </span>
              </div>
              {children ? <div className="filters">{children}</div> : null}
            </div>

            <Icon type="arrow-right" />
          </StyledButton>
        </Badge>
      </Wrapper>
    </>
  )
}

export { FilterView }
