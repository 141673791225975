import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { $enum } from 'ts-enum-util'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../../../hooks'
import { Select } from '../../../Select'
import { Co2EuroNorm } from '../../../../types'

const Co2EuroNormFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const options: OptionProps[] = $enum(Co2EuroNorm)
    .getEntries()
    .map(([label, value]) => ({ value, label: formatMessage({ id: `co2EuroNormOptions.${label}` }) }))

  options.unshift({ value: undefined, label: formatMessage({ id: 'filterAny' }) })

  const handleChange = (norm: Co2EuroNorm | undefined) => {
    filterStore.setCo2EuroNorm(norm)
  }

  return useObserver(() => {
    return (
      <Wrapper>
        <label>
          <FormattedMessage id="co2EuroNorm" /> :
        </label>
        <Select<string>
          className="co2EuroNormFilter"
          style={{ width: '100%' }}
          onChange={(value: any) => handleChange(value)}
          value={filterStore.filters.co2EuroNorm?.toString()}
          placeholder={<FormattedMessage id="co2EuroNorm" />}
          options={options}
        />
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  color: #fff;
  label {
    line-height: 39.9999px;
  }
`

export { Co2EuroNormFilter }
