import React from 'react'

const Tr: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.42 43.51">
      <path
        d="M65.27,43.51H1.15A1.15,1.15,0,0,1,0,42.37V1.15A1.14,1.14,0,0,1,1.15,0H65.27a1.14,1.14,0,0,1,1.15,1.15h0V42.37A1.15,1.15,0,0,1,65.27,43.51Z"
        fill="#ff4b55"
      />
      <path
        d="M32.88,17.83l1.79,2.35,2.8-.94a.17.17,0,0,1,.22.09.17.17,0,0,1,0,.17L36,21.94l1.76,2.38a.17.17,0,0,1,0,.24.17.17,0,0,1-.17,0l-2.83-.84L33,26.14a.16.16,0,0,1-.24,0,.17.17,0,0,1-.07-.14l-.07-3-2.82-.89a.17.17,0,0,1,0-.33l2.79-1v-3a.18.18,0,0,1,.24-.05Z"
        fill="#f5f5f5"
      />
      <path
        d="M23,30.92a9.17,9.17,0,0,1,0-18.33,9,9,0,0,1,5,1.56.22.22,0,0,0,.29-.33,11.45,11.45,0,1,0,0,15.88.21.21,0,0,0,0-.31.22.22,0,0,0-.29,0A9,9,0,0,1,23,30.92Z"
        fill="#f5f5f5"
      />
    </svg>
  )
}

export { Tr }
