import React from 'react'
import { useObserver } from 'mobx-react'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../../../hooks'
import { CarInventory } from '../../../../types'
import { Checkbox } from '../../../'

const CarInventoryFilter: React.FC = () => {
  const filterStore = useFilterStore()

  const onChange = <K extends keyof CarInventory>(key: K, value: boolean) => {
    filterStore.setCarInventory(key, value)
  }

  return useObserver(() => {
    const { companyCar, oneYearOldCar, usedCar } = filterStore.filters.vehicleInventory

    return (
      <Row gutter={[0, 10]}>
        <Col key="companyCar" span={6}>
          <Checkbox checked={companyCar} onChange={(e) => onChange('companyCar', e.target.checked)}>
            <FormattedMessage id="carInventoryValues.companyCar" />
          </Checkbox>
        </Col>
        <Col key="oneYearOldCar" span={6}>
          <Checkbox checked={oneYearOldCar} onChange={(e) => onChange('oneYearOldCar', e.target.checked)}>
            <FormattedMessage id="carInventoryValues.oneYearOldCar" />
          </Checkbox>
        </Col>
        <Col key="usedCar" span={6}>
          <Checkbox checked={usedCar} onChange={(e) => onChange('usedCar', e.target.checked)}>
            <FormattedMessage id="carInventoryValues.usedCar" />
          </Checkbox>
        </Col>
      </Row>
    )
  })
}

export { CarInventoryFilter }
