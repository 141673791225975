import React, { useEffect } from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { useAppStore } from '../../hooks'

import {
  VehicleTypeSwitcher,
  CarFilterLink,
  PriceFilterLink,
  RegistrationFilterLink,
  MileageFilterLink,
  LocationFilterLink,
  GWNumberFilterLink,
} from '../'

interface SimpleFilterProps {}

const SimpleFilter: React.FC<SimpleFilterProps> = () => {
  const appStore = useAppStore()

  useEffect(() => {
    appStore.setLastActiveFilter('simple')
  })

  return useObserver(() => (
    <Wrapper>
      <VehicleTypeSwitcher />

      <CarFilterLink />
      <PriceFilterLink />
      <RegistrationFilterLink />
      <MileageFilterLink />
      <LocationFilterLink />
      <GWNumberFilterLink />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  padding: 25px;
`

export { SimpleFilter }
