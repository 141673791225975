import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { FormattedMessage, useIntl } from 'react-intl'

import { useCarStore } from '../../hooks'
import { Row, Col } from 'antd'

const CarDetailContact: React.FC<{}> = () => {
  const { formatMessage } = useIntl()
  const carStore = useCarStore()

  return useObserver(() => {
    const tel = formatMessage({ id: carStore.car?.location.isGmbh ? 'contact.telephoneGmbh' : 'contact.telephone' })

    return (
      <Wrapper id="contact">
        <Row gutter={[{ xs: 0, lg: 20 }, 20]} type="flex" align="middle">
          <Col lg={11}>
            {carStore.car?.location.isGmbh ? (
              <img src="/react/gwsuche/img/callcenter.jpg" alt="Ihr Ansprechpartner" />
            ) : (
              <img src="/react/gwsuche/img/bdc_team.jpg" alt="Ihr Ansprechpartner" />
            )}
          </Col>

          <Col lg={13}>
            <h2>
              <FormattedMessage id="contactPerson" />
            </h2>
            <FormattedMessage id="contactDepartment" />
            <br />
            <br />
            Tel.: <a href={`tel:${tel}`}>{tel}</a>
          </Col>
        </Row>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  background-color: #0e0e0e;

  ${breakpoint('tablet')`
    background-color: transparent;
  `}

  img {
    width: 100%;
  }

  h2 {
    font-family: 'CorporateA', serif;
    font-size: 25px;
    line-height: 1;
    color: #fff;
    margin: 0px;
    line-height: 25px;
  }

  a {
    color: #fff;
  }
`

export { CarDetailContact }
