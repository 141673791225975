import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { Icon } from '../../components'

const Wrapper = styled.div`
  background-image: linear-gradient(#1d3742, #18323d);
  padding: 10px 15px;
  border-radius: 20px;
  color: #00adf0;
  margin-right: 10px;
  display: flex;
  white-space: nowrap;

  & .filter {
    margin-right: 10px;
  }

  & .custom-icon {
    transition: all 300ms;
    font-size: 18px;
    line-height: 23px;

    &:hover {
      color: #fff;
      font-weight: 600;
      cursor: pointer;
    }
  }

  height: 40px;
`

interface ResultListFilterProps {
  label: string | React.ReactNode
  onRemove?: () => void
}

const ResultListFilter: React.FC<ResultListFilterProps> = ({ label, onRemove }) => {
  return useObserver(() => (
    <Wrapper className="result-list-filter">
      <div className="filter">{label}</div>
      <span onClick={onRemove}>
        <Icon type="close" />
      </span>
    </Wrapper>
  ))
}

export { ResultListFilter }
