import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedNumber } from 'react-intl'
import styled from 'styled-components'

import { useCarStore, useCarListStore, useRouterStore } from '../../hooks'
import { Icon } from '../Icon'

const CarDetailNextPrev: React.FC<{}> = () => {
  const routerStore = useRouterStore()
  const carStore = useCarStore()
  const carListStore = useCarListStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return useObserver(() => {
    const prevCar = carStore.car ? carListStore.getPrevCar(carStore.car._id) : null
    const nextCar = carStore.car ? carListStore.getNextCar(carStore.car._id) : null
    const carIndex = carStore.car ? carListStore.getCarIndex(carStore.car._id) + 1 : null

    return carIndex && carListStore.total ? (
      <Wrapper>
        {prevCar && (
          <Prev onClick={() => navigate(`/fahrzeug-detailansicht/${prevCar._id}/${prevCar.link}`)}>
            <Icon type="arrow-left" />
          </Prev>
        )}

        <Summary>
          {<FormattedNumber value={carIndex} />}/{<FormattedNumber value={carListStore.total} />}
        </Summary>

        {nextCar && (
          <Next onClick={() => navigate(`/fahrzeug-detailansicht/${nextCar._id}/${nextCar.link}`)}>
            <Icon type="arrow-right" />
          </Next>
        )}
      </Wrapper>
    ) : null
  })
}

const Prev = styled.div``
const Next = styled.div``
const Summary = styled.div``

const Wrapper = styled.div`
  display: flex;

  ${Summary} {
    margin-left: 15px;
    margin-right: 15px;
  }

  ${Next},
  ${Prev} {
    &:hover {
      background-color: #1daeee;
      cursor: pointer;
    }
  }

  ${Next},
  ${Prev},
  ${Summary} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #fff;
    background-color: #252525;
    padding: 10px 15px 10px 15px;

    transition: all 300ms;
  }

  .custom-icon {
    font-size: 20px;
  }
`

export { CarDetailNextPrev }
