import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon, RangeFilterLabel } from '../'
import { useFilterStore } from '../../hooks'

interface PriceFilterLinkProps {}

const PriceFilterLink: React.FC<PriceFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  const price = filterStore.filters.price

  return useObserver(() => (
    <FilterView label="priceFilterLabel" link="/filter/price" badge={price.lte || price.gte ? <Icon type="check" /> : 0}>
      <RangeFilterLabel filter={filterStore.filters.price} unit="€" />
    </FilterView>
  ))
}

export { PriceFilterLink }
