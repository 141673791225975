import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }

  ${CheckedButton} {
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const filters = [
  { value: 3, label: <FormattedMessage id="fuels.LPG" /> },
  { value: 1, label: <FormattedMessage id="fuels.PETROL" /> },
  { value: 2, label: <FormattedMessage id="fuels.DIESEL" /> },
  { value: 6, label: <FormattedMessage id="fuels.ELECTRIC" /> },
  { value: 4, label: <FormattedMessage id="fuels.NATURAL_GAS" /> },
  { value: 9, label: <FormattedMessage id="fuels.ETHANOL" /> },
  { value: 7, label: <FormattedMessage id="fuels.HYBRID_PETROL" /> },
  { value: 10, label: <FormattedMessage id="fuels.HYBRID_DIESEL" /> },
  { value: 8, label: <FormattedMessage id="fuels.HYDROGEN" /> },
  { value: 0, label: <FormattedMessage id="fuels.OTHER" /> },
]

interface FuelFilterPageProps {}

const FuelFilterPage: React.FC<FuelFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: number) => {
    filterStore.addOrRemoveFuel(value)
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="fuelFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        {filters.map(filter => (
          <CheckedButton
            onClick={() => onFilterChange(filter.value)}
            label={filter.label}
            active={filterStore.filters.engine.fuels.includes(filter.value)}
            key={filter.value}
          />
        ))}
      </Wrapper>
    </Page>
  ))
}

export { FuelFilterPage }
