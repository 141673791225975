import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { $enum } from 'ts-enum-util'

import { useFilterStore } from '../../hooks'
import { CheckedButton } from '../../components'

import { GeneralEquipment, GeneralEquipmentUtilityVehicle } from '../../types'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  color: #5d5d5d;
`

interface GeneralEquipmentFilterProps {}

const GeneralEquipmentFilter: React.FC<GeneralEquipmentFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: GeneralEquipment | GeneralEquipmentUtilityVehicle) => {
    filterStore.addOrRemoveGeneralEquipment(value)
  }

  return useObserver(() => (
    <Wrapper>
      {$enum(GeneralEquipment)
        .getValues()
        .map((filter) => (
          <CheckedButton
            onClick={() => onFilterChange(filter)}
            label={<FormattedMessage id={`equipment.${GeneralEquipment[filter]}`} />}
            active={filterStore.filters.equipment.general.includes(filter)}
            key={filter}
          />
        ))}
      {filterStore.filters.utilityVehicle
        ? $enum(GeneralEquipmentUtilityVehicle)
            .getValues()
            .map((filter) => (
              <CheckedButton
                onClick={() => onFilterChange(filter)}
                label={<FormattedMessage id={`equipment.${GeneralEquipmentUtilityVehicle[filter]}`} />}
                active={filterStore.filters.equipment.general.includes(filter)}
                key={filter}
              />
            ))
        : null}
    </Wrapper>
  ))
}

export { GeneralEquipmentFilter }
