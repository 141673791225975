import React, { useEffect, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { AuEmbedConfig, auEmbedConfig } from './'
import { Button } from '../'
import { usercentricsHelper } from '../../helpers/usercentrics'

declare global {
  interface Window {
    AuEmbed: {
      config: AuEmbedConfig
    }
  }
}

interface AuEmbedHandler {
  reset: () => void
  TradeInCalculatorWidgetEmbedder: {
    openTradeInModal: (config: AuEmbedConfig) => void
  }
}

declare const AuEmbed: AuEmbedHandler
declare type AuEmbedTypes = 'cta' | 'form'

interface AuEmbedProps {
  config?: Partial<AuEmbedConfig>
  type?: AuEmbedTypes
}

const AuEmbedFC: React.FC<AuEmbedProps> = ({ config, type }) => {
  const [element, setElement] = useState<HTMLElement | null>(null)

  const scriptId = 'au-trade-in-script'

  const mergedConfig = {
    ...auEmbedConfig,
    ...config,
  }

  const reset = useCallback(() => {
    typeof AuEmbed !== 'undefined' && AuEmbed.reset && AuEmbed.reset()
    if (element) {
      element.remove()
    }
  }, [element])

  const appendScript = useCallback(() => {
    reset()

    window.AuEmbed = {
      config: mergedConfig,
    }

    const s = document.createElement('script')
    s.type = usercentricsHelper().isAutoUncleAccepted() ? 'text/javascript' : 'text/plain'
    s.async = true
    s.src = 'https://www.autouncle.de/widgets/trade_in_valuations/embed_script.js?config_key=' + encodeURIComponent(mergedConfig.key)
    s.id = scriptId
    s.setAttribute('data-usercentrics', 'AutoUncle Aps')
    const h = document.getElementsByTagName('head')[0]
    h.insertBefore(s, h.lastChild)
  }, [mergedConfig, reset])

  useEffect(() => {
    if (!element) {
      appendScript()
    }

    setElement(document.getElementById(scriptId))

    return () => {
      reset()
    }
  }, [element, appendScript, reset])

  const openModal = () => {
    AuEmbed.TradeInCalculatorWidgetEmbedder.openTradeInModal(mergedConfig)
  }

  const renderCta = () => (
    <Cta block={true} onClick={() => openModal()} className={'tradeInButton'}>
      <FormattedMessage id="auCta" />
    </Cta>
  )

  const renderForm = () => (
    <>
      <div className="au-trade-in-form-inline-auto"></div>
      <div className="au-trade-in-backlink" style={{ textAlign: 'right', fontSize: '12px', color: '#515c5e', fontFamily: 'sans-serif' }}>
        <span>
          <FormattedMessage id="auFormHeadline" />
        </span>
        <img style={{ height: '16px' }} src="https://www.autouncle.de/widgets/au_backlink_logo" alt="AutoUncle" />
      </div>
    </>
  )

  if (type) {
    switch (type) {
      case 'cta':
        return renderCta()

      case 'form':
        return renderForm()
    }
  }

  return null
}

const Cta = styled(Button as any)`
  transition: all 300ms;

  &:hover {
    background-color: #252525;
    border-color: #252525;
    color: #fff;

    .custom-icon {
      svg {
        fill: #fff;
      }
    }

    cursor: pointer;
  }

  .custom-icon {
    svg {
      fill: #fff;
    }
  }
`

export { AuEmbedFC }
