import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface GearboxFilterLinkProps {}

const GearboxFilterLink: React.FC<GearboxFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <FilterView label="gearboxFilterLabel" link="/filter/gearbox" badge={filterStore.getGearboxFilterAsLabels.length}>
      {filterStore.getGearboxFilterAsLabels.length
        ? filterStore.getGearboxFilterAsLabels.map(label => (
            <div key={label}>
              <FormattedMessage id={`gearboxes.${label}`} />
            </div>
          ))
        : null}
    </FilterView>
  ))
}

export { GearboxFilterLink }
