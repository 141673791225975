import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from 'apollo-boost'
import flatten from 'flat'
import { Router } from 'react-router'
import { ApolloProvider } from 'react-apollo'
import { Provider, Observer } from 'mobx-react'
import { IntlProvider } from 'react-intl'
import { createBrowserHistory } from 'history'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { BreakpointProvider } from 'react-socks'
import { $enum } from 'ts-enum-util'

import App from './App'
import { Language } from './types'
import translations from './i18n'
import { AppStore, FilterStore, CarListStore, CarStore, RateCalcStore } from './stores'
import * as serviceWorker from './serviceWorker'

import 'mobx-react-lite/batchingOptOut'

const rootElement = document.getElementById('root')

const browserHistory = createBrowserHistory({
  basename: '/de/gebrauchtwagen',
})

const messages: any = translations

const userid = rootElement?.getAttribute('data-user') || null
const location = rootElement?.getAttribute('data-standort') || null
const brand = rootElement?.getAttribute('data-marke') || null
const inventory = rootElement?.getAttribute('data-inventory') || null

const stores = {
  appStore: new AppStore({
    userid,
    language: Language[$enum(Language).getKeyOrDefault(navigator.language.substring(0, 2)) || 'German'],
  }),
  routerStore: new RouterStore(),
  filterStore: new FilterStore({
    location,
    brand,
    inventory,
  }),
  carListStore: new CarListStore(),
  carStore: new CarStore(),
  rateCalcStore: new RateCalcStore(),
}

const synchronizedHistory = syncHistoryWithStore(browserHistory, stores.routerStore)

const client = new ApolloClient({
  // uri: 'http://127.0.0.1:4000',
  uri: 'https://api.sug-gebrauchtwagen.de',
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider {...stores}>
      <Router history={synchronizedHistory}>
        <Observer>
          {() => (
            <IntlProvider
              locale={stores.appStore.language}
              key={stores.appStore.language}
              messages={flatten(messages[stores.appStore.language])}
            >
              <BreakpointProvider>
                <App />
              </BreakpointProvider>
            </IntlProvider>
          )}
        </Observer>
      </Router>
    </Provider>
  </ApolloProvider>,
  rootElement
)

serviceWorker.unregister()
