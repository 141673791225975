import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Route, useRouteMatch } from 'react-router-dom'

import { CarFilter } from '../../types'
import { useFilterStore, useRouterStore } from '../../hooks'
import { Page, BrandFilter, ModelFilter, FilterLink, CarFilterList, Header, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  padding: 25px;

  .car-filter-view {
    margin-bottom: 25px;
  }

  .selectedCarsLabel {
    margin-bottom: 16px;
    color: #5d5e5f;
  }
`

interface CarFilterPage {}

const CarFilterPage: React.FC<CarFilterPage> = () => {
  const [brand, setBrand] = useState('')

  const { path } = useRouteMatch()
  const intl = useIntl()

  const filterStore = useFilterStore()
  const routerStore = useRouterStore()

  const onBrandSelect = (brand: string) => {
    setBrand(brand)
    routerStore.push(`/filter/car/brands/${encodeURIComponent(brand)}/models`)
  }

  const onModelSelect = (car: CarFilter) => {
    filterStore.addCar(car)
    routerStore.push(`/filter/car`)
  }

  return (
    <>
      <Route exact path={`${path}/`}>
        <Page
          header={<Header title={<FormattedMessage id="carFilterLabel" />} toolbar={<NavigateToFilter />} />}
          footer={<Footer showMeta={false} />}
        >
          <Wrapper>
            <CarFilterList></CarFilterList>
            <FilterLink label={<FormattedMessage id="addCar" />} link="/filter/car/brands" />
          </Wrapper>
        </Page>
      </Route>

      <Route exact path={`${path}/brands`}>
        <Page
          header={<Header title={<FormattedMessage id="brand" />} withBack backLabel={intl.formatMessage({ id: 'overview' })} />}
          footer={<Footer showMeta={false} />}
        >
          <Wrapper>
            <BrandFilter onBrandSelect={onBrandSelect} />
          </Wrapper>
        </Page>
      </Route>

      <Route exact path={`${path}/brands/:brand/models`}>
        <Page
          header={<Header title={brand} withBack backLabel={intl.formatMessage({ id: 'brand' })} />}
          footer={<Footer showMeta={false} />}
        >
          <Wrapper>
            <ModelFilter onModelSelect={onModelSelect} />
          </Wrapper>
        </Page>
      </Route>
    </>
  )
}

export { CarFilterPage }
