import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import styled from 'styled-components'

import { CheckedButton, RangeFilterForm } from '../'
import { useFilterStore } from '../../hooks'
import { RangeFilter } from '../../types'
import { doesFilterMatch } from '../../helpers/rangeFilter'

interface PowerFilterTabProps {}

const Wrapper = styled.div`
  padding: 25px;
  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

const PowerFilterTab: React.FC<PowerFilterTabProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: RangeFilter) => {
    filterStore.setPowerFilter(value)
  }

  const filters = [
    {
      label: (
        <FormattedMessage id="filterLabel.from" values={{ from: <FormattedNumber value={150} />, symbol: filterStore.filters.powerType }} />
      ),
      value: {
        gte: 150,
        lte: null,
      },
    },
    {
      label: (
        <FormattedMessage id="filterLabel.to" values={{ to: <FormattedNumber value={200} />, symbol: filterStore.filters.powerType }} />
      ),
      value: {
        gte: null,
        lte: 200,
      },
    },
    {
      label: (
        <FormattedMessage
          id="filterLabel.range"
          values={{ from: <FormattedNumber value={125} />, to: <FormattedNumber value={200} />, symbol: filterStore.filters.powerType }}
        />
      ),
      value: {
        gte: 125,
        lte: 200,
      },
    },
  ]

  return useObserver(() => (
    <Wrapper>
      <RangeFilterForm values={filterStore.filters.power} onChange={onFilterChange} unit={filterStore.filters.powerType} />
      <FormattedMessage id="popular" />
      {filters.map((filter, i) => (
        <CheckedButton
          onClick={() => onFilterChange(filter.value)}
          label={filter.label}
          active={doesFilterMatch(filterStore.filters.power, filter.value)}
          key={i}
        />
      ))}
    </Wrapper>
  ))
}

export { PowerFilterTab }
