import React from 'react'

const Mileage: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.8 128.8">
      <path
        d="M64.39,115.91a51.52,51.52,0,1,1,51.52-51.52,51.52,51.52,0,0,1-51.52,51.52M64.39,0a64.4,64.4,0,1,0,64.4,64.4h0A64.42,64.42,0,0,0,64.39,0"
        transform="translate(0.01 0)"
        fill="#5d5e5f"
      />
      <path d="M37.09,42.93a39.43,39.43,0,0,0-8.48,21.46H57.24Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M71.55,64.39,91.7,85.86a39.45,39.45,0,0,0,8.47-21.47Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M64.39,28.62A39.27,39.27,0,0,0,42.93,37L64.39,57.24Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M64.39,71.55v28.62a39.32,39.32,0,0,0,21.47-8.38Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M100.17,64.39a39.18,39.18,0,0,0-8.51-21.46L71.55,64.39Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M85.86,37.05a39.62,39.62,0,0,0-21.47-8.43V57.24Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M57.24,64.39H28.62a39.06,39.06,0,0,0,8.51,21.47Z" transform="translate(0.01 0)" fill="#5d5e5f" />
      <path d="M42.93,91.79a39.66,39.66,0,0,0,21.46,8.43V71.55Z" transform="translate(0.01 0)" fill="#5d5e5f" />
    </svg>
  )
}

export { Mileage }
