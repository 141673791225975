import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface EquipmentFilterLinkProps {}

const EquipmentFilterLink: React.FC<EquipmentFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const equipment = filterStore.getEquipmentFilter
    return (
      <FilterView label="equipmentFilterLabel" link="/filter/equipment" badge={equipment.length}>
        {equipment.length
          ? equipment.map(eq => (
              <div key={eq}>
                <FormattedMessage id={`equipment.${eq}`} />
              </div>
            ))
          : null}
      </FilterView>
    )
  })
}

export { EquipmentFilterLink }
