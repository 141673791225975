import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface PageProps {
  header?: ReactNode
  footer?: ReactNode
}

const Page: React.FC<PageProps> = ({ header, footer, children, ...rest }) => {
  return (
    <>
      {header}

      <ContentWrapper withPaddingTop={header ? true : false} withPaddingBottom={footer ? true : false}>
        <Content className="page">{children}</Content>
      </ContentWrapper>

      {footer}
    </>
  )
}

const ContentWrapper = styled.main<{ withPaddingTop: boolean; withPaddingBottom: boolean }>`
  // padding-top: ${(props) => (props.withPaddingTop ? '75px' : 0)};
  // padding-bottom: ${(props) => (props.withPaddingBottom ? '139px' : 0)};
`

const Content = styled.div``

export { Page }
