import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import { useObserver } from 'mobx-react'
import { toJS } from 'mobx'
import { Select as AntSelect } from 'antd'

import { Form, InputGroup, InputNumber, FormItem, Select } from '../'
import { RateCalculatorFormType } from '../../types'
import { useRateCalcStore } from '../../hooks'

declare global {
  interface Window {
    dataLayer: any
  }
}

const { Option } = AntSelect
export interface RateCalcFormProps extends FormComponentProps {
  onChange: (values: RateCalculatorFormType) => void
  unit?: string
  values?: RateCalculatorFormType
}

const RateCalcFormFC = forwardRef<FormComponentProps, RateCalcFormProps>(({ form, onChange, unit, values }, ref) => {
  window.dataLayer = window.dataLayer || []

  useImperativeHandle(ref, () => ({
    form,
    onChange,
  }))

  const { formatNumber } = useIntl()
  const rateCalcStore = useRateCalcStore()

  useEffect(() => {
    if (values) {
      const current = form.getFieldsValue()

      if (Object.keys(current).some((key: string) => current[key] !== values[key])) {
        form.setFieldsValue(toJS(values))
      }
    }
  }, [values, form])

  const format = (value: string | number | undefined): string => {
    return `${value ? formatNumber(Number(value)) : ''} €`
  }

  const parse = (value: any) => {
    if (value) {
      return `${value}`.replace(/\D+/gm, '')
    }
    return ''
  }

  /**
   *
   * @param customerType
   */
  const onCustomerTypeChange = (customerType: any) => {
    const dataLayerEvent = {
      event: 'changeCustomerType',
      customerType: customerType,
    }

    console.log('newDataLayerEvent', dataLayerEvent)
    window.dataLayer.push(dataLayerEvent)
  }

  /**
   *
   * @param calculationType
   */
  const onCalculationTypeChange = (calculationType: any) => {
    const dataLayerEvent = {
      event: 'changeCalculationType',
      calculationType: calculationType,
    }

    console.log('newDataLayerEvent', dataLayerEvent)
    window.dataLayer.push(dataLayerEvent)
  }

  /**
   *
   * @param periodMonths
   */
  const onPeriodMonthsChange = (periodMonths: any) => {
    const dataLayerEvent = {
      event: 'changePeriodMonths',
      periodMonths: periodMonths,
    }

    console.log('newDataLayerEvent', dataLayerEvent)
    window.dataLayer.push(dataLayerEvent)
  }

  /**
   *
   * @param totalMileage
   */
  const onTotalMileageChange = (totalMileage: any) => {
    const dataLayerEvent = {
      event: 'changeTotalMileage',
      totalMileage: totalMileage,
    }

    console.log('newDataLayerEvent', dataLayerEvent)
    window.dataLayer.push(dataLayerEvent)
  }

  return useObserver(() => (
    <Wrapper>
      <Form>
        <InputGroup>
          <Row>
            <Col xs={24}>
              <FormItem label={<FormattedMessage id="customerType" />}>
                {form.getFieldDecorator('customer_type')(
                  <Select placeholder={<FormattedMessage id="pleaseChoose" />} onChange={onCustomerTypeChange}>
                    {rateCalcStore.formItems.customer_type.length &&
                      rateCalcStore.formItems.customer_type.map((option: any) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              <FormItem label={<FormattedMessage id="calculationType" />}>
                {form.getFieldDecorator('calculation_type')(
                  <Select placeholder={<FormattedMessage id="pleaseChoose" />} onChange={onCalculationTypeChange}>
                    {rateCalcStore.formItems.calculation_type.length &&
                      rateCalcStore.formItems.calculation_type.map((option: any) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              <FormItem label={<FormattedMessage id="depositAmount" />}>
                {form.getFieldDecorator('deposit_amount')(<InputNumber formatter={format} parser={parse} />)}
              </FormItem>

              <FormItem label={<FormattedMessage id="periodMonths" />}>
                {form.getFieldDecorator('period_months')(
                  <Select placeholder={<FormattedMessage id="pleaseChoose" />} onChange={onPeriodMonthsChange}>
                    {rateCalcStore.formItems.period_months.length &&
                      rateCalcStore.formItems.period_months.map((option: any) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              <FormItem label={<FormattedMessage id="totalMileage" />} style={rateCalcStore.form.total_mileage ? {} : { display: 'none' }}>
                {form.getFieldDecorator('total_mileage')(
                  <Select placeholder={<FormattedMessage id="pleaseChoose" />} onChange={onTotalMileageChange}>
                    {rateCalcStore.formItems.total_mileage.length &&
                      rateCalcStore.formItems.total_mileage.map((option: any) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </Wrapper>
  ))
})

const RateCalcForm = Form.create<RateCalcFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(RateCalcFormFC)

const Wrapper = styled.div`
  .ant-form-item {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`

export { RateCalcForm }
