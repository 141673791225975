import React from 'react'
import { useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'

import { useFilterStore } from '../../hooks'
import { Select } from '../Select'
import { Fuel } from '../../types'

const FuelFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const handleChange = (fuel: number) => {
    if (!fuel) {
      filterStore.filters.engine.fuels = []
    } else {
      filterStore.filters.engine.fuels = [fuel]
    }
  }

  const fuels = [
    { value: undefined, label: 'filterAny' },
    { value: 3, label: 'fuels.LPG' },
    { value: 1, label: 'fuels.PETROL' },
    { value: 2, label: 'fuels.DIESEL' },
    { value: 6, label: 'fuels.ELECTRIC' },
    { value: 4, label: 'fuels.NATURAL_GAS' },
    { value: 9, label: 'fuels.ETHANOL' },
    { value: 7, label: 'fuels.HYBRID_PETROL' },
    { value: 10, label: 'fuels.HYBRID_DIESEL' },
    { value: 8, label: 'fuels.HYDROGEN' },
    { value: 0, label: 'fuels.OTHER' },
  ].map((f) => ({
    ...f,
    ...{
      label: formatMessage({ id: f.label }),
    },
  }))

  return useObserver(() => (
    <Select<Fuel>
      style={{ width: '100%' }}
      onChange={(value: Fuel) => handleChange(value)}
      value={(filterStore.filters.engine.fuels.length && filterStore.filters.engine.fuels[0]) || undefined}
      placeholder={formatMessage({ id: 'fuelFilterLabel' })}
      options={fuels}
    />
  ))
}

export { FuelFilter }
