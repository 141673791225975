import React from 'react'

const FirstRegistration: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.85 129.49">
      <path
        d="M102.46,11.77H96.77V0H85.39V11.77H28.46V0H17.08V11.77H11.39A11.62,11.62,0,0,0,0,23.55v94.16a11.62,11.62,0,0,0,11.39,11.78h91.07a11.62,11.62,0,0,0,11.39-11.78V23.55a11.62,11.62,0,0,0-11.39-11.78m0,105.94H11.39V41.2h91.07Z"
        transform="translate(0)"
        fill="#5d5e5f"
      />
    </svg>
  )
}

export { FirstRegistration }
