import React from 'react'
import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

const StyledButton = styled(AntButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 0 !important;
  width: 42px;
  height: 42px;
  margin-left: 16px;
  border-width: 3px;
  border-radius: 0;
  color: #fff;
  border-color: #1daeee;
  background-color: transparent !important;
  font-size: 24px;
  line-height: 18px;

  &:hover {
    color: #1daeee;
  }
`

const ParkButton: React.FC<ButtonProps> = props => {
  return <StyledButton {...props}>P</StyledButton>
}

export { ParkButton }
