import React from 'react'
import { useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { Input } from '../'
import { useFilterStore } from '../../hooks'

const InternalNumberFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()

  const { formatMessage } = useIntl()

  const handleChange = (e: any) => (filterStore.filters.gwNumber = e.target.value)

  return useObserver(() => (
    <StyledInput onChange={handleChange} value={filterStore.filters.gwNumber} placeholder={formatMessage({ id: 'gwNumberFilterLabel' })} />
  ))
}

const StyledInput = styled(Input as any)`
  height: 50px;
`

export { InternalNumberFilter }
