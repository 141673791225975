import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { DetailNewLink } from './DetailNewLink'
import { MatchesButton } from './MatchesButton'
import { LanguageSwitcher } from './LanguageSwitcher'

const FooterDesktop: React.FC = () => {
  return (
    <div className="footerDesktop">
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <LanguageSwitcher />
        </Col>

        <ResultCol>
          <DetailNewLink />
          <MatchesButton />
        </ResultCol>
      </Row>
    </div>
  )
}

const ResultCol = styled(Col)`
  display: flex;
  align-items: center;

  .matches-btn {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #222222;

    &:hover {
      color: #fff;
      background-color: #1daeee;
      border-color: #1daeee;
    }
  }

  .ant-btn-link {
    color: #bfbfbf;
    text-decoration: underline !important;

    &:hover {
      color: #1daeee;
    }

    .custom-icon {
      display: none;
    }
  }
`

export { FooterDesktop }
