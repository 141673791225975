import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Breakpoint } from 'react-socks'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col } from 'antd'
import { $enum } from 'ts-enum-util'
import { Language } from '../../types'

import { useCarStore } from '../../hooks'
import { Collapse, Panel } from '../'

export interface CarDetailEquipmentProps {
  showHeadline?: boolean
}

const CarDetailEquipment: React.FC<CarDetailEquipmentProps> = ({ showHeadline }) => {
  const carStore = useCarStore()
  const { locale } = useIntl()

  const lang = Language[$enum(Language).getKeyOrDefault(locale, 'German')]

  const eqCategories = [
    'Allgemein',
    'Exterieur',
    'Schaltung',
    'Media',
    'Interieur',
    'Weitere Informationen',
    'Sicherheit',
    'Komfort',
    'Technik',
    'Sonstiges',
    'Sportive',
    'Pakete',
  ]

  return useObserver(() => (
    <Wrapper className="carDetailEqTab">
      <Breakpoint small down>
        <Collapse defaultActiveKey={eqCategories} expandIconPosition="right">
          {eqCategories.map(
            (eqCategory) =>
              carStore.car?.equipmentTranslations[lang][eqCategory] && (
                <Panel header={<FormattedMessage id={`equipmentCategory.${eqCategory}`} />} key={eqCategory}>
                  <ul>
                    {carStore.car?.equipmentTranslations[lang][eqCategory].map((equipment: string) => (
                      <li key={equipment}>{equipment}</li>
                    ))}
                  </ul>
                </Panel>
              )
          )}
        </Collapse>
      </Breakpoint>
      <Breakpoint medium up>
        {showHeadline ? (
          <h2>
            <FormattedMessage id="specialEq" />
          </h2>
        ) : null}
        {eqCategories.map(
          (eqCategory) =>
            carStore.car?.equipmentTranslations[lang][eqCategory] && (
              <div key={eqCategory} className="category">
                <h4>
                  <FormattedMessage id={`equipmentCategory.${eqCategory}`} />
                </h4>
                <Row>
                  {carStore.car?.equipmentTranslations[lang][eqCategory].map((equipment: string) => (
                    <Col key={equipment}>{equipment}</Col>
                  ))}
                </Row>
              </div>
            )
        )}
      </Breakpoint>
    </Wrapper>
  ))
}

CarDetailEquipment.defaultProps = {
  showHeadline: true,
}

const Wrapper = styled.div`
  .ant.collapse {
    .ant-collapse-item:not(:last-child) {
      margin-bottom: 0px;
    }
  }

  .ant-collapse-content-box {
    padding-left: 0px;
    padding-right: 0px;
  }

  h2 {
    color: #fff;
  }

  h4 {
    font-size: 15px;
    color: #565758;
  }

  .category {
    &:not(:first-child) {
      margin-top: 25px;
    }

    h4 {
      border-bottom: 1px solid #383838;
      margin-bottom: 0px;
    }

    .ant-col {
      padding-top: 5px;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
    li {
      padding-top: 5px;
      &:not(:last-child) {
        padding-bottom: 5px;
      }
    }
  }
`

export { CarDetailEquipment }
