import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useObserver } from 'mobx-react'

import { park } from '../../helpers/park'
import { ParkLoginModal, ParkSuccModal } from '../'
import { useAppStore } from '../../hooks'

export interface ParkModalHandles extends ParkModalProps {
  setCarId: (id: string) => void
  doPark: () => void
}

interface ParkModalProps {}

// eslint-disable-next-line no-empty-pattern
const ParkModalFC: React.FC<ParkModalProps> = ({}, ref) => {
  const [isVisible, setVisible] = useState(false)
  const [carUid, setCarUid] = useState('')
  const [showSucc, setShowSucc] = useState(false)

  const appStore = useAppStore()

  const setCarId = (id: string) => {
    setCarUid(id)
    setVisible(true)
    if (appStore.isLoggedIn) {
      doPark(id)
    }
  }

  const doPark = (uid: string) => {
    park(uid)
    setShowSucc(true)
  }

  useImperativeHandle(ref, () => ({
    doPark,
    setCarId,
  }))

  return useObserver(() => {
    if (showSucc) {
      return (
        <ParkSuccModal
          visible={isVisible}
          onHide={() => {
            setVisible(false)
            setShowSucc(false)
          }}
        />
      )
    }

    return (
      <ParkLoginModal
        visible={isVisible}
        onHide={() => setVisible(false)}
        onPark={() => {
          doPark(carUid)
        }}
      />
    )
  })
}

const ParkModal = forwardRef(ParkModalFC as any)

export { ParkModal }
