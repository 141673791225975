import React from 'react'

const Ro: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.42 43.51">
      <path d="M22.14,43.51h-21A1.15,1.15,0,0,1,0,42.37V1.15A1.14,1.14,0,0,1,1.15,0h21Z" fill="#41479b" />
      <rect x="22.14" width="22.14" height="43.51" fill="#ffe15a" />
      <path d="M65.27,43.51h-21V0h21a1.14,1.14,0,0,1,1.15,1.15h0V42.37A1.15,1.15,0,0,1,65.27,43.51Z" fill="#ff4b55" />
    </svg>
  )
}

export { Ro }
