import React from 'react'

const Sv: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.42 43.52">
      <path
        d="M65.27,43.51H1.15A1.15,1.15,0,0,1,0,42.37V1.15A1.14,1.14,0,0,1,1.15,0H65.27a1.14,1.14,0,0,1,1.15,1.15h0V42.37A1.15,1.15,0,0,1,65.27,43.51Z"
        fill="#4173cd"
      />
      <polygon
        points="66.42 18.32 27.48 18.32 27.48 0 20.61 0 20.61 18.32 0 18.32 0 25.19 20.61 25.19 20.61 43.52 27.48 43.52 27.48 25.19 66.42 25.19 66.42 18.32"
        fill="#ffe15a"
      />
    </svg>
  )
}

export { Sv }
