import React from 'react'
import { reaction, toJS } from 'mobx'

import { useCarStore, useRateCalcStore, useAppStore, useFilterStore } from '../../hooks'
import { RateCalcFormItems, FormItemTypes } from '../../stores/RateCalcStore'
import { formItemTypes } from '../../stores/RateCalcStore'

const RateCalcHandler: React.FC = () => {
  const carStore = useCarStore()
  const rateCalcStore = useRateCalcStore()
  const appStore = useAppStore()
  const filterStore = useFilterStore()

  const API_URL = `https://ocapi.api.mercedes-benz.com/v3/${appStore.language}/calculations`

  const getData = (isInitial: boolean) => {
    const car = carStore.car

    if (!rateCalcStore.loading && car) {
      rateCalcStore.setLoading(true)

      const body = {
        vehicle: {
          fin: car.vin,
          prices: [
            {
              id: 'purchasePrice',
              currency: 'EUR',
              rawValue: rateCalcStore.getPrice(car),
            },
          ],
        },
        input: rateCalcStore.getRequestInputs,
      }

      load(body)
        .then((data: any) => {
          const formItems: RateCalcFormItems = {
            customer_type: [],
            calculation_type: [],
            period_months: [],
            total_mileage: [],
          }

          if (data.input?.items) {
            formItemTypes.forEach((item: FormItemTypes) => {
              const a = data.input.items.filter((i: any) => i.id === item)
              formItems[item] = a[0] ? a[0].values : []
            })

            rateCalcStore.setFormItems(formItems)
          }

          const depositAmout = data.input?.items.filter((i: any) => i.id === 'deposit_amount')[0]
          const periodMonths = data.input?.items.filter((i: any) => i.id === 'period_months')[0]
          const totalMileage = data.input?.items.filter((i: any) => i.id === 'total_mileage')[0]

          rateCalcStore.setFormData({
            deposit_amount: depositAmout ? depositAmout.value.value : null,
            period_months: periodMonths ? periodMonths.value.value : null,
            total_mileage: totalMileage ? totalMileage.value.value : null,
          })

          rateCalcStore.setOutput(data.output ? data.output.containers.filter((i: any) => i.id === 'output')[0].items : [])

          rateCalcStore.setLoading(false)

          if (isInitial) {
            rateCalcStore.initialDone()
          }
        })
        .catch((e: any) => {
          rateCalcStore.setOutput([])
          rateCalcStore.setLoading(false)
          console.error(`rateCalc: something went wrong`, e)
        })
    }
  }

  const load = (body: Object): any => {
    return new Promise((resolve, reject) => {
      fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': 'dd3deb2c-09c4-4b1c-bce9-10216387478a',
          'X-OCAPI-User-Interaction': 'calculation-changed',
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result)
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  // initial
  reaction(
    () => toJS(carStore.car),
    (car) => {
      getData(true)
    }
  )

  reaction(
    () => toJS(rateCalcStore.form),
    () => {
      getData(false)
    },
    {
      equals: (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
    }
  )

  /** sets the customer type */
  reaction(
    () => filterStore.filters.utilityVehicle,
    (type) => {
      rateCalcStore.setCustomerType(type ? 'business' : 'private')
    }
  )

  return null
}

export { RateCalcHandler }
