import React from 'react'

import { useFilterStore, useAppStore } from '../hooks'
import { Filter } from '../types/'

interface SavedSearchResponse {
  name: string
  filter: Filter
}

const SavedSearchHandler: React.FC = () => {
  const appStore = useAppStore()
  const filterStore = useFilterStore()

  const searchId = document.getElementById('root')?.getAttribute('data-saved-search') || null

  if (searchId && !appStore.isSavedFilterFetched) {
    fetch(`https://www.sug.de/de/mein-sg/reactsucheladen?search=${searchId}&type=999`)
      .then((res) => res.json())
      .then((data: SavedSearchResponse) => {
        filterStore.mergeFilter(data.filter)
        appStore.setSavedFilterFetched(true)
      })
      .catch((err) => console.log(err))
  }

  return null
}

export { SavedSearchHandler }
