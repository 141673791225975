import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { reaction, toJS } from 'mobx'
import { useQuery } from '@apollo/react-hooks'

import { useFilterStore, useCarListStore } from '../../hooks'

const GET_CARS = gql`
  query Cars($pagination: PaginationInput, $filter: CarFilterInput!) {
    cars(pagination: $pagination, filter: $filter) {
      docs {
        _id
        uid
        link
        brand
        name
        price
        priceWithoutTax
        mileage
        power
        firstRegistration
        firstRegistrationDate
        location {
          name
          street
          zipCode
          city
          phone
          isGmbh
          clickAndMeetOrClickAndCollect
          clickAndMeetUrl
        }
        emission
        fuelConsumption
        combinedPowerConsumption
        images {
          imagepath
        }
        model
        categories
        color
        engine {
          fuel
          gearbox
        }
        financing {
          rate
          regulatory
        }
        energyEfficiencyClass
        vatReportable
        warranty
        qualitySeal {
          youngStar
        }
        wltp {
          combined
          combinedPowerConsumption
          emission
        }
      }
      totalDocs
      totalPages
      linkList {
        _id
        link
      }
    }
  }
`

const CarHandler: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const carListStore = useCarListStore()

  const { data, loading, refetch } = useQuery(GET_CARS, {
    variables: filterStore.filtersForCarsQuery,
  })

  useEffect(() => {
    if (data) {
      carListStore.setLoading(loading)
      carListStore.setDocs(data.cars.docs)
      carListStore.setLinkList(data.cars.linkList)
      carListStore.setTotal(data.cars.totalDocs)
      carListStore.setPages(data.cars.totalPages)
    }
  })

  reaction(
    () => toJS(filterStore.filters),
    () => {
      console.log(toJS(filterStore.filtersForCarsQuery))

      if (filterStore.pagination.page > 1) {
        filterStore.changePage(1)
      } else {
        refetch(filterStore.filtersForCarsQuery)
      }

      console.log(toJS(filterStore.filtersForCarsQuery))
    }
  )

  reaction(
    () => toJS(filterStore.pagination),
    () => {
      refetch(filterStore.filtersForCarsQuery)
    }
  )

  return null
}

export default CarHandler
