import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { CarFilter } from '../../types'
import { Icon } from '../'
import { Input } from '../Input'

const Wrapper = styled.div`
  font-size: 13px;
  padding: 25px;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 25px;
  }
`

const View = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 18px;
  }

  .anticon {
    font-size: 18px;
  }

  .custom-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Variant = styled.div`
  margin-top: 10px;
`

interface CarFilterViewProps {
  car: CarFilter
  onRemove?: (event: React.MouseEvent) => void
  onVariant: (car: CarFilter, variant: any) => void
}

const CarFilterView: React.FC<CarFilterViewProps> = ({ car, onRemove, onVariant, ...rest }) => {
  return (
    <>
      <Wrapper className="car-filter-view">
        <View>
          <span className="title">{`${car.brand} ${car.model ? car.model : ''}`}</span>
          <span onClick={onRemove}>
            <Icon type="close" />
          </span>
        </View>

        <Variant>
          <FormattedMessage id="placeholder.variant">
            {(placeholder: string) => <Input onChange={event => onVariant(car, event.target.value)} placeholder={placeholder} />}
          </FormattedMessage>
        </Variant>
      </Wrapper>
    </>
  )
}

export { CarFilterView }
