interface AuEmbedConfig {
  key: string
  noDelayedCtaPage?: boolean
  stockId?: string
  customData: {
    leadSource?: string
  }
  onFormOpen?: () => void
  onCustomerDetailsSent?: () => void
  onValuationDone?: () => void
  onValuationFailed?: () => void
  onUnavailable?: () => void
}

const auEmbedConfig: AuEmbedConfig = {
  key: 'TN47IomKzctLJI8q0y4jrw',
  customData: {
    leadSource: 'Ankauf Lead',
  },
}

export { auEmbedConfig }
export type { AuEmbedConfig }
