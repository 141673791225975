import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'
import { Icon } from '../../components'

interface GWNumberFilterLinkProps {}

const GWNumberFilterLink: React.FC<GWNumberFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <FilterView label="gwNumberFilterLabel" link="/filter/gw" badge={filterStore.filters.gwNumber ? <Icon type="check" /> : 0}>
      {filterStore.filters.gwNumber ? <div>{filterStore.filters.gwNumber}</div> : null}
    </FilterView>
  ))
}

export { GWNumberFilterLink }
