import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'

import { usercentricsHelper } from '../../helpers/usercentrics'

interface AuWidgetEmbed {
  init: () => void
  disableLazyLoad: boolean
}

declare global {
  interface Window {
    auWidgetEmbed: AuWidgetEmbed
  }
}

declare const auWidgetEmbed: AuWidgetEmbed

declare type PriceRatingWidgetTheme = 'light' | 'dark' | 'transparent'
declare type PriceRatingWidgetLayout =
  | 'tall_small'
  | 'tall_no-border'
  | 'tall_smaller'
  | 'wide_without_price'
  | 'wide_without_price_with_explanation'
  | 'wider_no_border'
  | 'minimal_look'
  | 'with_price'

interface PriceRatingWidgetProps {
  link: string
  internalNumber: string
  theme?: PriceRatingWidgetTheme
  layout?: PriceRatingWidgetLayout
}

const PriceRatingWidget: React.FC<PriceRatingWidgetProps> = ({ link, internalNumber, layout, theme }) => {
  const scriptId = 'au-widget-script'

  const [isLoaded, setIsLoaded] = useState(false)
  const [element, setElement] = useState<HTMLElement | undefined>(undefined)

  const initAuWidget = useCallback(
    (callback?: boolean) => {
      if (callback) {
        setIsLoaded(true)
      }

      if (isLoaded) {
        setTimeout(() => {
          if (window.auWidgetEmbed) {
            auWidgetEmbed.disableLazyLoad = true
            auWidgetEmbed.init()
          }
        }, 500)
      }
    },
    [isLoaded]
  )

  const appendScript = useCallback(() => {
    if (!element) {
      const sourceName = 'Sug.de'
      const s = document.createElement('script')
      s.type = usercentricsHelper().isAutoUncleAccepted() ? 'text/javascript' : 'text/plain'
      s.async = true
      s.src = '//www.autouncle.de/widgets/embed.js?source_name=' + encodeURIComponent(sourceName)
      s.setAttribute('data-source-name', sourceName)
      s.setAttribute('data-usercentrics', 'AutoUncle Aps')
      s.id = scriptId
      s.onload = () => initAuWidget(true)
      const h = document.getElementsByTagName('head')[0]
      h.insertBefore(s, h.lastChild)
    }
  }, [element, initAuWidget])

  useEffect(() => {
    const element = document.getElementById(scriptId)
    if (element) {
      setElement(element)
      setIsLoaded(true)
      initAuWidget()
    } else {
      appendScript()
    }
  }, [setIsLoaded, appendScript, initAuWidget])

  return (
    <Widget
      className="au-widget-car-rating"
      data-car-url={`https://www.sug.de/de/gebrauchtwagen/fahrzeug-detailansicht/${internalNumber}/${link}`}
      data-layout={layout}
      data-theme={theme}
    ></Widget>
  )
}

const Widget = styled.div``

PriceRatingWidget.defaultProps = {
  layout: 'tall_smaller',
  theme: 'transparent',
}

export { PriceRatingWidget }
