import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { reaction } from 'mobx'

import { useRouterStore } from '../../hooks'
import { DetailNewLink } from '../DetailNewLink'
import { MatchesButton } from '../MatchesButton'
import { LanguageSwitcher } from '../LanguageSwitcher'

const isResultPage = (pathname: string): boolean => {
  return pathname === '/treffer'
}

export interface FooterProps {
  showMeta?: boolean
}

const Footer: React.FC<FooterProps> = ({ showMeta }) => {
  const routerStore = useRouterStore()
  const { pathname } = routerStore.location

  const [show, setShow] = useState(!isResultPage(pathname))

  useEffect(() => {
    const disposePathReaction = reaction(
      () => routerStore.location.pathname,
      (pathname) => {
        setShow(!isResultPage(pathname))
      }
    )

    return () => {
      disposePathReaction()
    }
  })

  const renderMeta = () => {
    return showMeta ? (
      <MetaWrapper>
        <LanguageSwitcher />
        <DetailNewLink />
      </MetaWrapper>
    ) : null
  }

  return show ? (
    <Wrapper>
      <MatchesButton />
      {renderMeta()}
    </Wrapper>
  ) : null
}

Footer.defaultProps = {
  showMeta: true,
}

const Wrapper = styled.footer`
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 25px;
  color: #555556;
  font-family: 'Rubik', sans-serif;
  border-top: 1px solid #4e4e4e;
  background-color: #191919;
  z-index: 50;
`

const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  font-size: 13px;
  letter-spacing: 0.05em;

  .ant-btn {
    color: #bfbfbf;

    &-link {
      padding: 0;
    }
  }
`

export { Footer }
