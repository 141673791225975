import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { $enum } from 'ts-enum-util'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'antd'

import { useFilterStore } from '../../../../hooks'
import {
  GeneralEquipment,
  AcEquipment,
  InteriorEquipment,
  SecurityEquipment,
  GeneralEquipmentUtilityVehicle,
  DriversCabEquipmentUtilityVehicle,
  SecurityEquipmentUtilityVehicle,
  InteriorEquipmentUtilityVehicle,
} from '../../../../types'
import { Checkbox } from '../../..'

const EquipmentFilter: React.FC = () => {
  const filterStore = useFilterStore()

  const generalFilterChange = (value: GeneralEquipment[]) => {
    filterStore.filters.equipment.general = value
  }

  const acFilterChange = (value: AcEquipment[]) => {
    filterStore.filters.equipment.ac = value
  }

  const driversCabFilterChange = (value: DriversCabEquipmentUtilityVehicle[]) => {
    filterStore.filters.equipment.driversCab = value
  }

  const interiorFilterChange = (value: InteriorEquipment[]) => {
    filterStore.filters.equipment.interior = value
  }

  const securityFilterChange = (value: (SecurityEquipment | SecurityEquipmentUtilityVehicle)[]) => {
    filterStore.filters.equipment.security = value
  }

  return useObserver(() => (
    <>
      {/** general */}
      <Wrapper>
        <label>
          <FormattedMessage id="general" />
        </label>
        <Checkbox.Group value={filterStore.filters.equipment.general} onChange={(v: any) => generalFilterChange(v)}>
          <Row type="flex" gutter={[12, 12]}>
            {$enum(GeneralEquipment)
              .getValues()
              .map((filter) => (
                <Col key={filter} span={6}>
                  <Checkbox value={filter}>
                    <FormattedMessage id={`equipment.${GeneralEquipment[filter]}`} />
                  </Checkbox>
                </Col>
              ))}
            {filterStore.filters.utilityVehicle
              ? $enum(GeneralEquipmentUtilityVehicle)
                  .getValues()
                  .map((filter) => (
                    <Col key={filter} span={6}>
                      <Checkbox value={filter}>
                        <FormattedMessage id={`equipment.${GeneralEquipmentUtilityVehicle[filter]}`} />
                      </Checkbox>
                    </Col>
                  ))
              : null}
          </Row>
        </Checkbox.Group>
      </Wrapper>

      {/** ac */}
      <Wrapper>
        <label>
          <FormattedMessage id="ac" />
        </label>
        <Checkbox.Group value={filterStore.filters.equipment.ac} onChange={(v: any) => acFilterChange(v)}>
          <Row type="flex" gutter={[12, 12]}>
            {$enum(AcEquipment)
              .getValues()
              .map((filter) => (
                <Col key={filter} span={6}>
                  <Checkbox value={filter}>
                    <FormattedMessage id={`equipment.${AcEquipment[filter]}`} />
                  </Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      </Wrapper>

      {/** drivers cab */}
      {filterStore.filters.utilityVehicle ? (
        <Wrapper>
          <label>
            <FormattedMessage id="driversCab" />
          </label>

          <Checkbox.Group value={filterStore.filters.equipment.driversCab} onChange={(v: any[]) => driversCabFilterChange(v)}>
            <Row type="flex" gutter={[12, 12]}>
              {$enum(DriversCabEquipmentUtilityVehicle)
                .getValues()
                .map((filter) => (
                  <Col key={filter} span={6}>
                    <Checkbox value={filter}>
                      <FormattedMessage id={`equipment.${DriversCabEquipmentUtilityVehicle[filter]}`} />
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
        </Wrapper>
      ) : null}

      {/** interior */}
      <Wrapper>
        <label>
          <FormattedMessage id="interior" />
        </label>
        <Checkbox.Group value={filterStore.filters.equipment.interior} onChange={(v: any) => interiorFilterChange(v)}>
          <Row type="flex" gutter={[12, 12]}>
            {$enum(InteriorEquipment)
              .getValues()
              .map((filter) => (
                <Col key={filter} span={6}>
                  <Checkbox value={filter}>
                    <FormattedMessage id={`equipment.${InteriorEquipment[filter]}`} />
                  </Checkbox>
                </Col>
              ))}
            {filterStore.filters.utilityVehicle
              ? $enum(InteriorEquipmentUtilityVehicle)
                  .getValues()
                  .map((filter) => (
                    <Col key={filter} span={6}>
                      <Checkbox value={filter}>
                        <FormattedMessage id={`equipment.${InteriorEquipmentUtilityVehicle[filter]}`} />
                      </Checkbox>
                    </Col>
                  ))
              : null}
          </Row>
        </Checkbox.Group>
      </Wrapper>

      {/** security */}
      <Wrapper>
        <label>
          <FormattedMessage id="security" />
        </label>
        <Checkbox.Group value={filterStore.filters.equipment.security} onChange={(v: any) => securityFilterChange(v)}>
          <Row type="flex" gutter={[12, 12]}>
            {$enum(SecurityEquipment)
              .getValues()
              .map((filter) => (
                <Col key={filter} span={6}>
                  <Checkbox value={filter}>
                    <FormattedMessage id={`equipment.${SecurityEquipment[filter]}`} />
                  </Checkbox>
                </Col>
              ))}
            {filterStore.filters.utilityVehicle
              ? $enum(SecurityEquipmentUtilityVehicle)
                  .getValues()
                  .map((filter) => (
                    <Col key={filter} span={6}>
                      <Checkbox value={filter}>
                        <FormattedMessage id={`equipment.${SecurityEquipmentUtilityVehicle[filter]}`} />
                      </Checkbox>
                    </Col>
                  ))
              : null}
          </Row>
        </Checkbox.Group>
      </Wrapper>
    </>
  ))
}

const Wrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;

  .ant-checkbox-group {
    margin-top: 10px;
  }
`

export { EquipmentFilter }
