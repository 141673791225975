import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon } from '../'
import { useFilterStore } from '../../hooks'

interface VatReportableFilterLinkProps {}

const VatReportableFilterLink: React.FC<VatReportableFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const vat = filterStore.filters.isVatReportable

    return (
      <FilterView
        label="vatReportable"
        link="/filter/vat"
        badge={vat !== undefined ? <Icon type="check" /> : 0}
      >
      </FilterView>
    )
  })
}

export { VatReportableFilterLink }
