import { Language } from '../../types'

import { De } from './de'
import { En } from './en'
import { Fr } from './fr'
import { Es } from './es'
import { It } from './it'
import { No } from './no'
import { Pl } from './pl'
import { Pt } from './pt'
import { Ro } from './ro'
import { Ru } from './ru'
import { Sv } from './sv'
import { Tr } from './tr'

const LanguageIcons = new Map<Language, React.FC>([
  [Language.German, De],
  [Language.English, En],
  [Language.French, Fr],
  [Language.Spanish, Es],
  [Language.Italian, It],
  [Language.Norwegian, No],
  [Language.Polish, Pl],
  [Language.Portuguese, Pt],
  [Language.Romanian, Ro],
  [Language.Russian, Ru],
  [Language.Swedish, Sv],
  [Language.Turkish, Tr],
])

export { LanguageIcons }
