import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'
import { Icon } from '../'

interface SeatFilterLinkProps {}

const SeatFilterLink: React.FC<SeatFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const { seats } = filterStore.filters
    return (
      <FilterView label="seats" link="/filter/seats" badge={seats ? <Icon type="check" /> : 0}>
        {seats ? <div><FormattedMessage id={`seatOptions.${seats}`} /></div> : null}
      </FilterView>
    )
  })
}

export { SeatFilterLink }
