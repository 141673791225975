import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { Page, Header, Tabs, TabPane, PriceFilterTab, FinanceFilterTab, Footer, NavigateToFilter } from '../../components'
import { useFilterStore } from '../../hooks'

const Wrapper = styled.div`
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }
`

interface PriceFilterPage {}

const PriceFilterPage: React.FC<PriceFilterPage> = () => {
  const filterStore = useFilterStore()

  const onTabChange = () => {
    filterStore.clearFinancingFilter()
    filterStore.clearPriceFilter()
  }

  return useObserver(() => (
    <Page
      header={<Header title={<FormattedMessage id="priceFilterLabel" />} toolbar={<NavigateToFilter />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <Tabs type="card" onChange={onTabChange}>
          <TabPane tab={<FormattedMessage id="price" />} key="price">
            <PriceFilterTab />
          </TabPane>
          <TabPane tab={<FormattedMessage id="financeRate" />} key="financing">
            <FinanceFilterTab />
          </TabPane>
        </Tabs>
      </Wrapper>
    </Page>
  ))
}

export { PriceFilterPage }
