import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'

import {
  Page,
  Header,
  CheckedButton,
  GeneralEquipmentFilter,
  SecurityEquipmentFilter,
  AcEquipmentFilter,
  InteriorEquipmentFilter,
  Footer,
  NavigateToFilter,
  DriversCabEqFilter,
} from '../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  ${CheckedButton} {
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
`

interface EquipmentFilterProps {}

const EquipmentFilterPage: React.FC<EquipmentFilterProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="equipmentFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <GeneralEquipmentFilter />
        <AcEquipmentFilter />
        {filterStore.filters.utilityVehicle ? <DriversCabEqFilter /> : null}
        <InteriorEquipmentFilter />
        <SecurityEquipmentFilter />
      </Wrapper>
    </Page>
  ))
}

export { EquipmentFilterPage }
