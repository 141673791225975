import styled from 'styled-components'
import { Button as AntButton } from 'antd'

const Button = styled(AntButton)`
  height: auto;

  &:not(.ant-btn-link) {
    padding: 17px;
    font-size: 13px !important;
    letter-spacing: 0.05em;
    border-radius: 0;
    color: #fff;
    border-color: #1daeee;
    background-color: #1daeee;

    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: rgba(0, 0, 0, 0.65);
    }

    &:disabled {
      border-color: #1daeee;
      background-color: #1daeee;
      color: #fff;
    }

    .anticon {
      margin-right: 10px;
    }
  }

  &.ant-btn-link {
    padding: 0;
  }
`

export { Button }
