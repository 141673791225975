import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../hooks'
import { Select } from '../Select'

const mileages = [5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 125000, 150000, 175000, 200000]

const MileageUntilFilter: React.FC<{}> = () => {
  const { formatNumber, formatMessage } = useIntl()
  const filterStore = useFilterStore()

  const options: OptionProps[] = mileages.map((m) => ({ value: m, label: `${formatNumber(m)} km` }))
  options.unshift({ label: formatMessage({ id: 'filterAny' }), value: undefined })

  return useObserver(() => (
    <Select<number>
      style={{ width: '100%' }}
      onChange={(mileage: any) => (filterStore.filters.mileage.lte = mileage)}
      value={filterStore.filters.mileage.lte || undefined}
      placeholder={<FormattedMessage id="mileageUntil" />}
      options={options}
    />
  ))
}

export { MileageUntilFilter }
