export enum UtilityVehicleCategory {
  'Van' = 'Van/Kleinbus',
  'UpTo7500_ThreeSidedTipper' = 'Dreiseitenkipper',
  'BoxTypeDeliveryVan' = 'Kastenwagen',
  'HighAndLongBoxTypeDeliveryVan' = 'Kastenwagen hoch + lang',
  'LongBoxTypeDeliveryVan' = 'Kastenwagen lang',
  'HighBoxTypeDeliveryVan' = 'Kastenwagen hoch',
  'TipperVan' = 'Kipper',
  'BoxVan' = 'Koffer',
  'StakeBodyVan' = 'Pritsche',
  'StandardSZM' = 'Standard-SZM',
  'EstateMinibusUpTo9SeatsVan' = 'Kombi, Kleinbus bis 9 Sitze',
}
