import React, { useRef, useLayoutEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { Row, Col, Icon } from 'antd'
import { Breakpoint } from 'react-socks'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Collapse as AntdCollapse } from 'antd'

import { useCarStore } from '../../hooks'
import {
  CarDetailImages,
  CarDetailShortInfo,
  CarDetailContact,
  CarDetailLegal,
  CarDetailVehicleData,
  CarDetailConsumption,
  CarDetailButtons,
  CarDetailEquipment,
  CarDetailSeller,
} from './'

import { TabPane, Tabs, Collapse, ParkModal, Tile } from '../'
import { CarDetailRateCalculator } from './CarDetailRateCalculator'
import { ParkModalHandles } from '../ParkModal/ParkModal'
import { SimiliarVehicles } from '../SimiliarVehicles'

const AntdPanel = AntdCollapse.Panel

const CarDetail: React.FC<{}> = () => {
  const [eqHeight, setEqHeight] = useState('auto')

  const carStore = useCarStore()

  const parkModalRef = useRef<ParkModalHandles>(null)
  const handleParkClick = (carId: string) => {
    if (parkModalRef.current) {
      parkModalRef.current.setCarId(carId)
    }
  }

  const emissionRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line
  useLayoutEffect(() => {
    if (emissionRef.current) {
      setEqHeight(`${emissionRef.current.clientHeight}px`)
    }
  })

  return useObserver(() => {
    const { car } = carStore

    return (
      <>
        {carStore.loading ? (
          <Loading>
            <Icon type="loading" />
          </Loading>
        ) : (
          <Wrapper>
            <Row type="flex" gutter={[{ xs: 0, lg: 20 }, 20]} style={{ paddingBottom: '10px' }}>
              <Col xs={24} md={24} lg={12}>
                <CarDetailImages />
              </Col>

              <Col md={24} lg={12} xs={24}>
                <Tile className="fullHeight">
                  <CarDetailShortInfo />
                </Tile>
              </Col>

              <Col xs={24} md={12}>
                <CarDetailButtons onPark={(uid: string) => handleParkClick(uid)} />
                <ParkModal ref={parkModalRef} />
              </Col>
              <Col xs={24} md={12}>
                <Tile>
                  <CarDetailContact />
                </Tile>
              </Col>
            </Row>

            {/* only tablets and above */}
            <Breakpoint medium up>
              <Row gutter={20} type="flex" style={{ paddingBottom: '20px' }}>
                <Col xs={12}>
                  <div ref={emissionRef}>
                    <Tile>
                      <Tabs defaultActiveKey="emission">
                        <TabPane tab={<FormattedMessage id="consumptionAndEmission" />} key="emission">
                          <CarDetailConsumption />
                        </TabPane>
                      </Tabs>
                    </Tile>

                    <Tile>
                      <CarDetailSeller />
                    </Tile>
                  </div>
                </Col>

                <Col xs={12}>
                  <div style={{ height: eqHeight }} id="vehicleData">
                    <Tile className="fullHeight">
                      <Tabs
                        defaultActiveKey="vehicleData"
                        activeKey={carStore.activeVehicleTab}
                        onChange={(v) => carStore.setActiveVehicleTab(v)}
                        className="eqTab"
                      >
                        <TabPane tab={<FormattedMessage id="carDetails" />} key="vehicleData">
                          <CarDetailVehicleData />
                        </TabPane>

                        <TabPane tab={<FormattedMessage id="equipmentFilterLabel" />} key="equipment">
                          <CarDetailEquipment showHeadline={false} />
                        </TabPane>
                      </Tabs>
                    </Tile>
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col id="rateCalc">
                  <Tile className="fullHeight">
                    <Tabs defaultActiveKey="rateCalculator">
                      <TabPane tab={<FormattedMessage id="rateCalculator" />} key="rateCalculator">
                        <CarDetailRateCalculator />
                      </TabPane>
                    </Tabs>
                  </Tile>
                </Col>
              </Row>
            </Breakpoint>

            {/* only on mobile devices */}
            <Breakpoint small down>
              <Row gutter={[0, 20]}>
                <Col className="mobileCollapses">
                  <Collapse expandIconPosition="right" accordion={true}>
                    <Panel header={<FormattedMessage id="carDetails" />} key="CarDetailVehicleData">
                      <CarDetailVehicleData />
                    </Panel>

                    <Panel header={<FormattedMessage id="equipmentFilterLabel" />} key="CarDetailEquipment">
                      <CarDetailEquipment />
                    </Panel>

                    <Panel header="Verbrauch und Emissionen" key="Verbrauch und Emissionen">
                      <CarDetailConsumption />
                    </Panel>

                    <Panel header="Ratenrechner" key="Ratenrechner" id="rateCalc">
                      <CarDetailRateCalculator />
                    </Panel>
                  </Collapse>
                </Col>

                <Col>
                  <CarDetailSeller />
                </Col>
              </Row>
            </Breakpoint>

            {car?.similarVehicles.length ? (
              <Row gutter={[0, 20]}>
                <Col className="similiarVehicles">
                  <Tile className="fullHeight">
                    <Tabs defaultActiveKey="similiarVehicles">
                      <TabPane tab={<FormattedMessage id="similiarVehicles" />} key="similiarVehicles">
                        <SimiliarVehicles car={car} />
                      </TabPane>
                    </Tabs>
                  </Tile>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row gutter={[0, 20]}>
              <Col>
                <Tile className="carDetailLegal">
                  <CarDetailLegal />
                </Tile>
              </Col>
            </Row>
          </Wrapper>
        )}
      </>
    )
  })
}

const Wrapper = styled.div`
  padding: 20px;

  .carDetailLegal,
  .similiarVehicles {
    margin-top: 20px;
  }

  .ant-collapse-item {
    background-color: #0e0e0e;

    .ant-collapse-content {
      background-color: transparent;
    }
  }

  .eqTab {
    height: 100%;

    .ant-tabs-content {
      height: 100%;
    }

    .carDetailEqTab {
      height: calc(100% - 67px);
      overflow-y: auto;
    }
  }

  .mobileCollapses .ant-collapse-item .ant-collapse-content-box {
    padding: 16px 0;
  }
`

const Panel = styled(AntdPanel)`
  > .ant-collapse-header {
    font-family: 'CorporateA', serif;
    font-size: 25px;
    line-height: 1;
  }
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  min-height: 100vh;

  & .anticon {
    font-size: 30px;
  }
`

export { CarDetail }
