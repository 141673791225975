import React from 'react'
import styled, { css } from 'styled-components'

import { Co2EfficiencyClass } from '../../types'
import { getCo2ClassColor } from '../../helpers/co2EffClass'
import { FormattedMessage } from 'react-intl'

export declare type BorderPosition = 'left' | 'right'

export interface Co2EffClassProps {
  type?: Co2EfficiencyClass
  width?: string
  borderPosition?: BorderPosition
}

const Co2EffClass: React.FC<Co2EffClassProps> = ({ type, width, borderPosition }) => {
  if (type) {
    return (
      <Wrapper width={width} color={getCo2ClassColor(type)} borderPosition={borderPosition || 'left'}>
        {type}
      </Wrapper>
    )
  }

  return <FormattedMessage id="noValue" />
}

const Wrapper = styled.div<{ color: string; width?: string; borderPosition: BorderPosition }>`
  position: relative;
  padding: 2px 5px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
  }

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
      width: ${props.width};
      &:after {
        top: 0px;
        ${props.borderPosition === 'right'
          ? css`
              right: -25px;
              border-right: 12.5px solid transparent;
              border-top: 12.5px solid transparent;
              border-bottom: 12.5px solid transparent;
              border-left: 12.5px solid ${props.color};
            `
          : css`
              left: -25px;
              border-top: 12.5px solid transparent;
              border-bottom: 12.5px solid transparent;
              border-left: 12.5px solid transparent;
              border-right: 12.5px solid ${props.color};
            `}
      }
    `}
`

Co2EffClass.defaultProps = {
  width: '25px',
}

export { Co2EffClass }
