import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Row, Col } from 'antd'
import moment from 'moment'

import { DetailCar, Fuel } from '../../types'
import { Co2EffClass, Button } from '../'
import { kwToPs } from '../../helpers/power'
import { useRouterStore } from '../../hooks'

import { FirstRegistration, Mileage, Power } from '../../Icon'

interface VehicleProps {
  car: DetailCar
}

const Vehicle: React.FC<VehicleProps> = ({ car }) => {
  const { formatMessage } = useIntl()
  const routerStore = useRouterStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return useObserver(() => (
    <Wrapper className="vehicle">
      {car.images.length ? (
        <img alt={`${car.brand} ${car.model}`} src={`https://www.sug-verwaltung.de/public/images/${car.images[0].imagepath}`} />
      ) : (
        <img alt={`${car.brand} ${car.model}`} src={`https://www.sug-verwaltung.de/public/foto_folgt_in_kuerze.jpeg`} />
      )}

      <Content>
        <Row>
          <Col span={24}>
            <span className="model">{`${car.brand}`}</span>
          </Col>
          <Col className="features" span={24}>
            <span>{car.name}</span>
          </Col>
        </Row>
        <Row>
          {car.energyEfficiencyClass ? (
            <Col span={5}>
              <Co2EffClass type={car.energyEfficiencyClass} borderPosition="right" />
            </Col>
          ) : (
            <></>
          )}

          <Col span={19}>
            <div className="emission">
              {car.emission ? (
                <div>
                  <FormattedMessage id="emission" /> NEFZ
                  <sup>[4]</sup>: {car.emission} g/km{' '}
                </div>
              ) : (
                ''
              )}

              {car.wltp.emission ? (
                <div>
                  <FormattedMessage id="emission" /> WLTP
                  <sup>[5]</sup>: {car.wltp.emission} g/km{' '}
                </div>
              ) : (
                ''
              )}

              {Fuel[car.engine.fuel || 0] !== 'ELECTRIC' ? (
                <>
                  {car.fuelConsumption ? (
                    <div>
                      <FormattedMessage id="fuelConsumption" /> NEFZ
                      <sup>[4]</sup>: <FormattedNumber value={car.fuelConsumption} /> l/100km
                    </div>
                  ) : (
                    ''
                  )}

                  {car.wltp.combined ? (
                    <div>
                      <FormattedMessage id="fuelConsumption" /> WLTP
                      <sup>[5]</sup>: {car.wltp.combined} l/100km
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : null}

              {['ELECTRIC', 'HYBRID_DIESEL', 'HYBRID_PETROL'].includes(Fuel[car.engine.fuel || 0]) ? (
                <>
                  {car.combinedPowerConsumption ? (
                    <div>
                      <FormattedMessage id="powerConsumption" /> NEFZ
                      <sup>[4]</sup>: <FormattedNumber value={car.combinedPowerConsumption} /> kWh/100km
                    </div>
                  ) : (
                    ''
                  )}

                  {car.wltp.combined ? (
                    <div>
                      <FormattedMessage id="powerConsumption" /> WLTP
                      <sup>[5]</sup>: {car.wltp.combinedPowerConsumption} kWh/100km
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="infos" gutter={[0, 10]}>
          <Col span={24} className="gwNumber">
            <FormattedMessage id="gwNumberFilterLabel" />: {` ${car._id}`}
          </Col>
          <Col span={24}>
            <FirstRegistration />
            {moment(car.firstRegistrationDate, 'MM.YYYY').format('MM/YYYY')}
          </Col>
          <Col span={24}>
            <Mileage />
            <FormattedNumber value={car.mileage || 0} /> km
          </Col>
          <Col span={24}>
            <Power />
            {car.power} <FormattedMessage id="kw" /> {`(${kwToPs(car.power || 0)} ${formatMessage({ id: 'ps' })})`}
          </Col>
        </Row>

        <Row gutter={[0, 20]}>
          <Col span={24}>
            <span className="price">
              <FormattedNumber value={car.vatReportable ? car.priceWithoutTax : car.price} /> €
            </span>
          </Col>
          <Col span={24} className="cta-wrapper">
            <Button onClick={() => navigate(`/fahrzeug-detailansicht/${car._id}/${car.link}`)}>
              <FormattedMessage id="view" />
            </Button>
          </Col>
        </Row>
      </Content>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  padding: 0px 20px;

  .ant-row {
    margin: 5px 0px;
    padding: 5px 0px;

    &:not(:last-child) {
      border-bottom: 1px solid #3f3f3f;
    }

    .model {
      font-size: 18px;
      margin-bottom: 2px;
    }

    .features {
      min-height: 42px;
      word-break: break-all;
    }

    .emission {
      font-size: 9px;
    }

    .price {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .infos {
    svg {
      height: 20px;
      width: auto;
      margin-right: 10px;
    }

    font-size: 12px;
  }

  .cta-wrapper {
    display: flex;
    align-items: center;

    .ant-btn:not(.ant-btn-default) {
      padding: 10px 20px;
      flex-grow: 1;
      text-transform: uppercase;
    }
  }
`

export { Vehicle }
