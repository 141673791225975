import React, { useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import {
  CarFilterPage,
  PriceFilterPage,
  PowerFilterPage,
  FuelFilterPage,
  RegistrationFilterPage,
  GearboxFilterPage,
  MileageFilterPage,
  CubicCapacityFilterPage,
  LocationFilterPage,
  EquipmentFilterPage,
  QualitySealFilterPage,
  ColorFilterPage,
  GWNumberFilterPage,
  VatFilterPage,
  TotalWeightFilter,
  SeatFilter,
  Co2EuroNormFilter,
  CarEnvBadgeFilter,
  CarInventoryFilterPage,
} from './'

import { useFilterStore } from '../../hooks'

interface FilterProps {}

const FilterPage: React.FC<FilterProps> = () => {
  const { path } = useRouteMatch()
  const filterStore = useFilterStore()

  useEffect(() => {
    if (filterStore.filters.legacyCarFilter.length) {
      filterStore.unsetLegacyCarFilter()
    }
  })

  return (
    <>
      <Switch>
        <Route path={`${path}/car`}>
          <CarFilterPage />
        </Route>
        <Route path={`${path}/price`}>
          <PriceFilterPage />
        </Route>
        <Route path={`${path}/power`}>
          <PowerFilterPage />
        </Route>
        <Route path={`${path}/fuel`}>
          <FuelFilterPage />
        </Route>
        <Route path={`${path}/registration`}>
          <RegistrationFilterPage />
        </Route>
        <Route path={`${path}/gearbox`}>
          <GearboxFilterPage />
        </Route>
        <Route path={`${path}/mileage`}>
          <MileageFilterPage />
        </Route>
        <Route path={`${path}/ccm`}>
          <CubicCapacityFilterPage />
        </Route>
        <Route path={`${path}/location`}>
          <LocationFilterPage />
        </Route>
        <Route path={`${path}/equipment`}>
          <EquipmentFilterPage />
        </Route>
        <Route path={`${path}/qualityseal`}>
          <QualitySealFilterPage />
        </Route>
        <Route path={`${path}/color`}>
          <ColorFilterPage />
        </Route>
        <Route path={`${path}/gw`}>
          <GWNumberFilterPage />
        </Route>
        <Route path={`${path}/vat`}>
          <VatFilterPage />
        </Route>
        <Route path={`${path}/weight`}>
          <TotalWeightFilter />
        </Route>
        <Route path={`${path}/seats`}>
          <SeatFilter />
        </Route>
        <Route path={`${path}/euronorm`}>
          <Co2EuroNormFilter />
        </Route>
        <Route path={`${path}/carenvbadge`}>
          <CarEnvBadgeFilter />
        </Route>
        <Route path={`${path}/inventory`}>
          <CarInventoryFilterPage />
        </Route>
      </Switch>
    </>
  )
}

export { FilterPage }
