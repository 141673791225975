import React from 'react'
import styled from 'styled-components'

import { Button, Icon } from '../'

interface SelectProps {
  label: string | React.ReactNode
  active: boolean
  onClick: () => void
}

const CheckedButtonComponent: React.FC<SelectProps> = ({ label, active, onClick, ...rest }) => {
  return (
    <>
      <StyledButton block type="primary" onClick={onClick} {...rest}>
        {label}
        <Icon type="check" className={active ? 'active' : 'hidden'}></Icon>
      </StyledButton>
    </>
  )
}

const StyledButton = styled(Button as any)`
  display: flex;
  justify-content: space-between;
  padding: 17.5px 25px !important;
  background-color: #1d1e1e !important;
  border-color: #1d1e1e !important;
  border-radius: 4px;
  font-size: 13px !important;
  letter-spacing: 0.05em;

  :hover,
  :focus,
  :active {
    background-color: #1d1e1e !important;
    border-color: #1d1e1e !important;
    color: #fff;
  }

  .anticon {
    margin: 0 !important;
    font-size: 20px;
    color: #1daeee;

    &.hidden {
      opacity: 0;
    }

    &.active {
      opacity: 1;
    }

    transition: all 0.24s;
  }
`

const CheckedButton = styled(CheckedButtonComponent)``

export { CheckedButton }
