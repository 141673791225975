import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { $enum } from 'ts-enum-util'

import { useFilterStore } from '../hooks'
import { CheckedButton } from '../components'

import { DriversCabEquipmentUtilityVehicle } from '../types'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  color: #5d5d5d;
`

interface DriversCabEqFilterProps {}

const DriversCabEqFilter: React.FC<DriversCabEqFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: DriversCabEquipmentUtilityVehicle) => {
    filterStore.addOrRemoveDriversCabEq(value)
  }

  return useObserver(() => (
    <Wrapper>
      <FormattedMessage id="driversCab" />
      {$enum(DriversCabEquipmentUtilityVehicle)
        .getValues()
        .map((filter) => (
          <CheckedButton
            onClick={() => onFilterChange(filter)}
            label={<FormattedMessage id={`equipment.${DriversCabEquipmentUtilityVehicle[filter]}`} />}
            active={filterStore.filters.equipment.driversCab.includes(filter)}
            key={filter}
          />
        ))}
    </Wrapper>
  ))
}

export { DriversCabEqFilter }
