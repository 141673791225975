import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #000;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto; 
    font-family: 'Arial,Helvetica',sans-serif;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }

  #root {
    font-family: 'Rubik', sans-serif;
    color: #fff;

    &.disableBanner {
      .searchBanner {
        display: none;
      }
    }
  }

  .ant-popover {
    &-inner-content {
      p {
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .ant-cascader {
    &-picker {
      background-color: #1e1e1e;
      border-radius: 1px;

      &-label:hover + .ant-cascader-input {
        border-color: #1e1e1e;
      }

      & .ant-input {
        border-color: #1e1e1e;
        color: #fff;
      }
    }

    &-menu {
      height: auto;
      color: #fff;
      background-color: #1e1e1e;
    }
  }

  .ant-select-dropdown {
    background-color: #1e1e1e;
    border-radius: 0px;
    color: #fff;

    &-menu-item {
      color: #fff;
      padding: 14px 12px;

      &:not(:last-child) {
        border-bottom: 1px solid #2c2c2c;
      }

      &-group-title {
        color: #5d5e5f;
      }

      &-selected,
      &-active:not(.ant-select-dropdown-menu-item-disabled),
      &:hover:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: #2c2c2c;
      }
    }
  }

  .ant-select-selection {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .ant-popover {
    &-inner {
      background-color: #0e0e0e;
      border: 2px solid #333333;
      
      &-content {
        color: #fff;
      }
    }
  }

  .ant-input-number-input {
    color: #fff;
  }
`

export { GlobalStyles }
