import styled from 'styled-components'
import { Tabs as AntTabs } from 'antd'

const Tabs = styled(AntTabs)`
  &.ant-tabs {
    .ant-tabs-bar {
      border-bottom: 1px solid #383838;

      & .ant-tabs-nav {
        &-container-scrolling {
          padding-left: 0px;
          padding-right: 0px;
        }

        color: #9a9b9c;
        font-size: 25px;
        font-family: 'CorporateA', serif;

        & .ant-tabs-tab-active {
          color: #fff;
        }

        > div {
          div {
            &:only-child {
              cursor: default;
            }
          }
        }
      }

      & .ant-tabs-ink-bar {
        background-color: transparent;
      }

      .ant-tabs-tab {
        padding: 0 20px 15px;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-tab-prev {
      display: none;
    }
  }

  &.ant-tabs-card .ant-tabs-card-bar {
    margin: 0;
    border-bottom: none;

    .ant-tabs-nav {
      width: 100%;

      & > div {
        display: flex;
      }
    }

    .ant-tabs-nav-container {
      height: 50px;
    }

    .ant-tabs-tab {
      width: 100%;
      height: 50px;
      padding-right: 25px;
      line-height: 48px;
      border-radius: 0;
      border: 5px solid #191919;
      border-bottom: none;
      background-color: #1e1e1e;
      color: #5d5e5f;
      font-size: 16px;
      text-align: center;

      &-active,
      &:hover {
        color: #fff;
        border-color: #1e1e1e;
        background-color: #191919;
      }
    }
  }
`

export { Tabs }
