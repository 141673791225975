import { useState, useEffect } from 'react'

import { useCurrentBreakpointName } from 'react-socks'

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)

  const breakPoint = useCurrentBreakpointName()

  useEffect(() => {
    if (breakPoint === 'small' || breakPoint === 'xsmall') {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [breakPoint])

  return isMobile
}
