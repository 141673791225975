import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { useFilterStore } from '../../../../hooks'
import { Select } from '../../../Select'

const VatReportableFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const options = [
    { value: undefined, label: 'filterAny' },
    { value: 'true', label: 'yes' },
    { value: 'false', label: 'no' },
  ].map((o) => ({
    ...o,
    ...{
      label: formatMessage({ id: o.label }),
    },
  }))

  const handleChange = (vat: string | undefined) => {
    filterStore.setVatReportableFilter(vat === undefined ? undefined : vat === 'true' ? true : false)
  }

  return useObserver(() => {
    const filter = filterStore.filters.isVatReportable

    return (
      <Wrapper>
        <label>
          <FormattedMessage id="vatReportable" /> :
        </label>
        <Select<string>
          className="varReportableFilter"
          style={{ width: '100%' }}
          onChange={(value: any) => handleChange(value)}
          value={filter === undefined ? undefined : filter.toString()}
          placeholder={<FormattedMessage id="vatReportable" />}
          options={options}
        ></Select>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  color: #fff;
  label {
    line-height: 39.9999px;
  }
`

export { VatReportableFilter }
