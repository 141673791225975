import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { Page, Header, RangeFilterForm, CheckedButton, Footer, NavigateToFilter } from '../../components'
import { useFilterStore } from '../../hooks'
import { RangeFilter } from '../../types'
import { doesFilterMatch } from '../../helpers/rangeFilter'

const Wrapper = styled.div`
  padding: 25px;
  color: #5d5d5d;

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

const filters = [
  {
    label: <FormattedMessage id="filterLabel.mileage" values={{ value: <FormattedNumber value={10000} /> }} />,
    value: {
      gte: null,
      lte: 10000,
    },
  },
  {
    label: <FormattedMessage id="filterLabel.mileage" values={{ value: <FormattedNumber value={25000} /> }} />,
    value: {
      gte: null,
      lte: 25000,
    },
  },
  {
    label: <FormattedMessage id="filterLabel.mileage" values={{ value: <FormattedNumber value={50000} /> }} />,
    value: {
      gte: null,
      lte: 50000,
    },
  },
  {
    label: <FormattedMessage id="filterLabel.mileage" values={{ value: <FormattedNumber value={100000} /> }} />,
    value: {
      gte: null,
      lte: 100000,
    },
  },
]

interface MileageFilterPageProps {}

const MileageFilterPage: React.FC<MileageFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: RangeFilter) => {
    filterStore.setMileageFilter(value)
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="mileageFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <RangeFilterForm values={filterStore.filters.mileage} onChange={onFilterChange} unit="km" />
        <FormattedMessage id="popular" />
        {filters.map((filter, i) => (
          <CheckedButton
            onClick={() => onFilterChange(filter.value)}
            label={filter.label}
            active={doesFilterMatch(filterStore.filters.mileage, filter.value)}
            key={i}
          />
        ))}
      </Wrapper>
    </Page>
  ))
}

export { MileageFilterPage }
