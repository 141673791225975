import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { Tree, TreeNode } from '../'
import { useFilterStore } from '../../hooks'
import { Brand } from '../../types'

interface SelectProps {
  onBrandSelect: (brand: string) => void
}

const BrandFilter: React.FC<SelectProps> = ({ onBrandSelect, ...rest }) => {
  const filterStore = useFilterStore()

  const onSelect = (selected: string[]) => {
    onBrandSelect(selected[0])
  }

  const Divider = styled.div`
    padding: 5px;
    padding-left: 10px;
    font-size: 16px;
    background-color: #1e1e1e;
  `

  const TreeWrapper = styled.div``

  return useObserver(() => (
    <>
      {Object.keys(filterStore.getGroupedBrands)
        .sort()
        .map(key => (
          <TreeWrapper key={key}>
            <Divider>
              <span>{key}</span>
            </Divider>
            <Tree key={key} showIcon={false} onSelect={onSelect}>
              {filterStore.getGroupedBrands[key].map((brand: Brand) => (
                <TreeNode title={brand._id} key={brand._id}></TreeNode>
              ))}
            </Tree>
          </TreeWrapper>
        ))}
    </>
  ))
}

export { BrandFilter }
