import styled from 'styled-components'
import { Pagination as AntPagination } from 'antd'

const Pagination = styled(AntPagination)`
  font-size: 13px;
  text-align: center;

  .ant-pagination {
    &-disabled {
      a {
        color: rgba(255, 255, 255, 0.3) !important;
      }
    }

    &-prev,
    &-next {
      min-width: 33px;
      height: 33px;
      line-height: 31px;

      a {
        color: #fff;
        border-radius: 0;
        border-color: #252525;
        background-color: #252525;
        font-size: inherit;
      }
    }

    &-prev {
      margin-right: 10px;
    }

    &-prev:focus,
    &-prev:hover,
    &-next:hover,
    &-next:focus {
      .ant-pagination-item-link {
        color: #fff;
        border-color: #252525;
      }
    }

    &-item {
      width: 33px;
      height: 33px;
      margin-right: 10px;
      line-height: 31px;
      border-width: 2px;
      border-radius: 0;
      border-color: #252525;
      background-color: #252525;

      &-active,
      &:hover {
        border-color: #1daeee !important;
        background-color: #0b3647 !important;
      }

      a {
        color: #fff;
        font-family: 'Rubik', sans-serif;
        line-height: 31px;
      }
    }

    &-jump-next,
    &-jump-prev {
      display: none;
    }
  }
`

export { Pagination }
