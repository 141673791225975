import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import { Page, Header, Footer, DetailFilter, VehicleTypeTab, ResetFilterButton } from '../components'
import { FooterDesktop } from '../components/FooterDesktop'
import { MatchesButton } from '../components/MatchesButton'
import { useIsMobile, useScroll, useFilterStore } from '../hooks'

interface DetailSearchPageProps {}

const DetailSearchPage: React.FC<DetailSearchPageProps> = () => {
  const isMobile = useIsMobile()
  const scroll = useScroll()
  const filterStore = useFilterStore()

  useEffect(() => {
    return () => {
      scroll.toTop()
    }
  })

  useEffect(() => {
    if (filterStore.filters.legacyCarFilter.length) {
      filterStore.unsetLegacyCarFilter()
    }
  })

  return useObserver(() => {
    return (
      <Wrapper>
        <Page
          header={
            <Header
              specialFont
              title={<FormattedMessage id="detailSearch" />}
              desktopTitle={<FormattedMessage id="detailSearch" />}
              toolbar={isMobile ? <ResetFilterButton /> : <MatchesButton />}
            />
          }
          footer={isMobile ? <Footer /> : <FooterDesktop />}
        >
          <VehicleTypeTab>
            <DetailFilter />
          </VehicleTypeTab>
        </Page>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  background-color: #252525;

  .footerDesktop {
    padding: 25px;
  }

  ${breakpoint('desktop')`
    .matches-btn {
      background-color: #ffffff;
      border-color: #ffffff;
      color: #222222;

      &:hover {
        color: #fff;
        background-color: #1daeee;
        border-color: #1daeee;
      }
    }
  `}

  ${breakpoint('tablet')`
    .matches-btn {
      background-color: #ffffff;
      border-color: #ffffff;
      color: #222222;

      &:hover {
        color: #fff;
        background-color: #1daeee;
        border-color: #1daeee;
      }
    }
  `}
`

export { DetailSearchPage }
