import React from 'react'
import styled from 'styled-components'
import { Icon } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { useObserver } from 'mobx-react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { useRouterStore, useCarListStore } from '../../hooks'
import { Button } from '../../components/Button'

const MatchesButton: React.FC<ButtonProps> = () => {
  const routerStore = useRouterStore()
  const carListStore = useCarListStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return useObserver(() => (
    <StyledButton
      block
      className="matches-btn"
      type="primary"
      loading={carListStore.loading}
      disabled={!carListStore.total}
      onClick={() => navigate('/treffer')}
    >
      <Icon type="search" />
      <FormattedMessage id="totalMatches" values={{ count: <FormattedNumber value={carListStore.total} /> }} />
    </StyledButton>
  ))
}

const StyledButton = styled(Button as any)`
  padding: 10.5px !important;
  text-align: center;
  min-width: 145px;
`

export { MatchesButton }
