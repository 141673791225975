import { Checkbox as AntCheckbox } from 'antd'
import { CheckboxProps as AntdCheckboxProps } from 'antd/lib/checkbox'
import styled, { css } from 'styled-components'

export interface CheckboxProps extends AntdCheckboxProps {
  color?: any
}

const Checkbox = styled(AntCheckbox)<CheckboxProps>`
  color: #fff;

  .ant-checkbox {
    &-inner {
      background-color: #1e1e1e;
      ${(props) =>
        props.color &&
        css`
          background-color: ${props.color};

          &::after {
            border-color: #212121;
          }
        `}
      border-color: #3e3e3f;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #1daeee;
  }
`

export { Checkbox }
