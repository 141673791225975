import React, { useState } from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { Button, Icon } from '../../components'
import { ResetFilterModal } from '../../components/ResetFilterModal'

const Wrapper = styled.div``

interface ResetFilterButtonProps {}

const ResetFilterButton: React.FC<ResetFilterButtonProps> = () => {
  const [isResetModalVisible, setResetModalVisible] = useState(false)

  return useObserver(() => (
    <Wrapper>
      <Button type="link" onClick={() => setResetModalVisible(true)}>
        <Icon type="trash" />
      </Button>
      <ResetFilterModal visible={isResetModalVisible} onModalClose={() => setResetModalVisible(false)} />
    </Wrapper>
  ))
}

export { ResetFilterButton }
