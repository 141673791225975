import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { Button, Modal } from '../../components'

interface ParkLoginModalProps {
  visible: boolean
  onHide: () => void
  onPark: () => void
}

const ParkLoginModal: React.FC<ParkLoginModalProps> = ({ visible, onHide, onPark }) => {
  const hide = () => {
    onHide()
  }

  return useObserver(() => (
    <Modal
      visible={visible}
      footer={[
        <Button key="register" href="https://www.sug.de/de/mein-sg/registrieren">
          <FormattedMessage id="register" />
        </Button>,

        <Button key="login" href="https://www.sug.de/de/mein-sg/login">
          <FormattedMessage id="login" />
        </Button>,

        <Button key="submit" onClick={() => onPark()}>
          <FormattedMessage id="park_without_login" />
        </Button>,
      ]}
      onCancel={() => hide()}
    >
      <ModalContent>
        <h2>
          <FormattedMessage id="parkModalHeader" />
        </h2>
      </ModalContent>
    </Modal>
  ))
}

const ModalContent = styled.div``

export { ParkLoginModal }
