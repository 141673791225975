import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon, RangeFilterLabel } from '../'
import { useFilterStore } from '../../hooks'

interface CcmFilterLinkProps {}

const CcmFilterLink: React.FC<CcmFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const ccm = filterStore.filters.engine.cubicCapacity
    return (
      <FilterView label="cubicCapacityFilterLabel" link="/filter/ccm" badge={ccm.lte || ccm.gte ? <Icon type="check" /> : 0}>
        <RangeFilterLabel filter={ccm} unit={'cm³'} />
      </FilterView>
    )
  })
}

export { CcmFilterLink }
