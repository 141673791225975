import React from 'react'
import styled from 'styled-components'
import { Checkbox } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useObserver } from 'mobx-react'
import { animateScroll } from 'react-scroll'

import { useFilterStore } from '../../hooks'
import { CarCategory, UtilityVehicleCategory } from '../../types'
import { Icon } from '../'

interface VehicleTypeSwitcherProps {}

const VehicleTypeSwitcher: React.FC<VehicleTypeSwitcherProps> = () => {
  const filterStore = useFilterStore()

  function handleChange(value: string[]): void {
    filterStore.setVehicleCategories(value)
  }

  const scroll = (x: number) => {
    animateScroll.scrollMore(x, {
      containerId: 'scrollContainer',
      smooth: true,
      horizontal: true,
      duration: 200,
    })
  }

  return useObserver(() => (
    <Wrapper className="vehicleTypeSwitcher">
      <label>
        <FormattedMessage id="vehicleType" />
      </label>

      <ScrollWrapper>
        <ScrollHelper onClick={() => scroll(-200)}>
          <Icon type="left" />
        </ScrollHelper>

        <Scroll id="scrollContainer">
          <Checkbox.Group value={filterStore.filters.categories} onChange={(value: any) => handleChange(value)}>
            {Object.entries(filterStore.filters.utilityVehicle ? UtilityVehicleCategory : CarCategory).map(([k, v]) => (
              <Checkbox key={k} value={v}>
                <img src={`/react/gwsuche/vehicletypes/${k}.svg`} alt="" />
                <div className={filterStore.filters.utilityVehicle ? 'utility' : 'cars'}>
                  <FormattedMessage id={'vehicleTypes.' + k} />
                </div>
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Scroll>
        <ScrollHelper onClick={() => scroll(200)}>
          <Icon type="right" />
        </ScrollHelper>
      </ScrollWrapper>
    </Wrapper>
  ))
}

const Scroll = styled.div`
  overflow-x: auto;
`

const ScrollHelper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 40px;
  font-size: 18px;
  z-index: 1;
  height: 40px;
  border-radius: 20px;
  transform: translateY(-50%);
  background-color: #252525;
  top: 50%;

  &:hover {
    cursor: pointer;
  }
`

const ScrollWrapper = styled.div`
  position: relative;

  ${ScrollHelper} {
    &:first-child {
      position: absolute;
      left: 10px;
    }

    &:last-child {
      position: absolute;
      right: 10px;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-checked::after {
    border: none;
  }

  & > label {
    margin-bottom: 16px;
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  .ant-checkbox-group {
    position: relative;
    display: flex;
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 80px;
    color: #5f6062;
    border: 3px solid #191919;
    background-color: #1e1e1e;
    box-sizing: content-box;
    padding: 15px;
    text-align: center;
    position: relative;

    div {
      font-size: 11px;
      transition: all 300ms;

      position: absolute;
      bottom: 5px;
      left: 50%;

      transform: translate(-50%);
    }

    & .ant-checkbox-inner {
      display: none;
    }

    &:before {
      display: none;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    img {
      transition: all 300ms;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

    &-checked,
    &:hover {
      color: #fff;
      border-color: #212121 !important;
      background-color: #212121;

      &:not(.ant-radio-button-wrapper-disabled) {
        box-shadow: none;
      }

      &:not(.ant-radio-button-wrapper-disabled):active,
      &:not(.ant-radio-button-wrapper-disabled):hover {
        color: #fff;
        box-shadow: none;
      }

      &:not(.ant-radio-button-wrapper-disabled):hover,
      &:not(.ant-radio-button-wrapper-disabled):focus-within {
        outline: none;
      }

      img {
        opacity: 1 !important;
      }
    }

    img {
      opacity: 0.3;
      fill: #fff;

      svg {
        fill: #fff !important;
        path {
          fill: inherit !important;
        }
      }
    }
  }
`

export { VehicleTypeSwitcher }
