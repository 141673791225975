import React, { useState } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { useIntl, FormattedMessage } from 'react-intl'

import { useAppStore, useFilterStore } from '../../hooks'
import { Button, Modal, Input } from '..'

const SaveFilterModal: React.FC<{}> = () => {
  const [name, setName] = useState('')

  const appStore = useAppStore()
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const close = () => {
    appStore.setSaveFilterModalVisibility(false)
  }

  const saveFilter = () => {
    const filter = filterStore.filters

    fetch('https://www.sug.de/index.php?id=1282&type=999', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        name,
        filter,
      }),
    })
      .then((res) => close())
      .finally(() => close())
  }

  return useObserver(() =>
    appStore.isLoggedIn ? (
      <Modal
        visible={appStore.isSaveFilterModalVisible}
        footer={[
          <Wrapper key="modalContent">
            <Input placeholder={formatMessage({ id: 'placeholders.name' })} onChange={(e) => setName(e.target.value)} />
            <Button key="save" onClick={() => saveFilter()} disabled={!name}>
              <FormattedMessage id="save" />
            </Button>
          </Wrapper>,
        ]}
        onCancel={() => close()}
      >
        <h2>
          <FormattedMessage id="saveFilter" />
        </h2>
      </Modal>
    ) : (
      <Modal
        visible={appStore.isSaveFilterModalVisible}
        footer={[
          <Button key="register" href="https://www.sug.de/de/mein-sg/registrieren">
            <FormattedMessage id="register" />
          </Button>,

          <Button key="login" href="https://www.sug.de/de/mein-sg/login">
            <FormattedMessage id="login" />
          </Button>,
        ]}
        onCancel={() => close()}
      >
        <h2>
          <FormattedMessage id="parkModalHeader" />
        </h2>
      </Modal>
    )
  )
}

const Wrapper = styled.div``

export { SaveFilterModal }
