import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { CarFilter } from '../../types'
import { CarForm } from './components/CarForm'
import { Icon } from '../'

const CarFilterDesktop: React.FC = () => {
  const filterStore = useFilterStore()

  const carChanged = (uuid: string | undefined, car: CarFilter) => {
    filterStore.updateCar({ ...car, ...{ uuid: uuid } })
  }

  const addCar = () => {
    filterStore.addCar({ brand: undefined })
  }

  const removeCar = (car: CarFilter) => {
    filterStore.removeCar(car)
  }

  const getCarValues = (car: CarFilter) => {
    const { uuid, ...c } = car
    return c
  }

  return useObserver(() => {
    const cars = filterStore.filters.cars

    return (
      <Wrapper className="carFilterDesktop">
        <Row type="flex" gutter={[0, 10]}>
          {cars.length
            ? cars.map((car: CarFilter) => (
                <Col className="car" key={car.uuid} span={24}>
                  <Col span={20}>
                    <CarForm value={getCarValues(car)} onChange={(value: any) => carChanged(car.uuid, value)} />
                  </Col>
                  <Col span={4}>
                    <span onClick={() => removeCar(car)}>
                      <Icon type="trash" />
                    </span>
                  </Col>
                </Col>
              ))
            : null}
        </Row>
        <Row gutter={[0, 20]}>
          <Col>
            <span className="addCar" onClick={() => addCar()}>
              <Icon type="plus" />
              <span>
                <FormattedMessage id="addCar" />
              </span>
            </span>
          </Col>
        </Row>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  .addCar {
    transition: all 0.24s;
    color: #5d5e5f;
    font-size: 14px;

    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }

  .car {
    .carForm {
      flex-grow: 1;
    }
    display: flex;

    .custom-icon {
      padding-top: 15px;
      svg,
      g,
      path {
        height: 22px;
        fill: #fff;
      }
    }
  }
`

export { CarFilterDesktop }
