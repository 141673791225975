import styled from 'styled-components'
import { Tree as AntTree } from 'antd'
const AntTreeNode = AntTree.TreeNode

const TreeNode = styled(AntTreeNode)`
  padding: 15px 0 !important;
  font-size: 16px;

  ul {
    margin-top: 15px !important;
  }

  .ant-tree-switcher {
    color: #fff;
  }
`

const Tree = styled(AntTree)`
  .ant-tree {
    font-size: 16px;

    &-title {
      color: #fff;
    }
  }
`

export { Tree, TreeNode }
