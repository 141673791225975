import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useObserver } from 'mobx-react'
import { Breakpoint } from 'react-socks'
import { Typography, Col, Row } from 'antd'
import styled from 'styled-components'

import { Page, Header, Footer, VehicleTypeTab, SimpleFilter, ResetFilterButton } from '../components'
import { FooterDesktop } from '../components/FooterDesktop'
import { VehicleTypeFilter } from '../components/Filters/VehicleTypeFilter'
import { PriceUntilFilter } from '../components/Filters/PriceUntilFilter'
import { RegistrationDateFromFilter } from '../components/Filters/RegistrationDateFromFilter'
import { MileageUntilFilter } from '../components/Filters/MileageUntilFilter'
import { FuelFilter } from '../components/Filters/FuelFilter'
import { LocationFilter } from '../components/Filters/LocationFilter'
import { InternalNumberFilter } from '../components/Filters/InternalNumberFilter'
import { BrandFilter } from '../components/Filters/BrandFilter'
import { ModelFilter } from '../components/Filters/ModelFilter'
import { Banner } from '../Images/Banner'
import { useScroll, useFilterStore } from '../hooks'

interface SearchPageProps {}

const SearchPage: React.FC<SearchPageProps> = () => {
  const scroll = useScroll()
  const filterStore = useFilterStore()

  useEffect(() => {
    if (filterStore.filters.legacyCarFilter.length) {
      filterStore.unsetLegacyCarFilter()
    }

    return () => {
      scroll.toTop()
    }
  })

  return useObserver(() => (
    <>
      {/* only tablets and above */}
      <Breakpoint medium up>
        <Banner />

        <Wrapper>
          <Row className={'headline'} type="flex" justify="space-between" align="middle">
            <Col xs={24}>
              <Title>
                <FormattedMessage id="title" />
              </Title>
            </Col>
          </Row>

          <Row gutter={[25, 25]} type="flex">
            <Col span={6}>
              <VehicleTypeFilter type="dropdown" />
            </Col>

            <Col span={6}>
              <BrandFilter />
            </Col>

            <Col span={6}>
              <ModelFilter />
            </Col>

            <Col span={6}>
              <PriceUntilFilter />
            </Col>

            <Col span={6}>
              <RegistrationDateFromFilter />
            </Col>

            <Col span={6}>
              <MileageUntilFilter />
            </Col>

            <Col span={6}>
              <FuelFilter />
            </Col>

            <Col span={6}>
              <LocationFilter />
            </Col>

            <Col span={6}>
              <InternalNumberFilter />
            </Col>
          </Row>

          <Row gutter={[40, 40]}>
            <Col xs={24}>
              <FooterDesktop />
            </Col>
          </Row>
        </Wrapper>
      </Breakpoint>

      {/* only on mobile devices */}
      <Breakpoint small down>
        <Page
          header={<Header specialFont title={<FormattedMessage id="carSearch" />} toolbar={<ResetFilterButton />} />}
          footer={<Footer />}
        >
          <VehicleTypeTab>
            <SimpleFilter />
          </VehicleTypeTab>
        </Page>
      </Breakpoint>
    </>
  ))
}

const Wrapper = styled.div`
  background-color: #252525;
  padding: 20px;

  .headline {
    border-bottom: 1px solid rgb(53, 53, 53);
    height: 75px;
    margin: -20px 0;
  }

  > .ant-row-flex {
    margin-bottom: 20px !important;
  }
`

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  &.ant-typography {
    font-family: 'CorporateA', serif;
    font-size: 30px;
    line-height: 1;
    color: #fff;
    margin: 0;
    font-weight: 400;
  }
`

export { SearchPage }
