import React from 'react'

const Pt: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.42 43.52">
      <path
        d="M65.27,43.51H1.15A1.15,1.15,0,0,1,0,42.37V1.15A1.14,1.14,0,0,1,1.15,0H65.27a1.14,1.14,0,0,1,1.15,1.15h0V42.37A1.15,1.15,0,0,1,65.27,43.51Z"
        fill="#ff4b55"
      />
      <path d="M24,0H1.15A1.14,1.14,0,0,0,0,1.15V42.37a1.14,1.14,0,0,0,1.15,1.15H24Z" fill="#73af00" />
      <circle cx="24.06" cy="21.76" r="10.3" fill="#ffe15a" />
      <path
        d="M27.49,18.32V22.9a3.44,3.44,0,0,1-6.87,0V18.32h6.87M28.64,16H19.48a1.14,1.14,0,0,0-1.15,1.15V22.9a5.73,5.73,0,0,0,11.45,0V17.18A1.15,1.15,0,0,0,28.66,16Z"
        fill="#ff4b55"
      />
      <path d="M27.49,18.32V22.9a3.44,3.44,0,0,1-6.87,0V18.32h6.87" fill="#f5f5f5" />
      <circle cx="19.47" cy="17.18" r="0.57" fill="#ffe15a" />
      <circle cx="28.63" cy="17.18" r="0.57" fill="#ffe15a" />
      <circle cx="19.47" cy="21.76" r="0.57" fill="#ffe15a" />
      <circle cx="28.63" cy="21.76" r="0.57" fill="#ffe15a" />
      <circle cx="24.05" cy="17.18" r="0.57" fill="#ffe15a" />
      <circle cx="27.49" cy="25.98" r="0.57" fill="#ffe15a" />
      <circle cx="20.68" cy="25.98" r="0.57" fill="#ffe15a" />
      <path d="M24.8,21.47v1a.74.74,0,1,1-1.48,0h0v-1H24.8" fill="#41479b" />
      <path d="M24.8,19.13v1a.74.74,0,1,1-1.48,0h0v-1H24.8" fill="#41479b" />
      <path d="M24.8,23.83v1a.74.74,0,1,1-1.48,0h0v-1H24.8" fill="#41479b" />
      <path d="M26.79,21.47v1a.74.74,0,0,1-1.48,0h0v-1h1.48" fill="#41479b" />
      <path d="M22.8,21.47v1a.74.74,0,1,1-1.48,0v-1H22.8" fill="#41479b" />
    </svg>
  )
}

export { Pt }
