import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { RangeFilter } from '../../types'

interface RangeFilterLabelProps {
  filter: RangeFilter
  unit: string
  formatNumber?: boolean
}

const RangeFilterLabel: React.FC<RangeFilterLabelProps> = ({ filter, unit, formatNumber }) => {
  const formatRangeFilter = (range: any, unit: string): React.ReactElement => {
    let msgId = 'filterLabel.range'

    const values = {
      ...(range.gte && { from: formatNumber ? <FormattedNumber value={range.gte} /> : range.gte }),
      ...(range.lte && { to: formatNumber ? <FormattedNumber value={range.lte} /> : range.lte }),
      symbol: unit,
    }

    if (!values.to) {
      msgId = 'filterLabel.from'
    }

    if (!values.from) {
      msgId = 'filterLabel.to'
    }

    return <FormattedMessage id={msgId} values={values} />
  }

  return filter.lte || filter.gte ? <div>{formatRangeFilter(filter, unit)}</div> : <></>
}

RangeFilterLabel.defaultProps = {
  formatNumber: true,
}

export { RangeFilterLabel }
