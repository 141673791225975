import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../hooks'
import { Select } from '../'

const BrandFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const handleChange = (brand: string) => {
    filterStore.filters.model = null
    filterStore.filters.brand = brand

    filterStore.filters.cars = brand ? [{ brand }] : []
  }

  const options: OptionProps[] = [{ value: undefined, label: formatMessage({ id: 'filterAny' }) }]
  return useObserver(() => {
    const { brands } = filterStore

    return (
      <Select
        style={{ width: '100%' }}
        onChange={(value: any) => handleChange(value)}
        value={(filterStore.filters.cars.length && filterStore.filters.cars[0]?.brand) || undefined}
        placeholder={<FormattedMessage id="brandLabel" />}
        options={options.concat(brands.map((b) => ({ label: b._id, value: b._id })))}
      />
    )
  })
}

export { BrandFilter }
