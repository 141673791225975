import React from 'react'

const Fr: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.53 5.59">
      <path d="M2.84,5.59H.15A.15.15,0,0,1,0,5.44H0V.15A.15.15,0,0,1,.15,0H2.84Z" transform="translate(0 0)" fill="#41479b" />
      <rect x="2.84" width="2.84" height="5.59" fill="#f5f5f5" />
      <path d="M8.38,5.59H5.68V0h2.7a.15.15,0,0,1,.15.15V5.44A.15.15,0,0,1,8.38,5.59Z" transform="translate(0 0)" fill="#ff4b55" />
    </svg>
  )
}

export { Fr }
