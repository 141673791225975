import React from 'react'
import { useObserver } from 'mobx-react'
import { useIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Row, Col, Radio } from 'antd'

import { RangeFilterFormDropdown } from '../../../'
import { useFilterStore } from '../../../../hooks'
import { RangeFilter, PowerType } from '../../../../types'

const values = [34, 50, 60, 75, 90, 101, 118, 131, 150, 200, 252, 303, 358, 402, 454]

const PowerFilter: React.FC = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onChange = (values: RangeFilter) => {
    filterStore.setPowerFilter(values)
  }

  const onTypeChange = (key: string) => {
    let payload: PowerType
    switch (key) {
      case 'KW':
        payload = PowerType.KW
        break
      case 'PS':
        payload = PowerType.PS
        break
      default:
        payload = PowerType.PS
        break
    }

    if (payload) {
      filterStore.setPowerFilterType(payload)
    }
  }

  return useObserver(() => {
    const power = filterStore.filters.power
    return (
      <Wrapper>
        <RangeFilterFormDropdown
          label={formatMessage({ id: 'powerFilterLabel' })}
          unit={filterStore.filters.powerType}
          value={power}
          values={values}
          onChange={onChange}
        />

        <Row gutter={[12, 12]}>
          <Radio.Group value={filterStore.filters.powerType} onChange={(e: any) => onTypeChange(e.target.value)}>
            <Col span={12}>
              <Radio value={PowerType.PS}>
                <FormattedMessage id="ps" />
              </Radio>
            </Col>
            <Col span={12}>
              <Radio value={PowerType.KW}>
                <FormattedMessage id="kw" />
              </Radio>
            </Col>
          </Radio.Group>
        </Row>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  .ant-radio {
    &-group {
      width: 100%;
    }

    &-checked::after {
      border: 1px solid #e0e0e0;
    }

    &-inner {
      &::after {
        background-color: #e0e0e0;
      }

      border-color: #545455;
      background-color: #1e1e1e;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border: 1px solid #e0e0e0 !important;
    }

    &-wrapper {
      color: #fff;
    }
  }
`

export { PowerFilter }
