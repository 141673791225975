import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Select as AntSelect } from 'antd'
import { SelectProps as AntSelectProps, OptionProps } from 'antd/lib/select'

const AntOption = AntSelect.Option

interface SelectProps<T> extends AntSelectProps<T> {
  options?: OptionProps[]
  ref?: React.RefObject<AntSelectProps>
}

const filter = (val: string | number, option: any): boolean => {
  return option.props.label.toString().toLowerCase().startsWith(val.toString().toLowerCase())
}

const Select = <T,>({ children, options, value, ref, ...rest }: PropsWithChildren<SelectProps<T>>) => {
  return (
    <StyledSelect ref={ref} {...rest} value={value} filterOption={options ? filter : null} showSearch={options ? true : false}>
      {options
        ? options.map(({ label, value }) => (
            <AntOption key={value} value={value} label={label}>
              {label}
            </AntOption>
          ))
        : children}
    </StyledSelect>
  )
}

const StyledSelect = styled(AntSelect as any)`
  width: 100% !important;
  height: 50px;

  .ant-select {
    &-arrow {
      color: #595959;
    }

    &-selection {
      background-color: #1d1e1e;
      border: 1px solid #575958;
      border-radius: 0px;
      height: 50px;

      &--multiple {
        .ant-select-selection__rendered > ul > li {
          margin-top: 12px;
          background-color: #2c2c2c;
          color: #fff;
          border-color: #2c2c2c;
        }
      }

      .ant-select-arrow {
        font-size: 16px;
      }

      &__rendered {
        color: #fff;
        line-height: 50px;
      }

      &:hover,
      &:focus {
        border-color: #1daeee;
      }

      &:focus {
        .ant-select-select:active {
          border-color: #575958;
        }
      }
    }

    &-focused {
      .ant-select-selection {
        border-color: #575958;
      }
    }

    &-dropdown {
      border-radius: 0px;

      &-menu {
        &-item {
          &:first-child,
          &:last-child {
            border-radius: 0px;
          }
        }
      }
    }
  }
`

export { Select }
export type { OptionProps }
