import { observable, action, computed } from 'mobx'
import { DetailCar } from '../types'

export class CarStore {
  @observable loading = true
  @observable carToLoad: string = ''
  @observable car?: DetailCar

  @observable activeVehicleTab: string = 'vehicleData'

  @action
  loadCar(_id: string) {
    this.carToLoad = _id
  }

  @action
  setLoading(state: boolean) {
    this.loading = state
  }

  @action
  setCar(car: DetailCar) {
    this.car = car
  }

  @computed get getPrice(): number | undefined {
    return this.car?.vatReportable ? this.car.priceWithoutTax : this.car?.price
  }

  @action
  setActiveVehicleTab(tab: string) {
    this.activeVehicleTab = tab
  }
}
