import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { Button, Badge, Icon, Modal } from '../../components'

interface ParkSuccModalProps {
  visible: boolean
  onHide: () => void
}

const ParkSuccModal: React.FC<ParkSuccModalProps> = ({ visible, onHide }) => {
  const hide = () => {
    onHide()
  }

  return useObserver(() => (
    <Modal
      visible={visible}
      footer={[
        <Button key="back" href="https://www.sug.de/de/gebrauchtwagen/parkplatz.html">
          <FormattedMessage id="to_parking_space" />
        </Button>,

        <Button key="submit" type="primary" onClick={() => hide()}>
          <FormattedMessage id="continue_searching" />
        </Button>,
      ]}
      onCancel={() => hide()}
    >
      <ModalContent>
        <Badge count={<Icon type="check" />} />
        <h2>
          <FormattedMessage id="parked" />!
        </h2>
        <p>
          <FormattedMessage id="car_successfully_parked" />.
        </p>
      </ModalContent>
    </Modal>
  ))
}

const ModalContent = styled.div`
  .ant-badge {
    display: inline-block;
    margin-bottom: 15px;
    color: white;

    .custom-icon {
      position: relative;
      top: auto;
      min-width: 43px;
      height: 43px;
      line-height: 43px;
      border-radius: 100%;
    }
  }
`

export { ParkSuccModal }
