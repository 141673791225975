import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../../hooks'
import { Select } from '../..'

export interface BrandDropdownProps {
  value?: string
  onChange?: (value: string) => void
}

const BrandDropdown: React.FC<BrandDropdownProps> = ({ value, onChange }, ref) => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const { brands } = filterStore

    const options: OptionProps[] = brands.map((b) => ({ value: b._id, label: b._id }))

    return (
      <Select<string>
        ref={ref}
        value={value || undefined}
        onChange={(v: any) => (onChange ? onChange(v) : false)}
        placeholder={<FormattedMessage id="brandLabel" />}
        options={options}
      />
    )
  })
}
export { BrandDropdown }
