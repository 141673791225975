import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

interface LocationFilterPorps {}

const LocationFilterPage: React.FC<LocationFilterPorps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: string) => {
    filterStore.addOrRemoveLocation(value)
  }

  return useObserver(() => {
    const { locations } = filterStore

    return (
      <Page
        header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="locationFilterLabel" />} />}
        footer={<Footer showMeta={false} />}
      >
        <Wrapper>
          {locations.length &&
            locations.map((location) => (
              <CheckedButton
                onClick={() => onFilterChange(location)}
                label={location}
                active={filterStore.filters.locations.includes(location)}
                key={location}
              />
            ))}
        </Wrapper>
      </Page>
    )
  })
}

export { LocationFilterPage }
