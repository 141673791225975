import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { reaction, toJS } from 'mobx'

import { useFilterStore } from '../../hooks'

const INIT_FILTERS = gql`
  query Initial(
    $brandFilterInput: BrandFilterInput!
    $locationFilterInput: LocationFilterInput!
    $firstRegistrationsFilterInput: FirstRegistrationsFilterInput!
  ) {
    brands(filter: $brandFilterInput) {
      _id
      models {
        _id
        models {
          _id
        }
      }
    }

    locations(filter: $locationFilterInput) {
      _id
    }

    firstRegistrations(filter: $firstRegistrationsFilterInput) {
      min
      max
    }
  }
`

const FilterHandler: React.FC<{}> = () => {
  const filterStore = useFilterStore()

  const getFilters = () => {
    return {
      brandFilterInput: {
        utilityVehicle: filterStore.filters.utilityVehicle,
      },
      locationFilterInput: {
        utilityVehicle: filterStore.filters.utilityVehicle,
      },
      firstRegistrationsFilterInput: {
        utilityVehicle: filterStore.filters.utilityVehicle,
      },
    }
  }

  const { data, error, refetch } = useQuery(INIT_FILTERS, {
    variables: getFilters(),
  })

  useEffect(() => {
    if (data) {
      filterStore.setLoading(false)
      filterStore.setBrands(data.brands)
      filterStore.setLocations(data.locations)
      filterStore.setFirstRegistrationRange(data.firstRegistrations)
    }

    if (error) {
      filterStore.setLoading(false)
    }
  })

  reaction(
    () => toJS(filterStore.filters.utilityVehicle),
    () => {
      refetch(getFilters())
    }
  )

  return null
}

export default FilterHandler
