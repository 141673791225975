import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  padding: 25px;
  color: #5d5d5d;

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

const filters = [
  { value: 1, label: <FormattedMessage id="gearboxes.MANUAL" /> },
  { value: 2, label: <FormattedMessage id="gearboxes.SEMI_AUTOMATIC" /> },
  { value: 3, label: <FormattedMessage id="gearboxes.AUTOMATIC" /> },
]

interface GearboxFilterPageProps {}

const GearboxFilterPage: React.FC<GearboxFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: number) => {
    filterStore.addOrRemoveGearbox(value)
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="gearboxFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        {filters.map(filter => (
          <CheckedButton
            onClick={() => onFilterChange(filter.value)}
            label={filter.label}
            active={filterStore.filters.engine.gearboxes.includes(filter.value)}
            key={filter.value}
          />
        ))}
      </Wrapper>
    </Page>
  ))
}

export { GearboxFilterPage }
