import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { CheckedButton } from '../'
import { QualitySeal } from '../../types'

const Wrapper = styled.div`
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }
`

interface QualitySealFilterProps {}

const QualitySealFilter: React.FC<QualitySealFilterProps> = () => {
  const filterStore = useFilterStore()

  const toggleFilter = <K extends keyof QualitySeal>(index: K) => {
    filterStore.toggleQualitySeal(index)
  }

  return useObserver(() => (
    <Wrapper>
      <CheckedButton
        onClick={() => toggleFilter('youngStar')}
        label={<FormattedMessage id="qualitySeal.youngStar" />}
        active={filterStore.filters.qualitySeal.youngStar}
        key="youngStar"
      />
    </Wrapper>
  ))
}

export { QualitySealFilter }
