import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { $enum } from 'ts-enum-util'

import { useFilterStore } from '../../hooks'
import { CheckedButton } from '../../components'

import { AcEquipment } from '../../types'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  color: #5d5d5d;
`

interface AcEquipmentFilterProps {}

const AcEquipmentFilter: React.FC<AcEquipmentFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: AcEquipment) => {
    filterStore.addOrRemoveAcEquipment(value)
  }

  return useObserver(() => (
    <Wrapper>
      <FormattedMessage id="ac" />
      {$enum(AcEquipment)
        .getValues()
        .map(filter => (
          <CheckedButton
            onClick={() => onFilterChange(filter)}
            label={<FormattedMessage id={`equipment.${filter}`} />}
            active={filterStore.filters.equipment.ac.includes(filter)}
            key={filter}
          />
        ))}
    </Wrapper>
  ))
}

export { AcEquipmentFilter }
