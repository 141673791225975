import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore, useRouterStore } from '../../hooks'

import { Page, CheckedButton, Header, Footer } from '../../components'

const Wrapper = styled.div`
  padding: 25px;

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const options = [
  { label: <FormattedMessage id="sort.asc" values={{ value: <FormattedMessage id="price" /> }} />, value: 'price' },
  { label: <FormattedMessage id="sort.desc" values={{ value: <FormattedMessage id="price" /> }} />, value: '-price' },
  { label: <FormattedMessage id="sort.asc" values={{ value: <FormattedMessage id="mileage" /> }} />, value: 'mileage' },
  { label: <FormattedMessage id="sort.desc" values={{ value: <FormattedMessage id="mileage" /> }} />, value: '-mileage' },
  { label: <FormattedMessage id="sort.asc" values={{ value: <FormattedMessage id="firstRegistration" /> }} />, value: 'firstRegistration' },
  {
    label: <FormattedMessage id="sort.desc" values={{ value: <FormattedMessage id="firstRegistration" /> }} />,
    value: '-firstRegistration',
  },
]

interface SortPageProps {}

const SortPage: React.FC<SortPageProps> = () => {
  const filterStore = useFilterStore()
  const routerStore = useRouterStore()

  const onSortChange = (sort: string) => {
    filterStore.setSort(sort)
    routerStore.goBack()
  }

  return useObserver(() => (
    <Page header={<Header withBack title={<FormattedMessage id="sortPageLabel" />} />} footer={<Footer showMeta={false} />}>
      <Wrapper>
        {options.map(option => (
          <CheckedButton
            key={option.value}
            label={option.label}
            onClick={() => onSortChange(option.value)}
            active={filterStore.pagination.sort === option.value}
          />
        ))}
      </Wrapper>
    </Page>
  ))
}

export { SortPage }
