export enum Co2EfficiencyClassColor {
  'A+' = '#009e46',
  'A' = '#009e46',
  'B' = '#67b633',
  'C' = '#d2d700',
  'D' = '#ffed00',
  'E' = '#fdc602',
  'F' = '#f17e04',
  'G' = '#eb2220',
}
