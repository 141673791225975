import React from 'react'
import { useObserver } from 'mobx-react'
import { useIntl } from 'react-intl'

import { RangeFilterFormDropdown } from '../../../'
import { useFilterStore } from '../../../../hooks'
import { RangeFilter } from '../../../../types'
import { getYearRange } from '../../../../helpers/rangeFilter'

const RegistrationFilter: React.FC = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onChange = (values: RangeFilter) => {
    filterStore.setFirstRegistrationFilter(values)
  }

  return useObserver(() => {
    const registration = filterStore.filters.firstRegistration
    const values = getYearRange(filterStore.firstRegistrationRange.min, filterStore.firstRegistrationRange.max)

    return (
      <RangeFilterFormDropdown
        label={formatMessage({ id: 'firstRegistrationLabel' })}
        value={registration}
        values={values}
        onChange={onChange}
      />
    )
  })
}

export { RegistrationFilter }
