import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

import { useFilterStore } from '../../../../hooks'
import { Input } from '../../../'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  color: #fff;
  label {
    line-height: 39.9999px;
  }

  input {
    height: 50px;
  }
`

const GWNumberFilter: React.FC = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    filterStore.changeGwNumberFilter(event.target.value)
  }

  return useObserver(() => (
    <Wrapper>
      <label>
        <FormattedMessage id="gwNumberFilterLabel" /> :
      </label>
      <Input onChange={onFilterChange} value={filterStore.filters.gwNumber} />
    </Wrapper>
  ))
}

export { GWNumberFilter }
