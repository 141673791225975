import React from 'react'
import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

const StyledModal = styled(AntModal)`
  max-width: calc(100vw - 50px);
  padding-bottom: 0;
  text-align: center !important;

  .ant-modal {
    &-header {
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
    }

    &-content {
      padding: 23px;
      color: #fff;
      background-color: #0e0e0e;
      border: 2px solid #333;
      border-radius: 0;

      h2 {
        margin-bottom: 5px;
        font-family: 'CorporateA', serif;
        font-size: 30px;
        color: #fff !important;
        line-height: normal;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      padding: 0;
      border: none;
      border-radius: 0;

      .ant-btn {
        display: inline-block;
        margin: 8px 0;
        border-color: #252525;
        background-color: #252525;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-primary {
          color: #1d1d1b;
          background-color: #fff;
        }
      }
    }

    &-close {
      color: #5d5e5f;

      &:focus,
      &:hover {
        color: #fff;
      }
    }
  }
`

const Modal: React.FC<ModalProps> = props => {
  return <StyledModal centered {...props} />
}

export { Modal }
