import React from 'react'
import { useObserver } from 'mobx-react'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import {
  PriceFilter,
  MileageFilter,
  PowerFilter,
  RegistrationFilter,
  GWNumberFilter,
  VatReportableFilter,
  FuelFilter,
  GearBoxFilter,
  CcmFilter,
  ColorFilter,
  EquipmentFilter,
  QualitySealFilter,
  TotalWeightFilter,
  SeatFilter,
  Co2EuroNormFilter,
  CarEnvBadgeFilter,
  CarInventoryFilter,
  FinancingFilter,
} from './components'

import { LocationFilter } from '../../Filters/LocationFilter'

import { Title, VehicleTypeSwitcher, CarFilterDesktop } from '../../'
import { useFilterStore } from '../../../hooks'

const DetailFilterDesktop: React.FC = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <Wrapper>
      {/** carInventory */}
      <Row type="flex">
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="carInventory" />
          </Title>
        </Col>
        <Col span={24}>
          <CarInventoryFilter />
        </Col>
      </Row>
      {/** QuaitySeal */}
      <Row type="flex">
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="qualitySealFilterLabel" />
          </Title>
        </Col>
        <Col span={6}>
          <QualitySealFilter />
        </Col>
      </Row>
      {/** Car Filter  */}
      <Row type="flex">
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="carFilterLabel" />
          </Title>
        </Col>
        <Col span={24}>
          <CarFilterDesktop />
        </Col>
      </Row>
      {/** vehicle type */}
      <Row type="flex">
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="vehicleType" />
          </Title>
        </Col>
        <Col span={24}>
          <VehicleTypeSwitcher />
        </Col>
      </Row>
      {/** car search */}
      <Row type="flex" gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="carSearch" />
          </Title>
        </Col>
        <Col span={24}>
          <Col span={12}>
            <RegistrationFilter />
          </Col>
          <Col span={12}>
            <MileageFilter />
          </Col>
          <Col span={12}>
            <PriceFilter />
          </Col>
          <Col span={12}>
            <FinancingFilter />
          </Col>
          <Col span={12}>
            <PowerFilter />
          </Col>
          {filterStore.filters.utilityVehicle ? (
            <>
              <Col span={6}>
                <TotalWeightFilter />
              </Col>
              <Col span={6}>
                <VatReportableFilter />
              </Col>
              <Col span={6}>
                <SeatFilter />
              </Col>
              <Col span={6}>
                <Co2EuroNormFilter />
              </Col>
              <Col span={6}>
                <CarEnvBadgeFilter />
              </Col>
            </>
          ) : null}
          <Col span={6}>
            <GWNumberFilter />
          </Col>
        </Col>
      </Row>
      {/** engine */}
      <Row type="flex" gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="engine" />
          </Title>
        </Col>
        <Col span={24}>
          <FuelFilter />
        </Col>
        <Col span={24}>
          <GearBoxFilter />
        </Col>
        <Col span={12}>
          <CcmFilter />
        </Col>
      </Row>
      {/** location */}
      <Row type="flex" gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="locationFilterLabel" />
          </Title>
        </Col>
        <Col span={6}>
          <LocationFilter />
        </Col>
      </Row>
      {/** Color */}
      <Row type="flex" gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="colorFilterLabel" />
          </Title>
        </Col>
        <Col span={24}>
          <ColorFilter />
        </Col>
      </Row>
      {/** equipment */}
      <Row type="flex" gutter={[12, 12]}>
        <Col span={24}>
          <Title level={3}>
            <FormattedMessage id="equipmentFilterLabel" />
          </Title>
        </Col>
        <Col span={24}>
          <EquipmentFilter />
        </Col>
      </Row>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  padding: 25px;

  & > .ant-row-flex {
    &:not(:last-child) {
      margin-bottom: 20px !important;
    }

    & > .ant-col {
      &:first-child {
        border-bottom: 1px solid #3d3d3d;
      }

      &:not(:first-child) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .vehicleTypeSwitcher > label {
    display: none;
  }

  .locationFilter {
    margin-top: 20px;
  }
`

export { DetailFilterDesktop }
