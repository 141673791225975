import React from 'react'
import { useObserver } from 'mobx-react'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import breakpoint, { map } from 'styled-components-breakpoint'
import * as Scroll from 'react-scroll'

import { useCarStore, useAppStore } from '../../hooks'
import { Icon } from '../'

export interface CarDetailButtonProps {
  onPark: (uid: string) => void
}

const CarDetailButtons: React.FC<CarDetailButtonProps> = ({ onPark }) => {
  const carStore = useCarStore()
  const appStore = useAppStore()

  const print = () => {
    window.print()
  }

  const scrollToEquipment = () => {
    carStore.setActiveVehicleTab('equipment')
    Scroll.scroller.scrollTo('vehicleData', {
      smooth: true,
      offset: -75,
      duration: 300,
    })
  }

  return useObserver(() => (
    <Wrapper>
      <Row gutter={[{ xs: 20, lg: 0 }, 25]}>
        <Col xs={12} md={8}>
          <Button onClick={() => scrollToEquipment()} className={'equipmentButton'}>
            <Icon type="filter" />
            <FormattedMessage id="carDetailButtons.equipment" />
          </Button>
        </Col>

        <Col xs={12} md={8}>
          <Scroll.Link to="rateCalc" isDynamic duration={300} smooth={true} offset={-75}>
            <Button className={'financeButton'}>
              <Icon type="finance" />
              <FormattedMessage id="carDetailButtons.finance" />
            </Button>
          </Scroll.Link>
        </Col>

        <Col xs={12} md={8}>
          <Button onClick={print} className={'last printButton'}>
            <Icon type="print" />
            <FormattedMessage id="carDetailButtons.print" />
          </Button>
        </Col>

        <Col xs={12} md={8}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.sug.de/de/gebrauchtwagen/anfrage.html?tx_suggebrauchtwagen_suggebrauchtwagen%5Bcarid%5D=${carStore.car?.uid}`}
          >
            <Button className={'onlineRequestButton'}>
              <Icon type="user" />
              <FormattedMessage id="carDetailButtons.onlineRequest" />
            </Button>
          </a>
        </Col>

        <Col xs={12} md={8}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.sug.de/de/gebrauchtwagen/pdf_anfrage.html?tx_suggebrauchtwagen_suggebrauchtwagen%5Bcarid%5D=${carStore.car?.uid}&gwlanguage=${appStore.language}`}
          >
            <Button className={'pdfButton'}>
              <Icon type="download" />
              <FormattedMessage id="carDetailButtons.pdf" />
            </Button>
          </a>
        </Col>

        <Col xs={12} md={8}>
          <Button onClick={() => onPark(carStore.car?.uid || '')} className={'last parkButton'}>
            <Icon type="park" />
            <FormattedMessage id="carDetailButtons.park" />
          </Button>
        </Col>
      </Row>
    </Wrapper>
  ))
}

const Wrapper = styled.div``

const Button = styled.div`
  ${map({ mobile: '20px 10px', tablet: '30px 10px' }, (padding) => `padding: ${padding};`)}
  background-color: #0e0e0e;

  display: flex;
  justify-content: center;
  align-items: center;
  ${map({ mobile: 'column', tablet: 'row' }, (direction) => `flex-direction: ${direction};`)}

  font-size: 12px;
  line-height: 1;
  color: #fff;

  border-right: none;

  ${breakpoint('desktop')`
    border-right: 1px solid #212121;

    &.last {
      border-right: none;
    }
  `}

  .custom-icon, .anticon {
    svg {
      height: 20px;
      width: auto;
      fill: #1daeee;
    }

    ${map({ mobile: '8px', tablet: '0px' }, (marginBottom) => `margin-bottom: ${marginBottom};`)}
    ${map({ mobile: '0px', tablet: '8px' }, (marginRight) => `margin-right: ${marginRight};`)}
  }

  transition: all 0.2s ease-out;

  &:hover {
    cursor: pointer;
    color: #1daeee;
    transform: scale(1.05);
  }
`

export { CarDetailButtons }
