import React from 'react'
import { useObserver } from 'mobx-react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'
import { toJS } from 'mobx'

import { RateCalcForm, Popover, Icon } from '../'
import { RateCalculatorFormType, RateCalculatorOutput } from '../../types'
import { useRateCalcStore, useCarStore } from '../../hooks'
import { FormattedMessage, FormattedNumber } from 'react-intl'

const CarDetailRateCalculator: React.FC<{}> = () => {
  const rateCalcStore = useRateCalcStore()
  const carStore = useCarStore()

  const onChange = (values: RateCalculatorFormType) => {
    rateCalcStore.setFormData(values)
  }

  const renderPopover = (text: string): React.ReactNode => {
    return (
      <PopoverContent>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </PopoverContent>
    )
  }

  const disclaimerOffset = 1

  return useObserver(() => {
    const disclaimer = toJS(rateCalcStore.output).filter((output) =>
      output.disclaimer && output.disclaimer !== 'ocapi.de_DE.null' ? true : false
    )

    return (
      <Wrapper>
        <Row type="flex">
          {rateCalcStore.output.length ? (
            <>
              <Col xs={24} md={8}>
                {!rateCalcStore.initialLoading ? <RateCalcForm values={rateCalcStore.form} onChange={onChange} /> : null}
              </Col>
              <Col xs={24} md={16}>
                <Row type="flex" justify="space-between">
                  <Col span={24}>
                    <OutputWrapper>
                      <Row key="buyPrice" type="flex" justify="space-between">
                        <Col>
                          <FormattedMessage id="buyPrice" />
                        </Col>
                        {carStore.car ? (
                          <Col>
                            <FormattedNumber value={rateCalcStore.getPrice(carStore.car)} />
                            {` `}€
                          </Col>
                        ) : null}
                      </Row>
                      {rateCalcStore.output.length
                        ? rateCalcStore.output.map((output: RateCalculatorOutput) => (
                            <Row
                              key={`${output.id}${output.value}`}
                              type="flex"
                              justify="space-between"
                              className={output.highlight ? 'highlight' : ''}
                            >
                              <Col>
                                <span dangerouslySetInnerHTML={{ __html: output.label }} />
                                {/** disclaimer */}

                                {output.disclaimer && output.disclaimer !== 'ocapi.de_DE.null' ? (
                                  <sup>[{disclaimer.findIndex((item) => item.id === output.id) + disclaimerOffset}]</sup>
                                ) : null}

                                {output.infoTextKey ? (
                                  <>
                                    <Popover className="popTrigger" trigger="hover" content={renderPopover(output.infoTextKey)}>
                                      <span>
                                        <Icon type="exclamation-circle" />
                                      </span>
                                    </Popover>
                                  </>
                                ) : null}
                              </Col>
                              <Col>{output.value}</Col>
                            </Row>
                          ))
                        : null}
                    </OutputWrapper>
                  </Col>
                  <Col span={24}>
                    <DisclaimerWrapper>
                      {disclaimer.length
                        ? disclaimer.map((output: RateCalculatorOutput, i: number) => {
                            return output.disclaimer ? (
                              <div key={`${output.id}`}>
                                <span dangerouslySetInnerHTML={{ __html: `[${i + disclaimerOffset}] ` + output.disclaimer }} />
                              </div>
                            ) : null
                          })
                        : null}
                    </DisclaimerWrapper>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col>Leider können Wir Ihnen für dieses Fahrzeug keine Finanzierung anbieten.</Col>
          )}
        </Row>
      </Wrapper>
    )
  })
}

const DisclaimerWrapper = styled.div`
  font-size: 12px;
  color: #5d5e5f;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`

const PopoverContent = styled.div`
  max-width: 400px;
`

const OutputWrapper = styled.div`
  padding-top: 20px;

  .popTrigger {
    margin-left: 10px;
  }

  .ant-row-flex {
    padding-top: 15px;
    padding-bottom: 15px;

    & .ant-col {
      &:first-child {
        .custom-icon {
          color: #5d5e5f;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #383838;
    }
  }

  .highlight {
    font-weight: bold;
    background-color: #191919;
  }
`

const Wrapper = styled.div`
  > .ant-row-flex {
    > .ant-col {
      &:first-child {
        ${breakpoint('mobile')`
          padding-bottom: 25px;
          padding-right: 0px;
        `}

        ${breakpoint('tablet')`
          padding-right: 25px;
          padding-bottom: 0px;
        `}
      }

      &:last-child {
        .ant-row-flex {
          height: 100%;

          & .ant-col {
            &:last-child {
              display: flex;
              flex-direction: column-reverse;
            }
          }
        }
      }
    }
  }
`

export { CarDetailRateCalculator }
