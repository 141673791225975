import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { Col, Row } from 'antd'
import { Form, InputGroup, FormItem, Input } from '../../'
import { CarFilter } from '../../../types'
import { BrandDropdown } from './BrandDropdown'
import { ModelDropdown } from './ModelDropdown'

export interface CarFormProps extends FormComponentProps {
  onChange: (value: CarFilter) => void
  value?: CarFilter
}

const CarFormFC = forwardRef<FormComponentProps, CarFormProps>(({ form, onChange, value }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
    onChange,
  }))

  const { formatMessage } = useIntl()

  const BrandDropdownRef = forwardRef(BrandDropdown as any)
  const ModelDropdownRef = forwardRef(ModelDropdown as any) as any

  useEffect(() => {
    if (value) {
      const current = form.getFieldsValue()
      if (current.brand !== value.brand || current.model !== value.model || current.variant !== value.variant) {
        form.setFieldsValue(value)
      }
    }
  })

  return (
    <Wrapper className="carForm">
      <Form>
        <InputGroup>
          <Row type="flex" gutter={12}>
            <Col xs={8}>
              <FormItem>{form.getFieldDecorator('brand')(<BrandDropdownRef />)}</FormItem>
            </Col>
            <Col xs={8}>
              <FormItem>{form.getFieldDecorator('model')(<ModelDropdownRef selectedBrand={form.getFieldValue('brand')} />)}</FormItem>
            </Col>
            <Col xs={8}>
              <FormItem>
                {form.getFieldDecorator('variant')(<Input id="variant" placeholder={formatMessage({ id: 'placeholder.variant' })} />)}
              </FormItem>
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </Wrapper>
  )
})

const CarForm = Form.create<CarFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(CarFormFC)

const Wrapper = styled.div`
  .ant-input {
    height: 50px;
  }

  .ant-form-item {
    margin: 0px;
  }
`

export { CarForm }
