import de from './de.js'
import en from './en.js'
import es from './es.js'
import fr from './fr.js'
import it from './it.js'
import no from './no.js'
import pl from './pl.js'
import pt from './pt.js'
import ro from './ro.js'
import ru from './ru.js'
import sv from './sv.js'
import tr from './tr.js'

export default { de, en, es, fr, it, no, pl, pt, ro, ru, sv, tr }
