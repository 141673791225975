import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { Page, Header, QualitySealFilter, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  color: #5d5d5d;
  padding: 25px;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }
`

interface QualitySealFilterPageProps {}

const QualitySealFilterPage: React.FC<QualitySealFilterPageProps> = () => {
  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="qualitySealFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <QualitySealFilter />
      </Wrapper>
    </Page>
  ))
}

export { QualitySealFilterPage }
