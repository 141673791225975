export enum GeneralEquipment {
  'trailerCoupling' = 'trailerCoupling',
  'parkingDistanceControlSystem' = 'parkingDistanceControlSystem',
}

export enum GeneralEquipmentUtilityVehicle {
  'crane' = 'crane',
  'loadingBoard' = 'loadingBoard',
  'slidingDoor' = 'slidingDoor',
}

export enum AcEquipment {
  'noAc' = 'noAc',
  'ac' = 'ac',
  'automaticAc' = 'automaticAc',
}

export enum DriversCabEquipmentUtilityVehicle {
  'twinBerth' = 'twinBerth',
  'berth' = 'berth',
}

export enum InteriorEquipment {
  'bluetooth' = 'bluetooth',
  'bordcomputer' = 'bordcomputer',
  'cdPlayer' = 'cdPlayer',
  'electricWindows' = 'electricWindows',
  'electricExteriorMirrors' = 'electricExteriorMirrors',
  'electricAdjustableSeats' = 'electricAdjustableSeats',
  'speakerphone' = 'speakerphone',
  'headUpDisplay' = 'headUpDisplay',
  'mp3Interface' = 'mp3Interface',
  'multiFunctionSteeringWheel' = 'multiFunctionSteeringWheel',
  'navigationSystem' = 'navigationSystem',
  'rainSensor' = 'rainSensor',
  'sunroof' = 'sunroof',
  'powerSteering' = 'powerSteering',
  'centralLockingSystem' = 'centralLockingSystem',
  'seatHeating' = 'seatHeating',
  'skiBag' = 'skiBag',
  'auxiliaryHeating' = 'auxiliaryHeating',
  'startStopSystem' = 'startStopSystem',
  'cruiseControl' = 'cruiseControl',
  'tuner' = 'tuner',
  'tractionControlSystem' = 'tractionControlSystem',
}

export enum InteriorEquipmentUtilityVehicle {
  'partitionWall' = 'partitionWall',
}

export enum SecurityEquipment {
  'abs' = 'abs',
  'allWheelDrive' = 'allWheelDrive',
  'immobiliser' = 'immobiliser',
  'frontFogLights' = 'frontFogLights',
  'esp' = 'esp',
  'curveLights' = 'curveLights',
  'daytimeRunningLights' = 'daytimeRunningLights',
  'xenonHeadlights' = 'xenonHeadlights',
  'lightSensor' = 'lightSensor',
}

export enum SecurityEquipmentUtilityVehicle {
  'particleFilter' = 'particleFilter',
}
