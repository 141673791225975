import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../hooks'
import { Select } from '../Select'

const RegistrationDateFromFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const options: OptionProps[] = [{ label: formatMessage({ id: 'filterAny' }), value: undefined }]

  function range(min: number, max: number): number[] {
    function rangeRec(min: number, max: number, vals: any): any {
      if (min > max) return vals
      vals.push(min)
      return rangeRec(min + 1, max, vals)
    }
    return rangeRec(min, max, [])
  }

  return useObserver(() => (
    <Select<number>
      style={{ width: '100%' }}
      onChange={(firstRegistration: any) => (filterStore.filters.firstRegistration.gte = firstRegistration)}
      value={filterStore.filters.firstRegistration.gte || undefined}
      placeholder={<FormattedMessage id="firstRegistrationFromLabel" />}
      options={options.concat(
        range(filterStore.firstRegistrationRange.min, filterStore.firstRegistrationRange.max).map((d) => ({ value: d, label: d }))
      )}
    ></Select>
  ))
}

export { RegistrationDateFromFilter }
