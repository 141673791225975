import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useAppStore } from '../hooks'
import { Button, Modal } from './'

const LoginModal: React.FC<{}> = () => {
  const appStore = useAppStore()

  const close = () => {
    appStore.setLoginModalVisible(false)
  }

  return useObserver(() => (
    <Modal
      visible={appStore.isLoginModalOpened}
      footer={[
        <Button key="register" href="https://www.sug.de/de/mein-sg/registrieren">
          <FormattedMessage id="register" />
        </Button>,

        <Button key="login" href="https://www.sug.de/de/mein-sg/login">
          <FormattedMessage id="login" />
        </Button>,
      ]}
      onCancel={() => close()}
    >
      <h2>
        <FormattedMessage id="parkModalHeader" />
      </h2>
    </Modal>
  ))
}

export { LoginModal }
