export default {
  language: 'Sprache',
  totalMatches: '{count} Treffer',
  matches: 'Treffer',
  cancel: 'Abbrechen',
  new_search: 'Neue Suche',
  vehicleType: 'Fahrzeugart',
  carSearch: 'Fahrzeugsuche',
  detailSearch: 'Detailsuche',
  vehicle: 'Fahrzeug',
  engine: 'Motor',
  price: 'Preis',
  priceUntil: 'Preis bis',
  buyPrice: 'Kaufpreis',
  filter: 'Filter',
  equipmentFilterLabel: 'Ausstattung',
  contains_following_filters: 'Beinhaltet folgende Filterkriterien',
  addCar: 'Fahrzeug hinzufügen',
  selectedCars: 'Ausgewählte Fahrzeuge',
  carFilterLabel: 'Marke, Modell, Variante',
  brandLabel: 'Marke',
  modelLabel: 'Modell',
  search: 'Suche',
  filterAny: 'Beliebig',
  back: 'Zurück',
  utilityVehicle: 'Nutzfahrzeug',
  car: 'PKW',
  priceFilterLabel: 'Preis/Monatsrate',
  firstRegistrationLabel: 'Erstzulassung',
  firstRegistrationFromLabel: 'Erstzulassung ab',
  powerFilterLabel: 'Leistung',
  fuelFilterLabel: 'Treibstoff',
  popular: 'Beliebt',
  reset_filters: 'Suchkriterien entfernen',
  reset_search: 'Suche zurücksetzen',
  want_to_reset_search: 'Möchten Sie, dass Ihre Suchkriterien entfernt werden?',
  carCategory: 'Bauart',
  warranty: 'Garantie',
  vatReportable: 'MwSt. ausweisbar',
  consumptionAndEmission: 'Verbrauch und Emissionen',
  locationTagCount: '{count} Standorte ausgewählt',
  placeholders: {
    from: 'von',
    to: 'bis',
    name: 'Name eingeben…',
  },
  filterLabel: {
    from: 'Ab {from} {symbol}',
    to: 'Bis {to} {symbol}',
    range: '{from} {symbol} - {to} {symbol}',
    registration3: 'Bis zu 3 Jahre',
    registration10: 'Bis zu 10 Jahre',
    mileage: 'Bis {value} Kilometer',
  },
  from: 'Von',
  to: 'Bis',
  firstRegistration: 'Erstzulassung',
  mileage: 'Laufleistung',
  mileageUntil: 'Kilometer bis',
  emission: 'CO2-Emission komb.',
  fuelConsumption: 'Verbrauch komb.',
  powerConsumption: 'Stromverbrauch',
  monthlyRate: 'Monatliche Rate',
  parked: 'Geparkt',
  car_successfully_parked: 'Das Fahrzeug wurde erfolgreich geparkt',
  to_parking_space: 'zum Parkplatz',
  continue_searching: 'Weitersuchen',
  ps: 'PS',
  kw: 'KW',
  fuels: {
    LPG: 'Autogas (LPG)',
    PETROL: 'Benzin',
    DIESEL: 'Diesel',
    NATURAL_GAS: 'Erdgas (CNG)',
    ELECTRIC: 'Elektro',
    HYBRID_PETROL: 'Hybrid (Benzin / Elektro)',
    HYDROGEN: 'Wasserstoff',
    ETHANOL: 'Ethanol (FFV, E85 etc.)',
    HYBRID_DIESEL: 'Hybrid (Diesel / Elektro)',
    OTHER: 'Andere',
  },
  placeholder: {
    variant: 'Modellvariante z.B AMG (optional)',
  },
  carDetailButtons: {
    contact: 'KONTAKTIEREN',
    finance: 'FINANZIERUNG',
    print: 'DRUCKEN',
    onlineRequest: 'ONLINE-ANFRAGE',
    pdf: 'ALS PDF',
    park: 'PARKEN',
    equipment: 'AUSSTATTUNG',
  },
  financeRate: 'Monatsrate',
  gearboxFilterLabel: 'Getriebeart',
  gearboxes: {
    MANUAL: 'Schaltgetriebe',
    SEMI_AUTOMATIC: 'Halbautomatik',
    AUTOMATIC: 'Automatik',
  },
  mileageFilterLabel: 'Kilometer',
  cubicCapacityFilterLabel: 'Hubraum',
  locationFilterLabel: 'Standort',
  equipment: {
    trailerCoupling: 'Anhängerkupplung',
    parkingDistanceControlSystem: 'Einparkhilfe',
    noAc: 'Keine Klimaanlage',
    ac: 'Klimaanlage',
    automaticAc: 'Klimaautomatik',
    bluetooth: 'Bluetooth',
    bordcomputer: 'Bordcomputer',
    cdPlayer: 'CD-Player',
    electricWindows: 'Elektr. Fensterheber',
    electricExteriorMirrors: 'Elektr. Seitenspiegel',
    electricAdjustableSeats: 'Elektr. Sitzeinstellung',
    speakerphone: 'Freisprecheinrichtung',
    headUpDisplay: 'Head-up Display',
    mp3Interface: 'MP3-Schnittstelle',
    multiFunctionSteeringWheel: 'Multifunktionslenkrad',
    navigationSystem: 'Navigationssystem',
    rainSensor: 'Regensensor',
    sunroof: 'Schiebedach',
    powerSteering: 'Servo-Lenkung',
    centralLockingSystem: 'Zentralverriegelung',
    seatHeating: 'Sitzheizung',
    skiBag: 'Skisack',
    auxiliaryHeating: 'Standheizung',
    startStopSystem: 'Start/Stopp-Automatik',
    cruiseControl: 'Tempomat',
    tuner: 'Tuner/Radio',
    tractionControlSystem: 'Traktionskontrolle',
    abs: 'ABS',
    allWheelDrive: 'Allrad',
    immobiliser: 'Wegfahrsperre',
    frontFogLights: 'Nebelscheinwerfer',
    esp: 'ESP (el. Stabilitätsprogramm)',
    curveLights: 'Kurvenlicht',
    daytimeRunningLights: 'Tagfahrlicht',
    xenonHeadlights: 'Xenon-Scheinwerfer',
    lightSensor: 'Lichtsensor',
    crane: 'Kran',
    loadingBoard: 'Ladeboardwand',
    slidingDoor: 'Schiebetür',
    twinBerth: 'Doppelkabine',
    berth: 'Liegeplatz',
    particleFilter: 'Partikelfilter',
    partitionWall: 'Trennwand',
  },
  security: 'Sicherheit',
  ac: 'Klimatisierung',
  interior: 'Innenausstattung',
  qualitySeal: {
    youngStar: 'Junge Sterne',
  },
  qualitySealFilterLabel: 'Qualitätssiegel',
  colorFilterLabel: 'Farbe',
  color: {
    schwarz: 'Schwarz',
    weiss: 'Weiss',
    rot: 'Rot',
    blau: 'Blau',
    braun: 'Braun',
    gelb: 'Gelb',
    grau: 'Grau',
    silber: 'Silber',
    gruen: 'Grün',
    orange: 'Orange',
    violett: 'Violett',
    beige: 'Beige',
  },
  gwNumberFilterLabel: 'Fahrzeugnummer',
  sortPageLabel: 'Sortieren',
  sort: {
    asc: '{value} aufsteigend',
    desc: '{value} absteigend',
  },
  overview: 'Übersicht',
  brand: 'Marke',
  login: 'Für Mein S&G registrieren',
  register: 'Bei Mein S&G einloggen',
  park_without_login: 'Ohne Anmeldung parken',
  contact: {
    telephone: '+49 721 9565–355',
    telephoneGmbh: '+49 345 5218-975',
    mail: 'anfrage@sug.de',
  },
  yes: 'Ja',
  no: 'Nein',
  cityConsumption: 'Verbrauch innerorts',
  highwayConsumption: 'Verbrauch ausserorts',
  combinedConsumption: 'Verbrauch kombiniert',
  emissionComb: 'CO2-Emission kombiniert',
  energyEfficiencyClass: 'CO2-Effizienz',
  energyEfficiencyClassDesc: 'Auf Grundlage der gemessenen CO2-Emissionen unter Berücksichtigung der Masse des Fahrzeugs ermittelt.',
  carDetails: 'Fahrzeugdetails',
  usedCar: 'Gebraucht',
  newCar: 'Neuwagen',
  maintenanceGuide: 'Scheckheftgepflegt',
  firstHand: 'Aus erster Hand',
  rateCalculator: 'Mercedes-Benz Bank | Ratenrechner',
  customerType: 'Kundenart',
  calculationType: 'Finanzprodukt',
  totalMileage: 'Gesamtlaufleistung',
  periodMonths: 'Laufzeit',
  depositAmount: 'Anzahlung',
  pleaseChoose: 'Bitte wählen…',
  chooseLocation: 'Standort wählen…',
  parkModalHeader: 'Kennen Sie schon Mein S&G?',
  editFilter: 'Suche ändern',
  specialEq: 'Sonderausstattung',
  desktopDetailSearchTitle: 'S&G Gebrauchtwagensuche – Detailsuche',
  allBrands: 'Alle Marken',
  fuelType: 'Kraftstoffart',
  general: 'Allgemein',
  noValue: 'Nicht bekannt',
  vehicleTypes: {
    Cabrio: 'Cabrio/Roadster',
    SmallCar: 'Kleinwagen',
    EstateCar: 'Kombi',
    Limousine: 'Limousine',
    SportsCar: 'Sportwagen/Coupe',
    OffRoad: 'SUV/Geländewagen',
    Van: 'Van/Kleinbus',
    VanUpTo7500: 'Lkw bis 7,5 t',
    TruckOver7500: 'Lkw über 7,5 t',
    SemiTrailerTruck: 'Sattelzugmaschine',
    Trailer: 'Anhänger',
    SemiTrailer: 'Auflieger',
    ConstructionMachine: 'Baumaschine',
    UpTo7500_ThreeSidedTipper: 'Dreiseitenkipper',
    BoxTypeDeliveryVan: 'Kastenwagen',
    HighAndLongBoxTypeDeliveryVan: 'Kastenwagen hoch + lang',
    LongBoxTypeDeliveryVan: 'Kastenwagen lang',
    HighBoxTypeDeliveryVan: 'Kastenwagen hoch',
    TipperVan: 'Kipper',
    BoxVan: 'Koffer',
    StakeBodyVan: 'Pritsche',
    EstateMinibusUpTo9SeatsVan: 'Kombi, Kleinbus',
    StandardSZM: 'Standard-SZM',
  },
  similiarVehicles: 'Ähnliche Fahrzeuge',
  brutto: 'Brutto',
  netto: 'Netto',
  totalWeight: 'Zulässiges Gesamtgewicht',
  totalSeats: 'Anzahl Sitzplätze',
  totalWeightOptions: {
    '2.8T': 'Von 1 kg bis 2,8 t',
    '3.5T': 'Von 2,8 bis 3,5 t',
    '7.5T': 'Von 3,5 bis 7,5 t',
  },
  seats: 'Anzahl Sitzplätze',
  seatOptions: {
    two: '2',
    '3-6': '3 - 6',
    '>6': 'Mehr als 6',
  },
  co2EuroNorm: 'Schadstoffklasse',
  co2EuroNormOptions: {
    euro1: 'Euro1',
    euro2: 'Euro2',
    euro3: 'Euro3',
    euro4: 'Euro4',
    euro5: 'Euro5',
    euro6: 'Euro6',
  },
  carEnvBadge: 'Umweltplakette',
  carEnvBadgeOptions: {
    none: '1 (keine)',
    red: '2 (rot)',
    yellow: '3 (gelb)',
    green: '4 (grün)',
  },
  saveFilter: 'Suche speichern',
  save: 'Speichern',
  auFormHeadline: 'Preisüberprüfung in Zusammenarbeit mit',
  auCta: 'Inzahlungnahme',
  carInventory: 'Fahrzeugbestand',
  carInventoryValues: {
    companyCar: 'Geschäftswagen',
    oneYearOldCar: 'Jahreswagen',
    usedCar: 'Gebrauchtwagen',
  },
  driversCab: 'Fahrerhaus',
  view: 'Jetzt ansehen',
  navigationTitle: 'S&G Gebrauchtwagensuche – Treffer',
  title: 'S&G Gebrauchtwagensuche',
  contactPerson: 'Internet Vertriebsteam',
  contactDepartment: 'Verkauf Gebrauchtfahrzeuge',
  seller: 'Anbieter',

  equipmentCategory: {
    Allgemein: 'Allgemein',
    Exterieur: 'Exterieur',
    Schaltung: 'Schaltung',
    Media: 'Media',
    Interieur: 'Interieur',
    'Weitere Informationen': 'Weitere Informationen',
    Sicherheit: 'Sicherheit',
    Komfort: 'Komfort',
    Technik: 'Technik',
    Sonstiges: 'Sonstiges',
    Sportive: 'Sportive',
    Pakete: 'Pakete',
  },
  scoutServiceTitle: 'S&G ScoutService',
  scoutServiceText: 'Wir suchen für Sie den passenden Gebrauchtwagen, völlig kostenfrei, einfach anmelden und los gehts.',
  more: 'Mehr',
  nefzLegalText:
    '[4] Die angegebenen Werte wurden nach dem vorgeschriebenen Messverfahren ermittelt. Es handelt sich um die „NEFZ-CO2-Werte“ i.S.v. Art. 2 Nr. 1 Durchführungsverordnung (EU) 2017/1153. Die Kraftstoffverbrauchswerte wurden auf Basis dieser Werte errechnet. Der Stromverbrauch wurde auf der Grundlage der VO 692/2008/EG ermittelt. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug und sind nicht Bestandteil des Angebots, sondern dienen allein Vergleichszwecken zwischen verschiedenen Fahrzeugtypen. Die Werte variieren in Abhängigkeit der gewählten Sonderausstattungen.',
  wltpLegalText:
    '[5] Die angegebenen Werte wurden nach dem vorgeschriebenen Messverfahren ermittelt. Es handelt sich um „WLTP-CO₂-Werte“ i.S.v. Art. 2 Nr. 3 Durchführungsverordnung (EU) 2017/1153. Die Kraftstoffverbrauchswerte wurden auf Basis dieser Werte errechnet. Der Stromverbrauch wurde auf der Grundlage VO 683/2008/ EG ermittelt. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug und sind nicht Bestandteil des Angebots, sondern dienen allein Vergleichszwecken zwischen verschiedenen Fahrzeugtypen. Die Werte variieren in Abhängigkeit der gewählten Sonderausstattungen.',
  legalText:
    'Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO₂-Emissionen neuer Personenkraftwagen können dem „Leitfaden über den Kraftstoffverbrauch, die CO₂-Emissionen und den Stromverbrauch neuer Personenkraftwagen“ entnommen werden, der an allen Verkaufsstellen und bei der DAT Deutsche Fahrzeugmobil Treuhand GmbH (www.dat.de) unentgeltlich erhältlich ist.',
}
