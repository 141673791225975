export enum Color {
  '#000000' = 'schwarz',
  '#FFFFFF' = 'weiss',
  '#FF0000' = 'rot',
  '#00497d' = 'blau',
  '#7d4f22' = 'braun',
  '#f5d208' = 'gelb',
  '#AAAAAA' = 'grau',
  '#DDDDDD' = 'silber',
  '#00943e' = 'gruen',
  '#f29100' = 'orange',
  '#9361ff' = 'violett',
  '#dbb67f' = 'beige',
}
