import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalProps } from 'antd/lib/modal'

import { useFilterStore } from '../../hooks'
import { Button } from '../Button'
import { Modal } from '../Modal'

interface ResetFilterModalProps extends ModalProps {
  visible: boolean
  onModalClose: Function
}

const ResetFilterModal: React.FC<ResetFilterModalProps> = ({ visible, onModalClose }) => {
  const filterStore = useFilterStore()

  return (
    <Modal
      visible={visible}
      footer={[
        <Button key="back" onClick={() => onModalClose()}>
          <FormattedMessage id="cancel" />
        </Button>,

        <Button
          key="submit"
          type="primary"
          onClick={() => {
            filterStore.resetFilters()
            onModalClose()
          }}
        >
          <FormattedMessage id="reset_filters" />
        </Button>,
      ]}
      onCancel={() => onModalClose()}
    >
      <h2>
        <FormattedMessage id="reset_search" />
      </h2>
      <p>
        <FormattedMessage id="want_to_reset_search" />
      </p>
    </Modal>
  )
}

export { ResetFilterModal }
