import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { $enum } from 'ts-enum-util'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'
import { Icon } from '../'
import { Co2EuroNorm } from '../../types'

interface Co2EuroNormFilterLinkProps {}

const Co2EuroNormFilterLink: React.FC<Co2EuroNormFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const filter = filterStore.filters.co2EuroNorm
    return (
      <FilterView label="co2EuroNorm" link="/filter/euronorm" badge={filter ? <Icon type="check" /> : 0}>
        {filter ? (
          <div>
            <FormattedMessage id={`co2EuroNormOptions.${$enum(Co2EuroNorm).getKeyOrDefault(filter)}`} />
          </div>
        ) : null}
      </FilterView>
    )
  })
}

export { Co2EuroNormFilterLink }
