import React from 'react'

const Es: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.53 5.59">
      <path
        d="M.15,5.59H8.38a.15.15,0,0,0,.15-.15V.15A.15.15,0,0,0,8.38,0H.15A.15.15,0,0,0,0,.15H0V5.44a.15.15,0,0,0,.15.15Z"
        transform="translate(0)"
        fill="#c8414b"
      />
      <rect y="1.18" width="8.53" height="3.23" fill="#ffd250" />
      <path d="M3.6,2.79l.12-.56a.08.08,0,0,0-.06-.1h-.1a.08.08,0,0,0-.08.08v0Z" transform="translate(0)" fill="#c8414b" />
      <rect x="3.45" y="2.5" width="0.29" height="1.25" fill="#f5f5f5" />
      <rect x="3.38" y="2.35" width="0.44" height="0.15" fill="#fab446" />
      <rect x="3.09" y="2.79" width="0.73" height="0.15" fill="#c8414b" />
      <polygon points="3.82 3.38 3.38 3.23 3.38 3.09 3.82 3.23 3.82 3.38" fill="#c8414b" />
      <path d="M1.4,2.79l.12-.56a.08.08,0,0,0-.06-.1H1.35a.08.08,0,0,0-.08.08v0Z" transform="translate(0)" fill="#c8414b" />
      <path
        d="M1.91,2.35a.15.15,0,0,0-.15.15h0v1c0,.18.17.51.73.51s.73-.34.73-.51v-1a.15.15,0,0,0-.15-.15H1.91Z"
        transform="translate(0)"
        fill="#f5f5f5"
      />
      <path d="M2.5,3.09H1.76V2.5a.15.15,0,0,1,.15-.15H2.5Z" transform="translate(0)" fill="#c8414b" />
      <path d="M2.5,3.09h.73v.37a.38.38,0,0,1-.37.37h0a.38.38,0,0,1-.37-.37Z" transform="translate(0)" fill="#c8414b" />
      <path d="M1.76,3.09H2.5v.37a.38.38,0,0,1-.37.37h0a.38.38,0,0,1-.37-.37Z" transform="translate(0)" fill="#fab446" />
      <path d="M2.35,3.75V3.09H2.2v.73A.41.41,0,0,0,2.35,3.75Z" transform="translate(0)" fill="#c8414b" />
      <path d="M2.06,3.81V3.09H1.91v.66A.39.39,0,0,0,2.06,3.81Z" transform="translate(0)" fill="#c8414b" />
      <rect x="1.91" y="2.79" width="0.44" height="0.15" fill="#ffb441" />
      <rect x="1.91" y="2.5" width="0.44" height="0.15" fill="#fab446" />
      <rect x="1.98" y="2.59" width="0.29" height="0.27" fill="#fab446" />
      <rect x="1.25" y="2.5" width="0.29" height="1.25" fill="#f5f5f5" />
      <rect x="1.18" y="3.67" width="0.44" height="0.15" fill="#fab446" />
      <rect x="1.18" y="2.35" width="0.44" height="0.15" fill="#fab446" />
      <rect x="1.1" y="3.82" width="0.59" height="0.15" fill="#5064aa" />
      <rect x="3.45" y="3.67" width="0.44" height="0.15" fill="#fab446" />
      <rect x="3.31" y="3.82" width="0.59" height="0.15" fill="#5064aa" />
      <rect x="2.06" y="2.2" width="0.88" height="0.15" fill="#fab446" />
      <rect x="2.43" y="1.76" width="0.15" height="0.44" fill="#ffb441" />
      <path
        d="M2.35,2a.22.22,0,1,1,.22-.22A.22.22,0,0,1,2.35,2Zm0-.29a.07.07,0,1,0,.07.07h0a.07.07,0,0,0,0-.09h0Z"
        transform="translate(0)"
        fill="#f5f5f5"
      />
      <path
        d="M2.65,2a.22.22,0,1,1,.22-.22A.22.22,0,0,1,2.65,2Zm0-.29a.07.07,0,1,0,.07.07h0a.07.07,0,0,0-.05-.09h0Z"
        transform="translate(0)"
        fill="#f5f5f5"
      />
      <path
        d="M2.94,2.13a.22.22,0,1,1,.22-.22.22.22,0,0,1-.22.22Zm0-.29s-.07,0-.08.06a.07.07,0,1,0,.14,0H3A.07.07,0,0,0,2.94,1.84Z"
        transform="translate(0)"
        fill="#f5f5f5"
      />
      <path
        d="M2.06,2.13a.22.22,0,1,1,.22-.22.22.22,0,0,1-.22.22Zm0-.29a.07.07,0,1,0,.07.07h0a.07.07,0,0,0-.07-.07Z"
        transform="translate(0)"
        fill="#f5f5f5"
      />
      <path
        d="M2.94,3.38v.07a.08.08,0,0,1-.07.08.08.08,0,0,1-.08-.08h0V3.38h.15m.15-.15H2.65v.22a.22.22,0,0,0,.44,0Z"
        transform="translate(0)"
        fill="#fab446"
      />
      <path
        d="M2.87,2.94h0a.15.15,0,0,1-.15-.15h0V2.65a.15.15,0,0,1,.15-.15h0A.15.15,0,0,1,3,2.65V2.8A.15.15,0,0,1,2.87,2.94Z"
        transform="translate(0)"
        fill="#ffa0d2"
      />
      <circle cx="2.5" cy="3.09" r="0.22" fill="#5064aa" />
      <rect x="2.43" y="1.47" width="0.15" height="0.44" fill="#fab446" />
      <path d="M2.06,2.2l-.15-.15L2,2a.71.71,0,0,1,.5-.21h0A.71.71,0,0,1,3,2l.09.09-.15.15Z" transform="translate(0)" fill="#c8414b" />
      <circle cx="2.5" cy="2.06" r="0.07" fill="#ffd250" />
      <circle cx="2.2" cy="2.06" r="0.07" fill="#ffd250" />
      <circle cx="2.79" cy="2.06" r="0.07" fill="#ffd250" />
      <rect x="1.18" y="2.79" width="0.73" height="0.15" fill="#c8414b" />
      <polygon points="1.18 3.38 1.62 3.23 1.62 3.09 1.18 3.23 1.18 3.38" fill="#c8414b" />
    </svg>
  )
}

export { Es }
