import { useContext } from 'react'
import { RouterStore } from 'mobx-react-router'
import { MobXProviderContext } from 'mobx-react'

import { AppStore, FilterStore, CarListStore, CarStore, RateCalcStore } from '../stores'

export const useStores = (): {
  appStore: AppStore
  routerStore: RouterStore
  filterStore: FilterStore
  carListStore: CarListStore
  carStore: CarStore
  rateCalcStore: RateCalcStore
} => useContext(MobXProviderContext as any)

export const useAppStore = () => useStores().appStore

export const useFilterStore = () => useStores().filterStore

export const useCarListStore = () => useStores().carListStore

export const useRouterStore = () => useStores().routerStore

export const useCarStore = () => useStores().carStore

export const useRateCalcStore = () => useStores().rateCalcStore
