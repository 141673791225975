import React from 'react'

const No: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.53 5.59">
      <path
        d="M8.38,5.59H.15A.15.15,0,0,1,0,5.44H0V.15A.15.15,0,0,1,.15,0H8.38a.15.15,0,0,1,.15.15V5.44A.15.15,0,0,1,8.38,5.59Z"
        transform="translate(0 0)"
        fill="#ff4b55"
      />
      <polygon
        points="8.53 2.06 3.82 2.06 3.82 0 2.35 0 2.35 2.06 0 2.06 0 3.53 2.35 3.53 2.35 5.59 3.82 5.59 3.82 3.53 8.53 3.53 8.53 2.06"
        fill="#f5f5f5"
      />
      <polygon
        points="8.53 2.35 3.53 2.35 3.53 0 2.65 0 2.65 2.35 0 2.35 0 3.23 2.65 3.23 2.65 5.59 3.53 5.59 3.53 3.23 8.53 3.23 8.53 2.35"
        fill="#41479b"
      />
    </svg>
  )
}

export { No }
