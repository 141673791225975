import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface QualitysealFilterLinkProps {}

const QualitysealFilterLink: React.FC<QualitysealFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const qualitySeals = filterStore.getQualitysealFilterLabels
    return (
      <FilterView label="qualitySealFilterLabel" link="/filter/qualityseal" badge={qualitySeals.length}>
        {qualitySeals.length
          ? qualitySeals.map(seal => (
              <div key={seal}>
                <FormattedMessage id={`qualitySeal.${seal}`} />
              </div>
            ))
          : null}
      </FilterView>
    )
  })
}

export { QualitysealFilterLink }
