import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { RangeFilter } from '../../types'
import { CheckedButton, RangeFilterForm } from '../'
import { doesFilterMatch } from '../../helpers/rangeFilter'

const Wrapper = styled.div`
  color: #5d5d5d;
  padding: 25px;

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

const filter = [
  {
    label: <FormattedMessage id="filterLabel.from" values={{ from: <FormattedNumber value={100} />, symbol: '€' }} />,
    value: {
      gte: 100,
      lte: null,
    },
  },
  {
    label: <FormattedMessage id="filterLabel.to" values={{ to: <FormattedNumber value={500} />, symbol: '€' }} />,
    value: {
      gte: null,
      lte: 500,
    },
  },
  {
    label: (
      <FormattedMessage
        id="filterLabel.range"
        values={{
          from: <FormattedNumber value={500} />,
          to: <FormattedNumber value={1500} />,
          symbol: '€',
        }}
      />
    ),
    value: {
      gte: 500,
      lte: 1500,
    },
  },
]

interface FinanceFilterTabProps {}

const FinanceFilterTab: React.FC<FinanceFilterTabProps> = () => {
  const filterStore = useFilterStore()

  const financeFilterChange = (value: RangeFilter) => {
    filterStore.setFinancingFilter(value)
  }

  return useObserver(() => (
    <Wrapper>
      <RangeFilterForm onChange={financeFilterChange} values={filterStore.filters.financing} />
      <FormattedMessage id="popular" />
      {filter.map((filter, i) => (
        <CheckedButton
          onClick={() => financeFilterChange(filter.value)}
          label={filter.label}
          active={doesFilterMatch(filterStore.filters.financing, filter.value)}
          key={i}
        />
      ))}
    </Wrapper>
  ))
}

export { FinanceFilterTab }
