import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Col, Row } from 'antd'
import { Form, InputNumber, InputGroup, FormItem } from '../'
import { RangeFilter } from '../../types'

export interface RangeFilterFormProps extends FormComponentProps {
  onChange: (values: RangeFilter) => void
  formatNumber?: boolean
  unit?: string
  values?: RangeFilter
}

const RangeFilterFC = forwardRef<FormComponentProps, RangeFilterFormProps>(({ form, onChange, formatNumber, unit, values }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
    onChange,
  }))

  const intl = useIntl()

  const format = (value: any) => {
    if (value) {
      if (formatNumber) {
        value = intl.formatNumber(parseInt(value))
      }

      if (unit) {
        value = `${value} ${unit}`
      }
    }

    return value
  }

  const parse = (value: any) => {
    if (value) {
      const parsed = `${value}`.replace(/\D+/gm, '')
      return parsed ? Number(parsed) : null
    }
    return value
  }

  useEffect(() => {
    if (values) {
      const current = form.getFieldsValue()
      if (values.lte !== current.lte || values.gte !== current.gte) {
        form.setFieldsValue(values)
      }
    }
  })

  return (
    <>
      <Form>
        <InputGroup>
          <Row type="flex" gutter={12}>
            <Col xs={12}>
              <FormItem label={<FormattedMessage id="from" />}>
                {form.getFieldDecorator('gte')(<InputNumber formatter={format} parser={parse} />)}
              </FormItem>
            </Col>
            <Col xs={12}>
              <FormItem label={<FormattedMessage id="to" />}>
                {form.getFieldDecorator('lte')(<InputNumber formatter={format} parser={parse} />)}
              </FormItem>
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </>
  )
})

RangeFilterFC.defaultProps = {
  formatNumber: true,
}

const RangeFilterForm = Form.create<RangeFilterFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(RangeFilterFC)

export { RangeFilterForm }
