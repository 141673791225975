import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../hooks'
import { Select } from '../Select'

const prices = [
  500,
  1000,
  1500,
  2000,
  2500,
  3000,
  3500,
  4000,
  5000,
  6000,
  10000,
  11000,
  12000,
  13000,
  14000,
  15000,
  17500,
  20000,
  22500,
  25000,
  27500,
  30000,
  35000,
  40000,
  45000,
  50000,
  55000,
  60000,
  65000,
  70000,
  75000,
  80000,
  85000,
  90000,
]

const PriceUntilFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage, formatNumber } = useIntl()

  const options: OptionProps[] = prices.map((p) => ({ label: `${formatNumber(p)} €`, value: p }))
  options.unshift({ label: formatMessage({ id: 'filterAny' }), value: undefined })

  return useObserver(() => (
    <Select<number>
      style={{ width: '100%' }}
      onChange={(price: number) => (filterStore.filters.price.lte = price)}
      value={filterStore.filters.price.lte || undefined}
      placeholder={<FormattedMessage id="priceUntil" />}
      options={options}
    />
  ))
}

export { PriceUntilFilter }
