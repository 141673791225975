import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Breakpoint } from 'react-socks'
import { Select as AntSelect } from 'antd'

import { useFilterStore } from '../../hooks'
import { Icon, Select, Link } from '../../components'

const options = [
  { label: <FormattedMessage id="sort.asc" values={{ value: <FormattedMessage id="price" /> }} />, value: 'price' },
  { label: <FormattedMessage id="sort.desc" values={{ value: <FormattedMessage id="price" /> }} />, value: '-price' },
  { label: <FormattedMessage id="sort.asc" values={{ value: <FormattedMessage id="mileage" /> }} />, value: 'mileage' },
  { label: <FormattedMessage id="sort.desc" values={{ value: <FormattedMessage id="mileage" /> }} />, value: '-mileage' },
  { label: <FormattedMessage id="sort.asc" values={{ value: <FormattedMessage id="firstRegistration" /> }} />, value: 'firstRegistration' },
  {
    label: <FormattedMessage id="sort.desc" values={{ value: <FormattedMessage id="firstRegistration" /> }} />,
    value: '-firstRegistration',
  },
]

const { Option } = AntSelect

interface SortContainerProps {}

const SortContainer: React.FC<SortContainerProps> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const changeSort = (value: any) => {
    filterStore.setSort(value)
  }

  return useObserver(() => (
    <Wrapper>
      {/* desktop sort */}
      <Breakpoint large up>
        <Select
          value={filterStore.pagination.sort}
          placeholder={formatMessage({ id: 'pleaseChoose' })}
          onChange={(value: any) => changeSort(value)}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Breakpoint>
      {/* msobile sort */}
      <Breakpoint medium down>
        <Link to="/sort">
          <Icon type="sort" />
          <span>
            <FormattedMessage id="sortPageLabel" />
          </span>
        </Link>
      </Breakpoint>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  span {
    color: #5c5d5e;
    margin-left: 10px;
  }

  .ant-btn {
    background-color: transparent;
    border: none;
    padding: 0px;
  }

  .custom-icon {
    svg {
      fill: #5c5d5e;
      height: 15px;
      width: 15px;
    }
  }
`

export { SortContainer }
