import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { useFilterStore } from '../hooks'
import { LegacyCarFilter } from '../types'

const PresetFilterHandler: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const setYoungStarFilter = (v: string[]) => {
    filterStore.toggleQualitySeal('youngStar')
  }

  const setTypeFilter = (types: string[]) => {
    const type = types[0]
    if (type) {
      switch (type) {
        case 'nfz':
          filterStore.setUtilityVehicleFilter(true)
          break

        case 'kfz':
          filterStore.setUtilityVehicleFilter(false)
          break
      }
    }
  }

  const setGwNumber = (v: string[]) => {
    filterStore.changeGwNumberFilter(v[0])
  }

  const setMileageFilter = (v: string[]) => {
    const from = v[0] ? parseInt(v[0]) : null
    const to = v[1] ? parseInt(v[1]) : null

    filterStore.setMileageFilter({
      lte: from,
      gte: to,
    })
  }

  const setPriceFilter = (v: string[]) => {
    const from = v[0] ? parseInt(v[0]) : null
    const to = v[1] ? parseInt(v[1]) : null

    filterStore.setPriceFilter({
      lte: from,
      gte: to,
    })
  }

  const setLegacyCarFilter = (v: string[]) => {
    let filter: LegacyCarFilter = {}

    if (v.length) {
      if (v[0]) {
        filter = {
          ...filter,
          ...{
            brandId: parseInt(v[0]),
          },
        }
      }

      if (v[1]) {
        filter = {
          ...filter,
          ...{
            modelId: parseInt(v[1]),
          },
        }
      }

      filterStore.setLegacyCarFilter(filter)
    }
  }

  const setInventoryCarFilter = (v: any[]) => {
    console.log('setInventoryCarFilter', v)
    filterStore.setCarInventory(v[0], true)
  }

  const filterMap: Map<string[], (v: string[]) => void> = new Map([
    [['jungesternePre'], setYoungStarFilter],
    [['type'], setTypeFilter],
    [['interenummerPre'], setGwNumber],
    [['kmvonPre', 'kmbisPre'], setMileageFilter],
    [['preisvonPre', 'preisbisPre'], setPriceFilter],
    [['markePre', 'modellPre'], setLegacyCarFilter],
    [['inventoryPre'], setInventoryCarFilter],
  ])

  useEffect(() => {
    Array.from(filterMap.entries()).forEach(([key, processFn]) => {
      const values = key.map((k) => params.get(k)).filter((v) => !!v) as string[]

      if (values.length) {
        processFn(values)
      }
    })
  })

  return null
}

export { PresetFilterHandler }
