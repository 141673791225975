import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { reaction } from 'mobx'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'

import { useRouterStore, useFilterStore } from '../../hooks'
import { Icon } from '../../components'

interface DetailNewLinkProps {}

const isDetailPage = (pathname: string): boolean => {
  return pathname === '/detailsuche'
}

const DetailNewLink: React.FC<DetailNewLinkProps> = () => {
  const filterStore = useFilterStore()
  const routerStore = useRouterStore()
  const { pathname } = routerStore.location

  const [showNewSearch, setShowNewSearch] = useState(isDetailPage(pathname))

  useEffect(() => {
    const disposePathReaction = reaction(
      () => routerStore.location.pathname,
      (pathname) => {
        setShowNewSearch(isDetailPage(pathname))
      }
    )

    return () => {
      disposePathReaction()
    }
  })

  const navigate = (to: string) => {
    filterStore.resetFilters()
    routerStore.push(to)
  }

  return (
    <StyledButton onClick={() => navigate(!showNewSearch ? '/detailsuche' : '/')} type="link">
      <FormattedMessage id={!showNewSearch ? 'detailSearch' : 'new_search'} /> <Icon type="arrow-right" />
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: inherit;
  letter-spacing: 0.05em;

  .arrow-right {
    height: 15px;
    margin-left: 5px;
    fill: #bfbfbf;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export { DetailNewLink }
