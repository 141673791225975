import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface LocationFilterLinkProps {}

const LocationFilterLink: React.FC<LocationFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const locations = filterStore.filters.locations
    return (
      <FilterView label="locationFilterLabel" link="/filter/location" badge={locations.length}>
        {locations.length ? locations.map(location => <div key={location}>{location}</div>) : null}
      </FilterView>
    )
  })
}

export { LocationFilterLink }
