import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { Page, Header, RangeFilterForm, CheckedButton, Footer, NavigateToFilter } from '../../components'
import { useFilterStore } from '../../hooks'
import { RangeFilter } from '../../types'

const Wrapper = styled.div`
  padding: 25px;
  color: #5d5d5d;

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

const filters: any[] = []

interface CubicCapcaityFilterProps {}

const CubicCapacityFilterPage: React.FC<CubicCapcaityFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: RangeFilter) => {
    filterStore.setCubicCapacityFilter(value)
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="cubicCapacityFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <RangeFilterForm values={filterStore.filters.engine.cubicCapacity} onChange={onFilterChange} unit="cm³" />
        <FormattedMessage id="popular" />
        {filters.map((filter, i) => (
          <CheckedButton
            onClick={() => onFilterChange(filter.value)}
            label={filter.label}
            active={JSON.stringify(filterStore.filters.mileage) === JSON.stringify(filter.value)}
            key={i}
          />
        ))}
      </Wrapper>
    </Page>
  ))
}

export { CubicCapacityFilterPage }
