import styled from 'styled-components'
import { Typography } from 'antd'

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  &.ant-typography {
    font-family: 'CorporateA', serif;
    font-size: 25px;
    line-height: 30px;
    color: #fff;
    margin: 0;
    font-weight: 400;
  }
`

export { Title }
