import React from 'react'

const Power: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.52 135.5">
      <path
        d="M114.3,74.35a58.17,58.17,0,0,0,.41-6.6,43.08,43.08,0,0,0-.5-6.61L128.28,50a3.55,3.55,0,0,0,.83-4.33L115.79,22.19a3.36,3.36,0,0,0-4.08-1.52L95.15,27.49a48.47,48.47,0,0,0-11.24-6.61l-2.5-18A3.34,3.34,0,0,0,78.08,0H51.44a3.25,3.25,0,0,0-3.25,2.88l-2.5,18a50.59,50.59,0,0,0-11.24,6.61L17.88,20.72a3.28,3.28,0,0,0-4.08,1.52L.48,45.64A3.33,3.33,0,0,0,1.31,50L15.38,61.14a40.66,40.66,0,0,0-.08,13.21L1.23,85.49A3.53,3.53,0,0,0,.4,89.81l13.32,23.45a3.36,3.36,0,0,0,4.08,1.53L34.36,108a48.74,48.74,0,0,0,11.25,6.61l2.49,18a3.41,3.41,0,0,0,3.34,2.88H78.08a3.2,3.2,0,0,0,3.24-2.88l2.5-18A49.61,49.61,0,0,0,95.06,108l16.57,6.78a3.28,3.28,0,0,0,4.08-1.53L129,89.81a3.32,3.32,0,0,0-.83-4.32ZM64.76,93.15a25.41,25.41,0,0,1,0-50.81,25.41,25.41,0,0,1,0,50.81"
        transform="translate(0 0)"
        fill="#5d5e5f"
      />
    </svg>
  )
}

export { Power }
