import React from 'react'
import { useParams } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { Tree, TreeNode } from '../'
import { useFilterStore } from '../../hooks'
import { CarFilter, Model } from '../../types'
import { FormattedMessage } from 'react-intl'

interface SelectProps {
  onModelSelect: (car: CarFilter) => void
}

const ModelFilter: React.FC<SelectProps> = ({ onModelSelect, ...rest }) => {
  const { brand } = useParams()
  const filterStore = useFilterStore()

  const onSelect = (keys: string[], event: any) => {
    // first node is always empty
    if (brand) {
      if (event.node.props.pos === '0-0') {
        onModelSelect({ brand })
      } else {
        onModelSelect({ brand, model: keys[0] })
      }
    }
  }

  return useObserver(() => (
    <Wrapper>
      <Tree showIcon={false} defaultExpandAll={true} onSelect={onSelect}>
        <TreeNode title={<FormattedMessage id="filterAny" />}></TreeNode>
        {filterStore.getModelsByBrand(brand).map(brand => (
          <TreeNode title={brand._id} key={brand._id}>
            {brand.models?.map((model: Model) => (
              <TreeNode title={model._id} key={model._id} />
            ))}
          </TreeNode>
        ))}
      </Tree>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  ${TreeNode} {
    &:not(:last-child) {
      border-bottom: 1px solid #3f3f3f;
    }

    .ant-tree-switcher {
      visibility: hidden;
    }

    ul {
      border-top: 1px solid #3f3f3f;
    }
  }
`

export { ModelFilter }
