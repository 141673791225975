import React from 'react'
import { useObserver } from 'mobx-react'
import { useIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Row, Col, Radio } from 'antd'

import { RangeFilterFormDropdown } from '../../../'
import { useFilterStore } from '../../../../hooks'
import { RangeFilter, PriceType } from '../../../../types'

const values = [
  500,
  1000,
  1500,
  2000,
  2500,
  3000,
  3500,
  4000,
  4500,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
  11000,
  12000,
  13000,
  14000,
  15000,
  17500,
  20000,
  22500,
  25000,
  27500,
  30000,
  35000,
  40000,
  45000,
  50000,
  55000,
  60000,
  70000,
  80000,
  90000,
]

const PriceFilter: React.FC = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onFilterChange = (values: RangeFilter) => {
    filterStore.setPriceFilter(values)
  }

  const onTypeChange = (type: PriceType) => {
    filterStore.setPriceType(type)
  }

  return useObserver(() => {
    const price = filterStore.filters.price
    return (
      <Wrapper>
        <RangeFilterFormDropdown
          label={formatMessage({ id: 'price' })}
          unit={'€'}
          value={price}
          values={values}
          onChange={onFilterChange}
          formatValue
        />
        {filterStore.filters.utilityVehicle ? (
          <Row gutter={[12, 12]}>
            <Radio.Group value={filterStore.filters.priceType} onChange={(e: any) => onTypeChange(e.target.value)}>
              <Col span={12}>
                <Radio value={PriceType.BRUTTO}>
                  <FormattedMessage id="brutto" />
                </Radio>
              </Col>
              <Col span={12}>
                <Radio value={PriceType.NETTO}>
                  <FormattedMessage id="netto" />
                </Radio>
              </Col>
            </Radio.Group>
          </Row>
        ) : null}
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  .ant-radio {
    &-group {
      width: 100%;
    }

    &-checked::after {
      border: 1px solid #e0e0e0;
    }

    &-inner {
      &::after {
        background-color: #e0e0e0;
      }

      border-color: #545455;
      background-color: #1e1e1e;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border: 1px solid #e0e0e0 !important;
    }

    &-wrapper {
      color: #fff;
    }
  }
`

export { PriceFilter }
