import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import './components/AntdImport'

import { GlobalStyles } from './components/GlobalStyles'
import { SearchPage } from './pages/SearchPage'
import { ListPage } from './pages/ListPage'
import { DetailSearchPage } from './pages/DetailSearchPage'
import { FilterPage } from './pages/Filter'
import { SortPage } from './pages/Sort'
import FilterHandler from './components/FilterHandler/FilterHandler'
import CarHandler from './components/CarHandler/CarHandler'
import CarDetailStoreHandler from './components/CarDetail/CarDetailStoreHandler'
import { CarPage } from './pages/CarPage'
import { RateCalcHandler, SavedSearchHandler, PresetFilterHandler } from './components'

const App: React.FC<{}> = () => {
  return (
    <>
      <GlobalStyles />

      <FilterHandler />
      <CarHandler />
      <CarDetailStoreHandler />
      <RateCalcHandler />
      <SavedSearchHandler />
      <PresetFilterHandler />

      <Switch>
        <Route path="/" exact component={SearchPage} />
        <Route path="/suche" exact component={SearchPage} />
        <Route path="/detailsuche" component={DetailSearchPage} />
        <Route path="/treffer" component={ListPage} />
        <Route path="/filter" component={FilterPage} />
        <Route path="/sort" component={SortPage} />
        <Route path="/fahrzeug-detailansicht/:_id/:link" component={CarPage} />
        <Redirect to="/" />
      </Switch>
    </>
  )
}

export default App
