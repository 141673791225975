import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'antd'
import { map } from 'styled-components-breakpoint'

import { Button, LoginModal } from './'
import { useAppStore } from '../hooks'

const ScoutServiceBanner: React.FC<{}> = () => {
  const appStore = useAppStore()

  const more = () => {
    if (appStore.isLoggedIn) {
      window.open('https://www.sug.de/de/mein-sg/meine-suchen?tx_suggebrauchtwagen_suggebrauchtwagen%5Bcontroller%5D=Car', '_blank')
    } else {
      appStore.setLoginModalVisible(true)
    }
  }

  return (
    <Wrapper>
      <Row
        gutter={[
          { xs: 40, md: 40 },
          { xs: 40, md: 0 },
        ]}
        type="flex"
        justify="space-between"
        align="middle"
      >
        <Col xs={24} md={4}>
          <Image src="/react/gwsuche/img/scout_service.jpg" />
        </Col>
        <Col xs={24} lg={16}>
          <Title>
            <FormattedMessage id="scoutServiceTitle" />
          </Title>
          <ServiceText>
            <FormattedMessage id="scoutServiceText" />
          </ServiceText>
        </Col>

        <Col xs={24} md={4}>
          <StyledButton onClick={() => more()}>
            <FormattedMessage id="more" />
          </StyledButton>
        </Col>
      </Row>
      <LoginModal />
    </Wrapper>
  )
}

const Image = styled.img`
  width: 100%;
  height: auto;
`

const Wrapper = styled.div`
  background-color: #1e1e1e;

  ${map({ mobile: 'center', tablet: 'left' }, (textAlign) => `text-align: ${textAlign};`)}
`
const StyledButton = styled(Button as any)`
  min-width: 150px;
`

const Title = styled.h1`
  font-family: 'CorporateA', serif;
  font-size: 46px;
  color: #fff;
  margin: 0px;
  line-height: 46px;
`

const ServiceText = styled.span`
  font-size: 16px;
`

export { ScoutServiceBanner }
