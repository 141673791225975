import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { useCarStore } from '../../hooks'
import { Co2EffClass, Co2EffGraph } from '../'
import { Fuel } from '../../types'

const CarDetailConsumption: React.FC<{}> = () => {
  const carStore = useCarStore()

  return useObserver(() => (
    <Wrapper>
      {carStore.car?.emission ? (
        <Row type="flex" justify="space-between">
          <Col>
            <FormattedMessage id="emissionComb" /> NEFZ
            <sup>[4]</sup>
          </Col>
          <Col>
            <FormattedNumber value={carStore.car?.emission || 0} /> g/km
          </Col>
        </Row>
      ) : (
        ''
      )}

      {carStore.car?.wltp.emission ? (
        <Row type="flex" justify="space-between">
          <Col>
            <FormattedMessage id="emissionComb" /> WLTP
            <sup>[5]</sup>
          </Col>
          <Col>{carStore.car?.wltp.emission} g/km</Col>
        </Row>
      ) : (
        ''
      )}

      {carStore.car?.cityConsumption ? (
        <Row type="flex" justify="space-between">
          <Col>
            <FormattedMessage id="cityConsumption" /> NEFZ
            <sup>[4]</sup>
          </Col>
          <Col>
            <FormattedNumber value={carStore.car?.cityConsumption || 0} />{' '}
            {Fuel[carStore.car?.engine.fuel || 0] === 'ELECTRIC' ? <>kWh/100km</> : <>l/100km</>}
          </Col>
        </Row>
      ) : (
        ''
      )}

      {carStore.car?.consumptionOnTheHighway ? (
        <Row type="flex" justify="space-between">
          <Col>
            <FormattedMessage id="highwayConsumption" /> NEFZ
            <sup>[4]</sup>
          </Col>
          <Col>
            <FormattedNumber value={carStore.car?.consumptionOnTheHighway || 0} />{' '}
            {Fuel[carStore.car?.engine.fuel || 0] === 'ELECTRIC' ? <>kWh/100km</> : <>l/100km</>}
          </Col>
        </Row>
      ) : (
        ''
      )}

      {/** hide fule consumption if its pure electric */}
      {Fuel[carStore.car?.engine.fuel || 0] !== 'ELECTRIC' ? (
        <>
          {carStore.car?.fuelConsumption ? (
            <Row type="flex" justify="space-between">
              <Col>
                <FormattedMessage id="combinedConsumption" /> NEFZ
                <sup>[4]</sup>
              </Col>
              <Col>
                <FormattedNumber value={carStore.car?.fuelConsumption || 0} /> l/100km
              </Col>
            </Row>
          ) : (
            ''
          )}

          {carStore.car?.wltp.combined ? (
            <Row type="flex" justify="space-between">
              <Col>
                <FormattedMessage id="combinedConsumption" /> WLTP
                <sup>[5]</sup>
              </Col>
              <Col>{carStore.car?.wltp.combined} l/100km</Col>
            </Row>
          ) : (
            ''
          )}
        </>
      ) : null}

      {/** show power consumption if its electric or hybrid */}
      {['ELECTRIC', 'HYBRID_DIESEL', 'HYBRID_PETROL'].includes(Fuel[carStore.car?.engine.fuel || 0]) ? (
        <>
          {carStore.car?.combinedPowerConsumption ? (
            <Row type="flex" justify="space-between">
              <Col>
                <FormattedMessage id="powerConsumption" /> NEFZ
                <sup>[4]</sup>
              </Col>
              <Col>
                <FormattedNumber value={carStore.car?.combinedPowerConsumption || 0} /> kWh/100km
              </Col>
            </Row>
          ) : (
            ''
          )}
          {carStore.car?.wltp.combinedPowerConsumption ? (
            <Row type="flex" justify="space-between">
              <Col>
                <FormattedMessage id="powerConsumption" /> WLTP
                <sup>[5]</sup>
              </Col>
              <Col>{carStore.car?.wltp.combinedPowerConsumption} kWh/100km</Col>
            </Row>
          ) : (
            ''
          )}
        </>
      ) : null}

      <Row type="flex" justify="space-between" className="co2EffClass">
        <Col>
          <FormattedMessage id="energyEfficiencyClass" />
          <sup>[4]</sup>
        </Col>
        <Col>
          <Co2EffClass type={carStore.car?.energyEfficiencyClass} />
        </Col>
      </Row>

      {carStore.car?.energyEfficiencyClass && (
        <Row type="flex">
          <div className="co2EffGraphContainer">
            <h4>
              <FormattedMessage id="energyEfficiencyClass" />
              <sup>[4]</sup>
            </h4>
            <span>
              <FormattedMessage id="energyEfficiencyClassDesc" />
            </span>
            <Co2EffGraph type={carStore.car?.energyEfficiencyClass} />
          </div>
        </Row>
      )}
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  .ant-row-flex {
    padding-top: 15px;
    padding-bottom: 15px;

    .co2EffGraphContainer {
      margin-top: 35px;

      > span {
        color: #5d5e5f;
      }

      h4 {
        color: #fff;
        margin-bottom: 20px;
      }

      .co2EffGraph {
        margin-top: 20px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #383838;
    }
  }

  .co2EffClass {
    padding-bottom: 0px;
    border-bottom: none !important;
  }
`

export { CarDetailConsumption }
