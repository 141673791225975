import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useObserver } from 'mobx-react'
import { Select as AntSelect } from 'antd'

import { useFilterStore } from '../../hooks'
import { Select } from '../Select'

const ModelFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()

  const { OptGroup, Option } = AntSelect

  return useObserver(() => {
    const handleChange = (model: string) => {
      filterStore.filters.model = model
      filterStore.filters.cars[0] = { ...filterStore.filters.cars[0], ...{ model } }
    }

    const models = filterStore.getModelsByBrand(filterStore.filters.brand)

    return (
      <Select<string>
        style={{ width: '100%' }}
        onChange={(value: any) => handleChange(value)}
        value={filterStore.filters.model || undefined}
        placeholder={<FormattedMessage id="modelLabel" />}
        disabled={!filterStore.filters.brand || !models}
      >
        <Option value={undefined} key="any">
          <FormattedMessage id="filterAny" />
        </Option>

        {models &&
          models.map((modelGroup) => (
            <OptGroup label={modelGroup._id} key={modelGroup._id}>
              <Option value={modelGroup._id} key={modelGroup._id}>
                {modelGroup._id} (<FormattedMessage id="filterAny" />)
              </Option>

              {modelGroup.models &&
                modelGroup.models.map((model) => (
                  <Option value={model._id} key={model._id}>
                    {model._id}
                  </Option>
                ))}
            </OptGroup>
          ))}
      </Select>
    )
  })
}

export { ModelFilter }
