import { observable, action, computed, toJS } from 'mobx'
import { computedFn } from 'mobx-utils'
import uuid from 'uuid/v4'
import deepmerge from 'deepmerge'

import {
  Brand,
  Location,
  Filter,
  CarFilter,
  Pagination,
  PowerType,
  RangeFilter,
  Fuel,
  Gearbox,
  GeneralEquipment,
  SecurityEquipment,
  AcEquipment,
  InteriorEquipment,
  Color,
  FirstRegistrationRange,
  PriceType,
  TotalWeight,
  Seats,
  Co2EuroNorm,
  CarEnvBadge,
  TotalWeightValues,
  SeatValues,
  GeneralEquipmentUtilityVehicle,
  DriversCabEquipmentUtilityVehicle,
  SecurityEquipmentUtilityVehicle,
  InteriorEquipmentUtilityVehicle,
  CarInventory,
  QualitySeal,
  LegacyCarFilter,
} from '../types'

import { cleanRangeFilter } from '../helpers/rangeFilter'
import { getRangeFilterByValue } from '../helpers/map'

export const INITIAL_FILTERS: Filter = {
  vehicleInventory: {
    oneYearOldCar: false,
    companyCar: false,
    usedCar: false,
  },
  utilityVehicle: false,
  brand: null,
  model: null,
  location: null,
  cars: [],
  legacyCarFilter: [],
  categories: [],
  locations: [],
  equipment: {
    general: [],
    interior: [],
    ac: [],
    security: [],
    driversCab: [],
  },
  qualitySeal: {
    youngStar: false,
  },
  price: {
    lte: undefined,
    gte: undefined,
  },
  isVatReportable: undefined,
  totalWeight: undefined,
  seats: undefined,
  co2EuroNorm: undefined,
  carEnvBadge: undefined,
  priceType: PriceType.NETTO,
  power: {
    lte: undefined,
    gte: undefined,
  },
  powerType: PowerType.PS,
  engine: {
    fuels: [],
    gearboxes: [],
    cubicCapacity: {
      lte: undefined,
      gte: undefined,
    },
  },
  firstRegistration: {
    lte: undefined,
    gte: undefined,
  },
  financing: {
    lte: undefined,
    gte: undefined,
  },
  mileage: {
    lte: undefined,
    gte: undefined,
  },
  colors: [],
  isMetallicColor: false,
  gwNumber: '',
}

export interface Init {
  location: string | null
  brand: string | null
  inventory: string | null
}

export class FilterStore {
  constructor(filter?: Init) {
    if (filter?.location) {
      this.filters.locations.push(filter.location)
    }

    if (filter?.brand) {
      this.filters.cars.push({
        brand: filter.brand,
        model: undefined,
        variant: undefined,
        uuid: uuid(),
      })
    }

    if (filter?.inventory) {
      this.filters.vehicleInventory = {
        companyCar: filter.inventory === 'companyCar',
        oneYearOldCar: filter.inventory === 'oneYearOldCar',
        usedCar: filter.inventory === 'usedCar',
      }
    }
  }

  @observable loading = true

  @observable pagination: Pagination = {
    page: 1,
    limit: 20,
    sort: 'price',
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading
  }

  @observable filters: Filter = INITIAL_FILTERS

  /**
   * Um die Möglichkeit zu haben die Filter etwas anders zu übergeben wie man sie speichert
   */
  @computed get filtersForCarsQuery() {
    const filter: any = {
      utilityVehicle: this.filters.utilityVehicle,
    }

    const { vehicleInventory } = this.filters
    filter['vehicleInventory'] = {}
    if (vehicleInventory.companyCar) {
      filter['vehicleInventory']['companyCar'] = true
    }

    if (vehicleInventory.usedCar) {
      filter['vehicleInventory']['usedCar'] = true
    }

    if (vehicleInventory.oneYearOldCar) {
      filter['vehicleInventory']['oneYearOldCar'] = true
    }

    // append gwnumber
    if (this.filters.gwNumber) {
      filter['_id'] = this.filters.gwNumber
    }

    // append cars
    if (this.getCarsForQuery.length) {
      filter['cars'] = this.getCarsForQuery
    }

    // legacy car filter
    if (this.filters.legacyCarFilter.length) {
      filter['cars'] = this.filters.legacyCarFilter
    }

    // append categories
    if (this.filters.categories.length) {
      filter['categories'] = this.filters.categories
    }

    // append locations
    if (this.filters.locations.length) {
      filter['locations'] = this.filters.locations
    }

    // append equipment
    if (this.getEquipmentFilter.length) {
      filter['equipment'] = this.getEquipmentFilter
    }

    // append qualityseal
    if (this.filters.qualitySeal.youngStar) {
      filter['qualitySeal'] = {
        youngStar: true,
      }
    }

    // append price
    const price = cleanRangeFilter(this.filters.price)
    if (price) {
      if (this.filters.priceType === PriceType.NETTO) {
        filter['price'] = price
      } else if (this.filters.priceType === PriceType.BRUTTO) {
        filter['priceWithoutTax'] = price
      }
    }

    // append power filter
    const power = cleanRangeFilter(this.filters.power)
    if (power) {
      filter['power'] = this.filters.power
    }

    // append first registration filter
    const firstRegistration = cleanRangeFilter(this.filters.firstRegistration)
    if (firstRegistration) {
      filter['firstRegistration'] = firstRegistration
    }

    // append financing filter
    const financing = cleanRangeFilter(this.filters.financing)
    if (financing) {
      filter['financing'] = financing
    }

    const mileage = cleanRangeFilter(this.filters.mileage)
    if (mileage) {
      filter['mileage'] = mileage
    }

    if (this.filters.colors.length) {
      filter['colors'] = this.filters.colors
    }

    if (this.filters.carEnvBadge) {
      filter['environmentalBadge'] = this.filters.carEnvBadge
    }

    if (this.filters.co2EuroNorm) {
      filter['emissionClass'] = this.filters.co2EuroNorm
    }

    if (this.filters.seats) {
      filter['seats'] = this.filters.seats
    }

    if (this.filters.totalWeight) {
      filter['maximumWeightAllowed'] = this.filters.totalWeight
    }

    const { engine } = this.filters
    if (engine.fuels.length || engine.gearboxes.length || cleanRangeFilter(engine.cubicCapacity)) {
      const tmp: any = {}

      if (engine.fuels.length) {
        tmp['fuels'] = engine.fuels
      }

      if (engine.gearboxes.length) {
        tmp['gearboxes'] = engine.gearboxes
      }

      const cc = cleanRangeFilter(engine.cubicCapacity)
      if (cc) {
        tmp['cubicCapacity'] = cc
      }

      filter['engine'] = tmp
    }

    if (this.filters.isVatReportable !== undefined) {
      filter['vatReportable'] = this.filters.isVatReportable
    }

    // filters with pagination
    return {
      filter,
      pagination: this.pagination,
    }
  }

  @action
  setUtilityVehicleFilter(v: boolean): void {
    this.filters = {
      ...INITIAL_FILTERS,
      ...{
        utilityVehicle: v,
      },
    }
  }

  /**
   * returns flag if any filters are set
   *
   * @readonly
   * @type {boolean}
   * @memberof FilterStore
   */
  @computed get areFiltersSet(): boolean {
    const { utilityVehicle, vehicleInventory, ...filter } = this.filtersForCarsQuery.filter

    return !!Object.keys(filter).length
  }

  @action
  changePage(page: number) {
    this.pagination.page = page
  }

  /**
   * sets the sorting
   *
   * @param {string} sort
   * @memberof FilterStore
   */
  @action
  setSort(sort: string) {
    this.pagination.sort = sort
  }

  /**
   * re
   *
   * @memberof FilterStore
   */
  @action
  resetFilters() {
    const utilityVehicle = this.filters.utilityVehicle;

    this.filters = { ...INITIAL_FILTERS, ...{ utilityVehicle } }
  }

  /**
   * Marken / Modelle
   */
  @observable brands: Brand[] = []

  @action
  setBrands(brands: Brand[]) {
    this.brands = brands
  }

  getModelsByBrand = computedFn(function getModelsByBrand(this: FilterStore, selectedBrand?: string) {
    const brand: Brand[] = this.brands.filter((brand: { _id: String }) => brand._id === selectedBrand)
    if (brand.length && brand[0].models?.length) {
      return brand[0].models
    }
    return []
  })

  @computed get getGroupedBrands() {
    const brands = this.brands
    const grouped: any = {}

    brands.forEach((brand) => {
      const letter = brand._id[0].toUpperCase()
      if (grouped.hasOwnProperty(letter)) {
        grouped[letter].push(brand)
      } else {
        grouped[letter] = []
        grouped[letter].push(brand)
      }
    })

    return grouped
  }

  /**
   * holds the first registration range
   *
   * @type {FirstRegistrationRange}
   * @memberof FilterStore
   */
  @observable firstRegistrationRange: FirstRegistrationRange = {
    min: 0,
    max: 0,
  }

  /**
   * sets the first registration range
   *
   * @param {FirstRegistrationRange} range
   * @memberof FilterStore
   */
  @action
  setFirstRegistrationRange(range: FirstRegistrationRange): void {
    this.firstRegistrationRange = range
  }

  /**
   * Standorte
   */
  @observable locations: string[] = []

  @action
  setLocations(locations: Location[]) {
    this.locations = locations.map((location: Location) => location._id)
  }

  /**
   * adds a car
   *
   * @param {CarFilter} car
   * @memberof FilterStore
   */
  @action
  addCar(car: CarFilter) {
    this.filters.cars.push({ ...car, uuid: uuid() })
  }

  /**
   * deletes a car
   *
   * @param {CarFilter} car
   * @memberof FilterStore
   */
  @action
  removeCar(car: CarFilter) {
    this.filters.cars = this.filters.cars.filter((c) => c.uuid !== car.uuid)
  }

  @action
  updateCar(car: CarFilter) {
    this.filters.cars = this.filters.cars.map((c) => (c.uuid === car.uuid ? car : c))
  }

  /**
   * sets the variant of a car
   *
   * @param {string} variant
   * @memberof FilterStore
   */
  @action
  setVariant(car: CarFilter, variant: string) {
    this.filters.cars = this.filters.cars.map((c) => {
      if (JSON.stringify(c) === JSON.stringify(car)) {
        c.variant = variant
      }
      return c
    })
  }

  @computed get getCarFiltersAsLabels() {
    return Array.from(this.filters.cars).map((car) => {
      return `${car.brand ? car.brand : ''} ${car.model ? car.model : ''} ${car.variant ? car.variant : ''}`
    })
  }

  @action
  setPriceFilter(price: RangeFilter) {
    this.filters.price = {
      ...this.filters.price,
      ...price,
    }
  }

  @computed get getPriceFilter() {
    return this.filters.price
  }

  @action
  setPowerFilterType(type: PowerType) {
    this.filters.powerType = type
  }

  @action
  setPowerFilter(power: RangeFilter) {
    this.filters.power = {
      ...this.filters.power,
      ...power,
    }
  }

  /**
   * adds or removes a fuel type from filter
   *
   * @param {Fuel} fuel
   * @memberof FilterStore
   */
  @action
  addOrRemoveFuel(fuel: Fuel) {
    if (!this.filters.engine.fuels.includes(fuel)) {
      this.filters.engine.fuels.push(fuel)
    } else {
      this.filters.engine.fuels = this.filters.engine.fuels.filter((f) => f !== fuel)
    }
  }

  @computed get getFuelFilterAsLabels() {
    return this.filters.engine.fuels.map((f) => {
      return `${Fuel[f]}`
    })
  }

  /**
   * sets the first registration filter
   *
   * @param {RangeFilter} filter
   * @memberof FilterStore
   */
  @action
  setFirstRegistrationFilter(filter: RangeFilter) {
    this.filters.firstRegistration = {
      ...this.filters.firstRegistration,
      ...filter,
    }
  }

  /**
   * sets the financing filter
   *
   * @param {RangeFilter} filter
   * @memberof FilterStore
   */
  @action
  setFinancingFilter(filter: RangeFilter) {
    this.filters.financing = {
      ...this.filters.financing,
      ...filter,
    }
  }

  /**
   * reests the financing filter
   *
   * @memberof FilterStore
   */
  @action
  clearFinancingFilter() {
    this.filters.financing = {
      lte: null,
      gte: null,
    }
  }

  /**
   * resets the price filter
   *
   * @memberof FilterStore
   */
  @action
  clearPriceFilter() {
    this.filters.price = {
      lte: null,
      gte: null,
    }
  }

  /**
   * adds or removes a gearbox type
   *
   * @param {Gearbox} gearbox
   * @memberof FilterStore
   */
  @action
  addOrRemoveGearbox(gearbox: Gearbox) {
    if (!this.filters.engine.gearboxes.includes(gearbox)) {
      this.filters.engine.gearboxes.push(gearbox)
    } else {
      this.filters.engine.gearboxes = this.filters.engine.gearboxes.filter((f) => f !== gearbox)
    }
  }

  /**
   * returns the gearbox filter as labels
   *
   * @returns {string[]}
   * @memberof FilterStore
   */
  @computed get getGearboxFilterAsLabels(): string[] {
    return this.filters.engine.gearboxes.map((g) => {
      return `${Gearbox[g]}`
    })
  }

  /**
   * sets the mileage filter
   *
   * @param {RangeFilter} filter
   * @memberof FilterStore
   */
  @action
  setMileageFilter(filter: RangeFilter) {
    this.filters.mileage = {
      ...this.filters.mileage,
      ...filter,
    }
  }

  /**
   * sets the ccm filter
   *
   * @param {RangeFilter} filter
   * @memberof FilterStore
   */
  @action
  setCubicCapacityFilter(filter: RangeFilter) {
    this.filters.engine.cubicCapacity = {
      ...this.filters.engine.cubicCapacity,
      ...filter,
    }
  }

  /**
   * adds a location
   *
   * @param {string} location
   * @memberof FilterStore
   */
  @action
  addLocation(location: string) {
    if (!this.filters.locations.includes(location)) {
      this.filters.locations.push(location)
    }
  }

  /**
   * removes a location
   *
   * @param {string} location
   * @memberof FilterStore
   */
  @action
  removeLocation(location: string) {
    this.filters.locations = this.filters.locations.filter((loc) => loc !== location)
  }

  /**
   * adds or removes a location
   *
   * @param {string} location
   * @memberof FilterStore
   */
  @action
  addOrRemoveLocation(location: string) {
    if (!this.filters.locations.includes(location)) {
      this.addLocation(location)
    } else {
      this.removeLocation(location)
    }
  }

  /**
   * adds a general equipment
   *
   * @param {GeneralEquipment} eq
   * @memberof FilterStore
   */
  @action
  addGeneralEquipment(eq: GeneralEquipment | GeneralEquipmentUtilityVehicle) {
    if (!this.filters.equipment.general.includes(eq)) {
      this.filters.equipment.general.push(eq)
    }
  }

  /**
   * removes a general equipment
   *
   * @param {GeneralEquipment} eq
   * @memberof FilterStore
   */
  @action
  removeGeneralEquipment(eq: GeneralEquipment | GeneralEquipmentUtilityVehicle) {
    this.filters.equipment.general = this.filters.equipment.general.filter((e) => e !== eq)
  }

  /**
   * adds or removes drivers cab eq
   *
   * @param {DriversCabEquipmentUtilityVehicle} eq
   * @memberof FilterStore
   */
  @action
  addOrRemoveDriversCabEq(eq: DriversCabEquipmentUtilityVehicle) {
    if (!this.filters.equipment.driversCab.includes(eq)) {
      this.addDriversCabEq(eq)
    } else {
      this.removeDriversCabEq(eq)
    }
  }

  /**
   * adds drivers cab eq
   *
   * @param {DriversCabEquipmentUtilityVehicle} eq
   * @memberof FilterStore
   */
  @action
  addDriversCabEq(eq: DriversCabEquipmentUtilityVehicle) {
    if (!this.filters.equipment.driversCab.includes(eq)) {
      this.filters.equipment.driversCab.push(eq)
    }
  }

  /**
   * removes drivers cab eq
   *
   * @param {DriversCabEquipmentUtilityVehicle} eq
   * @memberof FilterStore
   */
  @action
  removeDriversCabEq(eq: DriversCabEquipmentUtilityVehicle) {
    this.filters.equipment.driversCab = this.filters.equipment.driversCab.filter((e) => e !== eq)
  }

  /**
   * adds or removes a general equipment
   *
   * @param {GeneralEquipment} eq
   * @memberof FilterStore
   */
  @action
  addOrRemoveGeneralEquipment(eq: GeneralEquipment | GeneralEquipmentUtilityVehicle) {
    if (!this.filters.equipment.general.includes(eq)) {
      this.addGeneralEquipment(eq)
    } else {
      this.removeGeneralEquipment(eq)
    }
  }

  /**
   * adds or remove security equipment
   *
   * @param {SecurityEquipment} eq
   * @memberof FilterStore
   */
  @action
  addOrRemoveSecurityEquipment(eq: SecurityEquipment | SecurityEquipmentUtilityVehicle) {
    if (!this.filters.equipment.security.includes(eq)) {
      this.filters.equipment.security.push(eq)
    } else {
      this.filters.equipment.security = this.filters.equipment.security.filter((e) => e !== eq)
    }
  }

  /**
   * adds or removes ac equipment
   *
   * @param {AcEquipment} eq
   * @memberof FilterStore
   */
  @action
  addOrRemoveAcEquipment(eq: AcEquipment) {
    if (!this.filters.equipment.ac.includes(eq)) {
      this.filters.equipment.ac.push(eq)
    } else {
      this.filters.equipment.ac = this.filters.equipment.ac.filter((e) => e !== eq)
    }
  }

  /**
   * adds or removes interior equipment
   *
   * @param {InteriorEquipment} eq
   * @memberof FilterStore
   */
  @action
  addOrRemoveInteriorEquipment(eq: InteriorEquipment | InteriorEquipmentUtilityVehicle) {
    if (!this.filters.equipment.interior.includes(eq)) {
      this.filters.equipment.interior.push(eq)
    } else {
      this.filters.equipment.interior = this.filters.equipment.interior.filter((e) => e !== eq)
    }
  }

  /**
   * returns all selected equipment labels
   *
   * @returns {string[]}
   * @memberof FilterStore
   */
  @computed get getEquipmentFilter(): string[] {
    return (this.filters.equipment.general as string[])
      .concat(this.filters.equipment.ac as string[])
      .concat(this.filters.equipment.interior as string[])
      .concat(this.filters.equipment.security as string[])
      .concat(this.filters.equipment.driversCab as string[])
  }

  /**
   * toggles a quality seal
   *
   * @param {string} index
   * @memberof FilterStore
   */
  @action
  toggleQualitySeal<K extends keyof QualitySeal>(index: K) {
    this.filters.qualitySeal[index] = !this.filters.qualitySeal[index]
  }

  /**
   * returns all enabled quality seal labels
   *
   * @returns {string[]}
   * @memberof FilterStore
   */
  @computed get getQualitysealFilterLabels(): string[] {
    const qualitySeals: string[] = []

    Object.keys(this.filters.qualitySeal).forEach((key: string) => {
      if (this.filters.qualitySeal[key as keyof QualitySeal]) {
        qualitySeals.push(key)
      }
    })

    return qualitySeals
  }

  /**
   * adds a color
   *
   * @param {Color} color
   * @memberof FilterStore
   */
  @action
  addColor(color: Color) {
    if (!this.filters.colors.includes(color)) {
      this.filters.colors.push(color)
    }
  }

  /**
   * removes a color
   *
   * @param {Color} color
   * @memberof FilterStore
   */
  @action
  removeColor(color: Color) {
    this.filters.colors = this.filters.colors.filter((c) => c !== color)
  }

  /**
   * adds or removes a color
   *
   * @param {Color} color
   * @memberof FilterStore
   */
  @action
  addOrRemoveColor(color: Color) {
    if (!this.filters.colors.includes(color)) {
      this.addColor(color)
    } else {
      this.removeColor(color)
    }
  }

  /**
   * changes the gw number filter
   *
   * @param {string} value
   * @memberof FilterStore
   */
  @action
  changeGwNumberFilter(value: string) {
    this.filters.gwNumber = value
  }

  /**
   * sets the vehicle categories
   *
   * @param {string[]} categories
   * @memberof FilterStore
   */
  @action
  setVehicleCategories(categories: string[]) {
    this.filters.categories = categories
  }

  /**
   * adds or removes a vehicle category
   *
   * @param {string} category
   * @memberof FilterStore
   */
  @action
  addOrRemoveVehicleCategory(category: string) {
    if (!this.filters.categories.includes(category)) {
      this.filters.categories.push(category)
    } else {
      this.filters.categories = this.filters.categories.filter((e) => e !== category)
    }
  }

  /**
   * sets the is vat reportable filter
   *
   * @param {(boolean | undefined)} vat
   * @memberof FilterStore
   */
  @action
  setVatReportableFilter(vat: boolean | undefined) {
    this.filters.isVatReportable = vat
  }

  /**
   * sets the price type
   *
   * @param {PriceType} type
   * @memberof FilterStore
   */
  @action
  setPriceType(type: PriceType) {
    this.filters.priceType = type
  }

  /**
   * sets the total weight of the vehicle
   *
   * @param {(TotalWeight | undefined)} weight
   * @memberof FilterStore
   */
  @action
  setTotalWeight(weight: TotalWeight | undefined) {
    this.filters.totalWeight = weight ? TotalWeightValues.get(weight) : undefined
  }

  /**
   * returns the proper total weight option
   *
   * @readonly
   * @type {(TotalWeight | undefined)}
   * @memberof FilterStore
   */
  @computed get getTotalWeight(): TotalWeight | undefined {
    return this.filters.totalWeight ? getRangeFilterByValue(TotalWeightValues, this.filters.totalWeight) : undefined
  }

  /**
   * sets the seat filter
   *
   * @param {(Seats | undefined)} seats
   * @memberof FilterStore
   */
  @action
  setSeats(seats: Seats | undefined) {
    this.filters.seats = seats ? SeatValues.get(seats) : undefined
  }

  @computed get getSeats(): Seats | undefined {
    return this.filters.seats ? getRangeFilterByValue(SeatValues, this.filters.seats) : undefined
  }

  /**
   * sets the co2 euro norm filter
   *
   * @param {(Co2EuroNorm | undefined)} norm
   * @memberof FilterStore
   */
  @action
  setCo2EuroNorm(norm: Co2EuroNorm | undefined) {
    this.filters.co2EuroNorm = norm
  }

  /**
   * sets the car environmental badge filter
   *
   * @param {(CarEnvBadge | undefined)} badge
   * @memberof FilterStore
   */
  @action
  setCarEnvBadge(badge: CarEnvBadge | undefined) {
    this.filters.carEnvBadge = badge
  }

  @action
  setCarInventory<K extends keyof CarInventory>(key: K, value: boolean) {
    this.filters.vehicleInventory = {
      ...{
        companyCar: false,
        oneYearOldCar: false,
        usedCar: false,
      },
      ...{
        [key]: value,
      },
    }
  }

  /**
   * merges the given filter
   *
   * @param {Filter} filter
   * @memberof FilterStore
   */
  @action
  mergeFilter(filter: Object) {
    this.filters = deepmerge.all([INITIAL_FILTERS, filter]) as Filter
  }

  @computed get getCarsForQuery(): CarFilter[] {
    const cars: CarFilter[] = []

    this.filters.cars.forEach((car) => {
      const { uuid, ...c } = car
      if (c.brand) {
        cars.push(c)
      }
    })

    return cars
  }

  @action setLegacyCarFilter(filter: LegacyCarFilter): void {
    this.filters.legacyCarFilter.push(filter)
    console.log(toJS(this.filters.legacyCarFilter))
  }

  @action unsetLegacyCarFilter(): void {
    this.filters.legacyCarFilter = []
  }
}
