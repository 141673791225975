import React from 'react'
import styled from 'styled-components'

import { Co2EfficiencyClass } from '../../types'
import { Co2EffClass } from '../'

interface Co2EffGraphProps {
  type?: Co2EfficiencyClass
}

const co2Classes: Co2EfficiencyClass[] = ['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G']

const Co2EffGraph: React.FC<Co2EffGraphProps> = ({ type }) => {
  return (
    <Wrapper className="co2EffGraph">
      <div className="classes">
        {co2Classes.map((co2Class: Co2EfficiencyClass, index: number) => (
          <Co2EffClass borderPosition="right" key={co2Class} type={co2Class} width={`${(100 / co2Classes.length - 1) * (index + 1)}%`}>
            {co2Class}
          </Co2EffClass>
        ))}
      </div>
      <div className="carClass">
        {co2Classes.map((co2Class: Co2EfficiencyClass) => (
          <CarClass className={co2Class === type ? 'active' : ''} key={co2Class}>
            {co2Class === type ? type : ''}
          </CarClass>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;

  & .classes {
    flex-grow: 1;
  }
`

const CarClass = styled.div`
  position: relative;
  padding: 2px 5px;
  height: 25px;
  width: 25px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.active {
    background-color: #fff;
    color: #000;

    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0px;
      left: -25px;
      border-top: 12.5px solid transparent;
      border-bottom: 12.5px solid transparent;
      border-left: 12.5px solid transparent;
      border-right: 12.5px solid #fff;
    }
  }
`

export { Co2EffGraph }
