import React from 'react'

const De: React.FC<{}> = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.41 43.51">
      <title>germany</title>
      <path
        fill="#464655"
        d="M66.41,14.5H0V1.15A1.15,1.15,0,0,1,1.15,0H65.27a1.15,1.15,0,0,1,1.15,1.15V14.5Z"
        transform="translate(-0.01 0)"
      />
      <path
        fill="#ffe15a"
        className="cls-2"
        d="M65.27,43.51H1.15A1.15,1.15,0,0,1,0,42.36V29H66.41V42.36A1.15,1.15,0,0,1,65.27,43.51Z"
        transform="translate(-0.01 0)"
      />
      <rect fill="#ff4b55" className="cls-3" y="14.5" width="66.41" height="14.5" />
    </svg>
  )
}

export { De }
