import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { CarInventory } from '../../types'
import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }

  ${CheckedButton} {
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

interface CarInventoryFilterPageProps {}

const CarInventoryFilterPage: React.FC<CarInventoryFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = <K extends keyof CarInventory>(key: K, value: boolean) => {
    filterStore.filters.vehicleInventory[key] = value
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="carInventory" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <CheckedButton
          onClick={() => onFilterChange('companyCar', !filterStore.filters.vehicleInventory.companyCar)}
          label={<FormattedMessage id="carInventoryValues.companyCar" />}
          active={filterStore.filters.vehicleInventory.companyCar}
          key="companyCar"
        />

        <CheckedButton
          onClick={() => onFilterChange('oneYearOldCar', !filterStore.filters.vehicleInventory.oneYearOldCar)}
          label={<FormattedMessage id="carInventoryValues.oneYearOldCar" />}
          active={filterStore.filters.vehicleInventory.oneYearOldCar}
          key="oneYearOldCar"
        />

        <CheckedButton
          onClick={() => onFilterChange('usedCar', !filterStore.filters.vehicleInventory.usedCar)}
          label={<FormattedMessage id="carInventoryValues.usedCar" />}
          active={filterStore.filters.vehicleInventory.usedCar}
          key="usedCar"
        />
      </Wrapper>
    </Page>
  ))
}

export { CarInventoryFilterPage }
