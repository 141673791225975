import React from 'react'

const It: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.52 5.58">
      <path d="M2.84,5.58H.15A.15.15,0,0,1,0,5.44V.15A.15.15,0,0,1,.15,0H2.84Z" transform="translate(0 0)" fill="#73af00" />
      <rect x="2.84" width="2.84" height="5.58" fill="#f5f5f5" />
      <path d="M8.37,5.58H5.68V0H8.37a.15.15,0,0,1,.15.15V5.44A.15.15,0,0,1,8.37,5.58Z" transform="translate(0 0)" fill="#ff4b55" />
    </svg>
  )
}

export { It }
