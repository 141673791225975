import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon, RangeFilterLabel } from '../'
import { useFilterStore } from '../../hooks'

interface PowerFilterLinkProps {}

const PowerFilterLink: React.FC<PowerFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  const power = filterStore.filters.power

  return useObserver(() => (
    <FilterView label="powerFilterLabel" link="/filter/power" badge={power.lte || power.gte ? <Icon type="check" /> : 0}>
      <RangeFilterLabel filter={power} unit={filterStore.filters.powerType} />
    </FilterView>
  ))
}

export { PowerFilterLink }
