import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

import { Vehicle } from './Vehicle'
import { DetailCar } from '../../types'

interface SimiliarVehiclesProps {
  car: DetailCar
}

const SimiliarVehicles: React.FC<SimiliarVehiclesProps> = ({ car }) => {
  return useObserver(() => {
    return (
      <Wrapper>
        {car.similarVehicles.slice(0, 4).map((car) => (
          <Vehicle key={car._id} car={car} />
        ))}
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  display: flex;

  ${breakpoint('mobile')`
    flex-direction: column;

    .vehicle {
      width: 100%;

    &:not(:last-child) {
      margin-right: 0px;
    }

      margin-bottom: 20px;
    }
  `}

  ${breakpoint('tablet')`
    flex-direction: row;

    .vehicle {
      width: 25%;

      &:not(:last-child) {
        margin-right: 20px !important;
      }

      margin-bottom: 0px;
    }
  `}



  display: flex;

  .vehicle {
    background-color: #212121;
  }
`

export { SimiliarVehicles }
