import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView } from '../'
import { useFilterStore } from '../../hooks'

interface CarFilterLinkProps {}

const CarFilterLink: React.FC<CarFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <FilterView
      label="carFilterLabel"
      link={filterStore.filters.cars.length ? '/filter/car' : '/filter/car/brands'}
      badge={filterStore.getCarFiltersAsLabels.length}
    >
      {filterStore.getCarFiltersAsLabels.length ? filterStore.getCarFiltersAsLabels.map(label => <div key={label}>{label}</div>) : null}
    </FilterView>
  ))
}

export { CarFilterLink }
