import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { CarFinancing } from '../../types'

export interface CarFinancingInfoProps {
  data: CarFinancing
}

const CarFinancingInfo: React.FC<CarFinancingInfoProps> = ({ data }) => {
  return (
    <Wrapper className="carFinancingInfo">
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="monthlyRate" />
        </Col>
        <Col>
          <FormattedNumber value={data.rate} /> €
        </Col>
      </Row>
      <div className="regulatory" dangerouslySetInnerHTML={{ __html: data.regulatory }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 400px;

  .ant-row-flex {
    padding-bottom: 15px;

    border-bottom: 1px solid #383838;
  }

  .regulatory {
    margin-top: 15px;
    font-size: 12px;
  }
`

export { CarFinancingInfo }
