import { Form as AntForm } from 'antd'
import styled from 'styled-components'

const AntFormItem = AntForm.Item

const Form = styled(AntForm)``

const FormItem = styled(AntFormItem)`
  label {
    color: #fff;
  }
`

export { Form, FormItem }
