import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

import { useFilterStore } from '../hooks'

const Banner: React.FC<{}> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const { utilityVehicle } = filterStore.filters

    return <Img className="searchBanner" src={`/react/gwsuche/img/${utilityVehicle ? 'nfz-banner' : 'pkw-banner'}.jpg`} />
  })
}

const Img = styled.img`
  width: 100%;
`

export { Banner }
