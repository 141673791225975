import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { FormattedMessage } from 'react-intl'

import { useCarStore } from '../../hooks'
import { Row, Col } from 'antd'

const CarDetailSeller: React.FC<{}> = () => {
  const carStore = useCarStore()

  return useObserver(() => {
    return (
      <Wrapper id="seller">
        <Row gutter={[{ xs: 0, lg: 20 }, 20]}>
          <Col>
            <h2>
              <FormattedMessage id="seller" />
            </h2>
            <br />
            {carStore.car?.location.isGmbh ? <>S&G Automobil GmbH</> : <>S&G Automobil AG</>}
            <br />
            {carStore.car?.location.street}
            <br />
            D-{carStore.car?.location.zipCode} {carStore.car?.location.city}
            <br />
          </Col>
        </Row>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  background-color: #0e0e0e;

  ${breakpoint('tablet')`
    background-color: transparent;
  `}

  img {
    width: 100%;
  }

  h2 {
    font-family: 'CorporateA', serif;
    font-size: 25px;
    line-height: 1;
    color: #fff;
  }

  a {
    color: #fff;
  }
`

export { CarDetailSeller }
