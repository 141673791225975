import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { useFilterStore } from '../../../../hooks'
import { Gearbox } from '../../../../types'
import { Checkbox } from '../../../'

const filters = [
  { value: 1, label: <FormattedMessage id="gearboxes.MANUAL" /> },
  { value: 2, label: <FormattedMessage id="gearboxes.SEMI_AUTOMATIC" /> },
  { value: 3, label: <FormattedMessage id="gearboxes.AUTOMATIC" /> },
]

const GearBoxFilter: React.FC = () => {
  const filterStore = useFilterStore()

  const onChange = (values: Gearbox[]) => {
    filterStore.filters.engine.gearboxes = values
  }

  return useObserver(() => {
    return (
      <Wrapper>
        <label>
          <FormattedMessage id="gearboxFilterLabel" />
        </label>

        <Checkbox.Group value={filterStore.filters.engine.gearboxes} onChange={(e: any) => onChange(e)}>
          <Row gutter={[0, 10]}>
            {filters.map((filter: any) => (
              <Col key={filter.value} span={6}>
                <Checkbox value={filter.value}>{filter.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;

  .ant-checkbox-group {
    margin-top: 10px;
  }
`

export { GearBoxFilter }
