import { Usercentrics } from '../types'

declare global {
  interface Window {
    usercentrics: Usercentrics
  }
}

interface UsercentricsHelper {
  isAutoUncleAccepted: () => boolean
}

const usercentricsHelper = (): UsercentricsHelper => {
  const isAutoUncleAccepted = (): boolean => {
    if (window.usercentrics) {
      return window.usercentrics
        .getConsents()
        .some((consent) => (consent.nameOfProcessingCompany === 'AutoUncle Aps' && consent.consentStatus ? true : false))
    }

    return false
  }

  return {
    isAutoUncleAccepted,
  }
}

export { usercentricsHelper }
