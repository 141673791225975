import { RangeFilter } from './'

export enum TotalWeight {
  '2.8T' = '2.8T',
  '3.5T' = '3.5T',
  '7.5T' = '7.5T',
}

export const TotalWeightValues = new Map<TotalWeight, RangeFilter>([
  [
    TotalWeight['2.8T'],
    {
      gte: 1,
      lte: 2800,
    },
  ],
  [
    TotalWeight['3.5T'],
    {
      gte: 2800,
      lte: 3500,
    },
  ],
  [
    TotalWeight['7.5T'],
    {
      gte: 3500,
      lte: 7500,
    },
  ],
])
