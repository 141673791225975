import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

export interface YoungStarProps {}

const YoungStar: React.FC<YoungStarProps> = () => {
  const { formatMessage } = useIntl()

  return <Wrapper>{formatMessage({ id: 'qualitySeal.youngStar' }).toUpperCase()}</Wrapper>
}

const Wrapper = styled.button`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 2px 6px;
  border: 0 solid #21467b;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #285393;
  background: linear-gradient(to bottom, #285393, #19396a);
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  margin-top: 8px;
`

export { YoungStar }
