const park = (id: string): any => {
  const url = `https://www.sug.de/de/gebrauchtwagen/fahrzeug-hinzufuegen?tx_suggebrauchtwagen_suggebrauchtwagen%5Bcar_id%5D=${id}&tx_suggebrauchtwagen_suggebrauchtwagen%5Bcontroller%5D=Car`

  fetch(url, {
    method: 'POST',
  })
    .then(() => {
      return true
    })
    .catch((e) => {
      return false
    })
}

export { park }
