import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useCarStore, useScroll } from '../hooks'
import { Page, Header } from '../components'
import { CarDetailNextPrev } from '../components/CarDetail/CarDetailNextPrev'
import { CarDetail } from '../components/CarDetail/CarDetail'
import { useIntl } from 'react-intl'

const CarPage: React.FC<{}> = () => {
  const carStore = useCarStore()
  const scroll = useScroll()
  const { formatMessage } = useIntl()

  const params: any = useParams()

  useEffect(() => {
    return () => {
      scroll.toTop()
    }
  })

  useEffect(() => {
    let id = params._id

    if (id.endsWith('_P')) {
      id = id.replace('_P', '')
    }

    carStore.loadCar(id)
  })

  return (
    <Page
      header={
        <Header withBack backTarget={'/treffer'} backLabel={formatMessage({ id: 'matches' })} title={''} toolbar={<CarDetailNextPrev />} />
      }
    >
      <CarDetail />
    </Page>
  )
}

export { CarPage }
