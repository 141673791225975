import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon } from './'
import { useFilterStore } from '../hooks'

interface CarInventoryLinkFilterProps {}

const CarInventoryFilterLink: React.FC<CarInventoryLinkFilterProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const inv = filterStore.filters.vehicleInventory

    return (
      <FilterView
        label="carInventory"
        link="/filter/inventory"
        badge={inv.companyCar || inv.oneYearOldCar ? <Icon type="check" /> : 0}
      ></FilterView>
    )
  })
}

export { CarInventoryFilterLink }
