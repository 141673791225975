import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { useFilterStore } from '../../../hooks'

import {
  VehicleTypeSwitcher,
  CarFilterLink,
  PriceFilterLink,
  RegistrationFilterLink,
  PowerFilterLink,
  FuelFilterLink,
  GearboxFilterLink,
  MileageFilterLink,
  CcmFilterLink,
  LocationFilterLink,
  EquipmentFilterLink,
  QualitysealFilterLink,
  ColorFilterLink,
  GWNumberFilterLink,
  VatReportableFilterLink,
  TotalWeightFilterLink,
  SeatFilterLink,
  Co2EuroNormFilterLink,
  CarEnvBadgeFilterLink,
  CarInventoryFilterLink,
} from '../../'

const DetailFilterMobile: React.FC = () => {
  const filterStore = useFilterStore()

  return useObserver(() => (
    <Wrapper>
      <VehicleTypeSwitcher />
      <CarFilterLink />
      <CarInventoryFilterLink />
      <PriceFilterLink />

      {filterStore.filters.utilityVehicle ? (
        <>
          <VatReportableFilterLink />
          <TotalWeightFilterLink />
          <SeatFilterLink />
          <Co2EuroNormFilterLink />
          <CarEnvBadgeFilterLink />
        </>
      ) : null}

      <RegistrationFilterLink />
      <PowerFilterLink />
      <FuelFilterLink />
      <GearboxFilterLink />
      <MileageFilterLink />
      <CcmFilterLink />
      <LocationFilterLink />
      <EquipmentFilterLink />
      <QualitysealFilterLink />
      <ColorFilterLink />
      <GWNumberFilterLink />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  padding: 25px;
`

export { DetailFilterMobile }
