const keys = new Map<string, string>([
  ['Karlsruhe-Knielingen', '4gVZT8TOVS8XRMqtwNagXw'],
  ['Karlsruhe-Hagsfeld', 'c_hZZE0fQYN4o8ir46DGAA'],
  ['Achern', 'wJyFhOZTVnmHszJENe003A'],
  ['Aschersleben', 'eNms1mY_26ZZTQGF86o3_g'],
  ['Bretten', 'sYjZ0HXhJsYX_G4TqknVoA'],
  ['Bruchsal', 'CczdUXI59yX4rx-do3KaAA'],
  ['Ettlingen', 'Oe9l_-PRICUXRqD8OPqmRg'],
  ['Halle', 'fPByA6YomaETT0SZMzKKrA'],
  ['Kehl', 'OL9LL8Yxj7XNSncjH9Ceag'],
  ['Lutherstadt Eisleben', 'Ib9PnYXZdWMqC7ivUgLOMg'],
  ['Merseburg', '5o6kagigCy4WFuu-QYv4Dw'],
  ['Offenburg', '2zKD_OxTc10hN-q1t_GLUw'],
  ['Pforzheim', '89hF2vH7AtpU8CykSEtpJg'],
  ['Quedlinburg', '7-qDH5je1xJMxYm7cmxN5g'],
  ['Querfurt', 'gVW9eD2SsRTi9VM6XSYgHw'],
  ['Sangerhausen', '94oDSJtwJ6c8GBW1PftBjg'],
  ['Petersberg OT Sennewitz', 'fPByA6YomaETT0SZMzKKrA'],
])

interface useAuConfigKeyProps {
  getKeyByLocation: (location: string) => string | undefined
}

const useAuConfigKey = (): useAuConfigKeyProps => {
  const getKeyByLocation = (location: string): string | undefined => {
    return keys.get(location)
  }

  return {
    getKeyByLocation,
  }
}

export { useAuConfigKey }
