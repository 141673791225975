import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { $enum } from 'ts-enum-util'

import { useFilterStore } from '../../../../hooks'
import { Select } from '../../../Select'
import { Seats } from '../../../../types'
import { OptionProps } from 'antd/lib/select'

const SeatFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const handleChange = (seats: Seats | undefined) => {
    filterStore.setSeats(seats)
  }

  const options: OptionProps[] = $enum(Seats)
    .getValues()
    .map((filter) => ({ label: formatMessage({ id: `seatOptions.${filter}` }), value: filter }))

  options.unshift({ value: undefined, label: formatMessage({ id: 'filterAny' }) })

  return useObserver(() => {
    return (
      <Wrapper>
        <label>
          <FormattedMessage id="seats" /> :
        </label>
        <Select<string>
          className="seatFilter"
          style={{ width: '100%' }}
          onChange={(value: any) => handleChange(value)}
          value={filterStore.getSeats}
          placeholder={<FormattedMessage id="seats" />}
          options={options}
        />
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  color: #fff;
  label {
    line-height: 39.9999px;
  }
`

export { SeatFilter }
