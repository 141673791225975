import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Select as AntSelect } from 'antd'

import { useFilterStore } from '../../../hooks'
import { Select } from '../..'

export interface ModelDropdownProps {
  selectedBrand: string
  value?: string
  onChange?: (value: string) => void
}

const ModelDropdown: React.FC<ModelDropdownProps & any> = ({ selectedBrand, onChange, value }, ref) => {
  const filterStore = useFilterStore()

  const { OptGroup, Option } = AntSelect

  return useObserver(() => {
    const models = filterStore.getModelsByBrand(selectedBrand)

    return (
      <Select
        ref={ref}
        onChange={(v: any) => (onChange ? onChange(v) : false)}
        value={value}
        placeholder={<FormattedMessage id="modelLabel" />}
        disabled={!selectedBrand}
      >
        <Option value={undefined} key="any">
          <FormattedMessage id="filterAny" />
        </Option>

        {models &&
          models.map((model) =>
            model.models ? (
              <OptGroup label={model._id} key={model._id}>
                <Option value={model._id} key={model._id}>
                  {model._id} (<FormattedMessage id="filterAny" />)
                </Option>

                {model.models &&
                  model.models.map((subModel) => (
                    <Option value={subModel._id} key={subModel._id}>
                      {subModel._id}
                    </Option>
                  ))}
              </OptGroup>
            ) : (
              <Option value={model._id} key={model._id}>
                {model._id}
              </Option>
            )
          )}
      </Select>
    )
  })
}

export { ModelDropdown }
