import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Breakpoint } from 'react-socks'

import { useAppStore } from '../../hooks'
import { Icon } from '../../components'

const ToggleModal: React.FC<{}> = () => {
  const appStore = useAppStore()

  const open = () => {
    appStore.setSaveFilterModalVisibility(true)
  }

  return useObserver(() => (
    <>
      <Breakpoint medium up>
        <DesktopButton onClick={() => open()}>
          <FormattedMessage id="saveFilter" />
        </DesktopButton>
      </Breakpoint>
      <Breakpoint medium down>
        <MobileButton onClick={() => open()}>
          <Icon type="save" />
          <span>
            <FormattedMessage id="save" />
          </span>
        </MobileButton>
      </Breakpoint>
    </>
  ))
}

const DesktopButton = styled(AntButton)`
  border-radius: 0;
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  line-height: 1;
  padding: 9px 20px;
  height: 42px;
  width: 215px;

  &:hover {
    color: #fff;
    background-color: #1daeee;
    border-color: #1daeee;
  }
`

const MobileButton = styled.div`
  color: #5c5d5e;
  line-height: 32px;

  .custom-icon {
    font-size: 18px;
  }

  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
`

export { ToggleModal }
