import { observable, action, computed } from 'mobx'

import { RateCalculatorFormType, RateCalculatorOutput, Option, DetailCar } from '../types'

declare type CustomerTypes = 'business' | 'private'

export const RATE_CALC_INITIAL: RateCalculatorFormType = {
  customer_type: 'private',
  calculation_type: 'balloon',
  deposit_amount: null,
  period_months: '',
  total_mileage: '',
}

export declare type FormItemTypes = 'customer_type' | 'calculation_type' | 'period_months' | 'total_mileage'
export const formItemTypes: FormItemTypes[] = ['customer_type', 'calculation_type', 'period_months', 'total_mileage']

export interface RequestInput {
  id: string
  value: any
}

export declare type RateCalcFormItems = {
  [key in FormItemTypes]: Option[]
}

export class RateCalcStore {
  /**
   * set to true until initial load is done
   *
   * @memberof RateCalcStore
   */
  @observable initialLoading = true

  /**
   * is set to true whenever data is loading
   *
   * @memberof RateCalcStore
   */
  @observable loading = false

  /**
   * holds the form data
   *
   * @type {RateCalculatorFormType}
   * @memberof RateCalcStore
   */
  @observable form: RateCalculatorFormType = RATE_CALC_INITIAL

  /**
   * holds the dropdown form items
   *
   * @type {RateCalcFormItems}
   * @memberof RateCalcStore
   */
  @observable formItems: RateCalcFormItems = {
    customer_type: [],
    calculation_type: [],
    period_months: [],
    total_mileage: [],
  }

  /**
   * holds the output values to show
   *
   * @type {RateCalculatorOutput[]}
   * @memberof RateCalcStore
   */
  @observable output: RateCalculatorOutput[] = []

  /**
   * sets the form items
   *
   * @param {RateCalcFormItems} items
   * @memberof RateCalcStore
   */
  @action
  setFormItems(items: RateCalcFormItems): void {
    this.formItems = items
  }

  /**
   * sets form data
   *
   * @param {RateCalculatorFormType} form
   * @memberof RateCalcStore
   */
  @action
  setFormData(form: Object): void {
    this.form = {
      ...this.form,
      ...form,
    }
  }

  /**
   * returns the form inputs as array
   *
   * @memberof RateCalcStore
   */
  @computed get getRequestInputs() {
    const items: RequestInput[] = []

    formItemTypes.forEach((key: FormItemTypes) => {
      if (this.form[key]) {
        items.push({
          id: key,
          value: this.form[key],
        })
      }
    })

    if (this.form['deposit_amount'] || this.form['deposit_amount'] === 0) {
      items.push({
        id: 'deposit_amount',
        value: this.form['deposit_amount'],
      })
    }

    return items
  }

  /**
   * sets the output
   *
   * @param {RateCalculatorOutput[]} data
   * @memberof RateCalcStore
   */
  @action
  setOutput(data: RateCalculatorOutput[]): void {
    this.output = data
  }

  /**
   * sets the loading flag
   *
   * @param {boolean} state
   * @memberof RateCalcStore
   */
  @action
  setLoading(state: boolean) {
    this.loading = state
  }

  /**
   * sets the initial load
   *
   * @memberof RateCalcStore
   */
  @action
  initialDone(): void {
    this.initialLoading = false
  }

  /**
   * sets the customer type
   *
   * @param {CustomerTypes} type
   * @memberof RateCalcStore
   */
  @action setCustomerType(type: CustomerTypes) {
    this.form.customer_type = type
  }

  @action getPrice(car: DetailCar): number {
    // if (this.form.customer_type === 'business' && car.vatReportable) {
    //   return car.priceWithoutTax
    // }

    return car.price
  }
}
