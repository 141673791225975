import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { $enum } from 'ts-enum-util'

import { Page, CheckedButton, Header, Footer, NavigateToFilter } from '../../components'
import { Color } from '../../types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  .ant-input-group {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #5d5d5d;
  }

  ${CheckedButton} {
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    & .color {
      display: flex;

      &__display {
        width: 20px;
        height: 20px;

        margin-right: 10px;
        border-radius: 5px;
      }
    }
  }
`

interface ColorFilterProps {}

const ColorFilterPage: React.FC<ColorFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (color: Color) => {
    filterStore.addOrRemoveColor(color)
  }

  const renderColor = (color: Color) => {
    return (
      <div className="color">
        <div className="color__display" style={{ background: $enum(Color).getKeyOrDefault(color) }} />
        <FormattedMessage id={`color.${color}`} />
      </div>
    )
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="colorFilterLabel" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        {$enum(Color)
          .getValues()
          .map(filter => (
            <CheckedButton
              onClick={() => onFilterChange(filter)}
              label={renderColor(filter)}
              active={filterStore.filters.colors.includes(filter)}
              key={filter}
            />
          ))}
      </Wrapper>
    </Page>
  ))
}

export { ColorFilterPage }
