import React from 'react'
import { Button } from 'antd'
import { useObserver } from 'mobx-react'

import { useRouterStore } from '../../hooks/useStore'

interface LinkProps {
  to: string
  type?: any
}

const Link: React.FC<LinkProps> = ({ to, children, ...rest }) => {
  const routerStore = useRouterStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return useObserver(() => (
    <>
      <Button onClick={() => navigate(to)} {...rest}>
        {children}
      </Button>
    </>
  ))
}

export { Link }
