import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useObserver } from 'mobx-react'
import { OptionProps } from 'antd/lib/select'

import { useFilterStore } from '../../hooks'
import { Select } from '../Select'

const LocationFilter: React.FC<{}> = () => {
  const filterStore = useFilterStore()

  const handleChange = (location: string[]) => {
    filterStore.filters.locations = location
  }

  return useObserver(() => {
    const { locations } = filterStore

    const options: OptionProps[] = locations.map((loc) => ({ value: loc, label: loc }))

    return (
      <Select<string[]>
        maxTagCount={0}
        maxTagTextLength={2}
        showSearch={false}
        maxTagPlaceholder={(tags: string[]) => <FormattedMessage id="locationTagCount" values={{ count: tags.length }} />}
        mode="multiple"
        className="locationFilter"
        style={{ width: '100%' }}
        onChange={(value: any) => handleChange(value)}
        value={filterStore.filters.locations}
        placeholder={<FormattedMessage id="chooseLocation" />}
        options={options}
      />
    )
  })
}

export { LocationFilter }
