import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Select } from 'antd'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { $enum } from 'ts-enum-util'

import { useAppStore } from '../../hooks'
import { Language } from '../../types'
import { LanguageIcons } from '../../Icon/Language'

const { Option } = Select

interface LanguageSwitcherProps {}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = () => {
  const appStore = useAppStore()

  function handleChange(value: Language): void {
    appStore.setLanguage(value)
  }

  return useObserver(() => (
    <>
      <GlobalStyles />

      <Wrapper>
        <label>
          <FormattedMessage id="language" />:
        </label>

        <Select dropdownClassName="language-dropdown" value={appStore.language} showArrow={false} onChange={handleChange}>
          {Array.from($enum(Language).values()).map((lang) => {
            const Icon = LanguageIcons.get(lang)
            return (
              <Option key={lang} value={lang}>
                {Icon ? <Icon /> : null}
              </Option>
            )
          })}
        </Select>
      </Wrapper>
    </>
  ))
}

const GlobalStyles = createGlobalStyle`
  .language-dropdown {
    width: auto !important;
    background-color: #191919;

    .ant-select-dropdown {
      &-menu-item {
        &-selected {
          background-color: transparent;
        }
      }
    }

    svg {
      width: 26px;
      height: auto;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 8px;
    letter-spacing: inherit;
  }

  .ant-select {
    &:hover {
      .ant-select-selection {
        border-color: #1daeee;
      }
    }
  }

  .ant-select-selection {
    width: 28px;
    height: 19px;
    background-color: transparent;
    border-color: #4e4e4e;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &__rendered {
      margin: 0;
      line-height: 1;
    }

    .ant-select-selection-selected-value {
      overflow: hidden;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`

export { LanguageSwitcher }
