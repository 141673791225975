import React from 'react'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { Page, Header, CheckedButton, Footer, NavigateToFilter, RegistrationFilterForm } from '../../components'
import { useFilterStore } from '../../hooks'
import { RangeFilter } from '../../types'
import { doesFilterMatch } from '../../helpers/rangeFilter'

const filters = [
  {
    label: <FormattedMessage id="filterLabel.registration3" />,
    value: {
      gte: Number(
        moment()
          .subtract(3, 'years')
          .format('YYYY')
      ),
      lte: null,
    },
  },
  {
    label: <FormattedMessage id="filterLabel.registration10" />,
    value: {
      gte: Number(
        moment()
          .subtract(10, 'years')
          .format('YYYY')
      ),
      lte: null,
    },
  },
]

export interface RegistrationFilterPageProps {}

const RegistrationFilterPage: React.FC<RegistrationFilterPageProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: RangeFilter) => {
    filterStore.setFirstRegistrationFilter(value)
  }

  return useObserver(() => (
    <Page
      header={<Header title={<FormattedMessage id="firstRegistrationLabel" />} toolbar={<NavigateToFilter />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <RegistrationFilterForm values={filterStore.filters.firstRegistration} onChange={onFilterChange} />
        <FormattedMessage id="popular" />
        {filters.map((filter, i) => (
          <CheckedButton
            onClick={() => onFilterChange(filter.value)}
            label={filter.label}
            active={doesFilterMatch(filterStore.filters.firstRegistration, filter.value)}
            key={i}
          />
        ))}
      </Wrapper>
    </Page>
  ))
}

const Wrapper = styled.div`
  padding: 25px;
  color: #5d5d5d;

  .input {
    margin-bottom: 15px;
    padding-bottom: 15px;

    border-bottom: 1px solid #5d5d5d;
  }

  .ant-select {
    margin-bottom: 15px;
  }

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

export { RegistrationFilterPage }
