import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { useIntl } from 'react-intl'
import { map } from 'styled-components-breakpoint'
import { Row, Col } from 'antd'
import { Breakpoint } from 'react-socks'

import { useCarListStore, useFilterStore } from '../hooks'
import { Car } from '../types'
import { CarBox } from '../components/CarBox'
import { Icon } from '../components/Icon'
import { Pagination } from '../components/Pagination'
import { Page } from '../components/Page'
import { Header } from '../components/Header'
import { ResultListFilterBar, NavigateToFilter, ParkModal, SortContainer, SaveFilterModal, ScoutServiceBanner } from '../components'
import { ParkModalHandles } from '../components/ParkModal'
import { CarDetailLegal } from '../components/CarDetail'
import { ToggleModal as ToggleSaveModal } from '../components/SaveFilterModal'
import { AuEmbedFC } from '../components/AutoUncle'
import { useScroll } from '../hooks'

interface ListPageProps {}

const ListPage: React.FC<ListPageProps> = () => {
  const { formatMessage, formatNumber } = useIntl()

  const carListStore = useCarListStore()
  const filterStore = useFilterStore()
  const scroll = useScroll()

  const parkModalRef = useRef<ParkModalHandles>(null)

  const handleParkClick = (carId: string) => {
    if (parkModalRef.current) {
      parkModalRef.current.setCarId(carId)
    }
  }

  useEffect(() => {
    return () => {
      scroll.toTop()
    }
  })

  const handlePageChange = (page: number) => {
    filterStore.changePage(page)
  }

  return useObserver(() => (
    <Page
      header={
        <Header
          title={`${formatMessage({ id: 'totalMatches' }, { count: formatNumber(carListStore.total) })}`}
          desktopTitle={formatMessage({ id: 'title' })}
          toolbar={
            <StyledToolbar>
              <Breakpoint medium up>
                <ToggleSaveModal />
              </Breakpoint>
              <NavigateToFilter />
            </StyledToolbar>
          }
        />
      }
    >
      <AuEmbedFC />
      <ResultListFilterBar />

      <TabletResultCountWrapper>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <span className="matches">{formatMessage({ id: 'totalMatches' }, { count: formatNumber(carListStore.total) })}</span>
          </Col>
          <Breakpoint small down>
            <Col>
              <ToggleSaveModal />
            </Col>
          </Breakpoint>

          <Col>
            <SortContainer />
          </Col>
        </Row>
      </TabletResultCountWrapper>

      {!carListStore.loading ? (
        <>
          <Wrapper>
            {carListStore.cars.map((item: Car, index: number) => (
              <>
                <CarBox key={item._id} data={item} onParkClick={(carId: string) => handleParkClick(carId)} />
                {carListStore.cars.length / 2 === index + 1 ? <ScoutServiceBanner /> : null}
              </>
            ))}
          </Wrapper>

          <StyledPagination
            hideOnSinglePage
            defaultCurrent={1}
            defaultPageSize={filterStore.pagination.limit}
            total={carListStore.total}
            current={filterStore.pagination.page}
            onChange={(page: number) => handlePageChange(page)}
          />
          <LegalWrapper>
            <CarDetailLegal />
          </LegalWrapper>
        </>
      ) : (
        <Loading>
          <Icon type="loading" />
        </Loading>
      )}

      <ParkModal ref={parkModalRef} />
      <SaveFilterModal />
    </Page>
  ))
}

const StyledToolbar = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: 10px;
  }
`

const Wrapper = styled.div`
  ${map({ mobile: '25px 25px 0px 25px', tablet: '0px' }, (padding) => `padding: ${padding};`)}
`

const LegalWrapper = styled.div`
  padding: 20px;
  background-color: #1e1e1e;

  .carDetailLegal {
    padding: 0px;

    p {
      padding: 0px;
    }
  }
`

const TabletResultCountWrapper = styled.div`
  background-color: rgb(14, 14, 14);
  padding: 20px;

  & .matches {
    font-size: 18px;
  }
`

// https://github.com/microsoft/TypeScript/issues/37597
const StyledPagination = styled(Pagination as any)`
  margin: 25px 0px 25px 0px;
  text-align: center;
`

const Loading = styled.div`
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 22px);

  & .custom-icon {
    font-size: 45px;
  }
`

export { ListPage }
