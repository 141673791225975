import React from 'react'
import { useObserver } from 'mobx-react'
import { useIntl } from 'react-intl'

import { RangeFilterFormDropdown } from '../../../'
import { useFilterStore } from '../../../../hooks'
import { RangeFilter } from '../../../../types'

const values = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500]

const FinancingFilter: React.FC = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onFilterChange = (values: RangeFilter) => {
    filterStore.setFinancingFilter(values)
  }

  return useObserver(() => {
    const financing = filterStore.filters.financing
    return (
      <>
        <RangeFilterFormDropdown
          label={formatMessage({ id: 'financeRate' })}
          unit={'€'}
          value={financing}
          values={values}
          onChange={onFilterChange}
          formatValue
        />
      </>
    )
  })
}

export { FinancingFilter }
