interface UseScrollProps {
  toTop: () => void
}

const useScroll = (): UseScrollProps => {
  const toTop = (): void => {
    const root = document.getElementById('root')
    root?.scrollIntoView(true)
  }

  return {
    toTop,
  }
}

export { useScroll }
