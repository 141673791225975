import React from 'react'
import styled from 'styled-components'
import { Icon, Popover, Row, Col } from 'antd'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { map } from 'styled-components-breakpoint'
import { Breakpoint } from 'react-socks'

import { Car, Fuel } from '../../types'
import { Button } from '../Button'
import { ParkButton } from '../ParkButton'
import { useRouterStore, useIsMobile, useFilterStore } from '../../hooks'
import { kwToPs } from '../../helpers/power'
import { Co2EffClass, CarFinancingInfo, YoungStar } from '../'
import { PriceRatingWidget } from '../AutoUncle'
import { useObserver } from 'mobx-react'

interface CarBoxProps {
  data: Car
  onParkClick: Function
}

const CarBox: React.FC<CarBoxProps> = ({ data, onParkClick }) => {
  const routerStore = useRouterStore()
  const { formatMessage } = useIntl()
  const isMobile = useIsMobile()
  const filterStore = useFilterStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return useObserver(() => (
    <Wrapper>
      <Row type="flex">
        {isMobile && data.qualitySeal.youngStar ? (
          <Col span={24}>
            <YoungStar />
          </Col>
        ) : null}

        <Col xs={24} lg={11}>
          <ImageWrapper onClick={() => navigate(`/fahrzeug-detailansicht/${data._id}/${data.link}`)}>
            <img
              src={
                data.images.length
                  ? `https://www.sug-verwaltung.de/public/images/${data.images[0].imagepath}`
                  : 'https://www.sug-verwaltung.de/public/foto_folgt_in_kuerze.jpeg'
              }
              alt=""
            />
          </ImageWrapper>
        </Col>

        <Col xs={24} lg={13}>
          <ContentWrapper>
            <Row gutter={20} type="flex">
              <Col xs={24} lg={16} className="nameWrapper">
                <h3 className="title" onClick={() => navigate(`/fahrzeug-detailansicht/${data._id}/${data.link}`)}>
                  {data.brand}
                </h3>

                <div className="subtitle" onClick={() => navigate(`/fahrzeug-detailansicht/${data._id}/${data.link}`)}>
                  {data.name}
                </div>
              </Col>

              <Col xs={24} lg={8} className="priceWrapper">
                <div>
                  {filterStore.filters.utilityVehicle && data.vatReportable ? (
                    <>
                      <span className="price">
                        <FormattedNumber value={data.priceWithoutTax} />
                        ,- €{' '}
                        <span className="light">
                          (<FormattedMessage id="netto" />)
                        </span>
                      </span>
                      <span className="brutto">
                        <FormattedNumber value={data.price} />
                        ,- €{' '}
                        <span className="netto">
                          (<FormattedMessage id="brutto" />)
                        </span>
                      </span>
                    </>
                  ) : (
                    <strong className="price">
                      <FormattedNumber value={data.price} />
                      ,- €
                    </strong>
                  )}

                  {data.financing && data.financing.regulatory && (
                    <Popover content={<CarFinancingInfo data={data.financing} />} trigger="click">
                      <Button type="link" className="rate">
                        <FormattedMessage id="monthlyRate" /> <Icon type="exclamation-circle" />
                      </Button>
                    </Popover>
                  )}
                </div>
                <PriceRatingWidget link={data.link} internalNumber={data._id} layout="tall_smaller" />
              </Col>

              <Col xs={24} className="attributes-list-wrapper">
                <ul className="attributes-list">
                  {/* only tablets and above */}
                  <Breakpoint medium up>
                    <li>
                      <div>
                        <FormattedMessage id="firstRegistration" />:
                      </div>
                      <div>{data.firstRegistrationDate}</div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="mileage" />:
                      </div>
                      <div>
                        <FormattedNumber value={data.mileage} /> km
                      </div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="powerFilterLabel" />:
                      </div>
                      <div>
                        {data.power} <FormattedMessage id="kw" /> {`(${kwToPs(data.power || 0)} ${formatMessage({ id: 'ps' })})`}
                      </div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="engine" />:
                      </div>
                      <div>
                        <FormattedMessage id={`fuels.${Fuel[data.engine.fuel || 1]}`} />
                      </div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="colorFilterLabel" />:
                      </div>
                      <Color>{data.color}</Color>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="carCategory" />:
                      </div>
                      <div>{data.categories[0]}</div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="warranty" />:
                      </div>
                      <div>
                        <FormattedMessage id={data.warranty ? 'yes' : 'no'} />
                      </div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="vatReportable" />:
                      </div>
                      <div>
                        <FormattedMessage id={data.vatReportable ? 'yes' : 'no'} />
                      </div>
                    </li>

                    {data.emission ? (
                      <li>
                        <div>
                          <FormattedMessage id="emission" /> NEFZ
                          <sup>[4]</sup>:
                        </div>
                        <div>
                          <FormattedNumber value={data.emission} /> g/km
                        </div>
                      </li>
                    ) : (
                      ''
                    )}

                    {data.wltp.emission ? (
                      <li>
                        <div>
                          <FormattedMessage id="emission" /> WLTP
                          <sup>[5]</sup>:
                        </div>
                        <div>{data.wltp.emission} g/km</div>
                      </li>
                    ) : (
                      ''
                    )}

                    {/* fuel consumption only for non pure electric */}
                    {Fuel[data.engine.fuel || 0] !== 'ELECTRIC' ? (
                      <>
                        {data.fuelConsumption ? (
                          <li>
                            <div>
                              <FormattedMessage id="fuelConsumption" /> NEFZ
                              <sup>[4]</sup>:
                            </div>
                            <div>
                              <FormattedNumber value={data.fuelConsumption} /> l/100km
                            </div>
                          </li>
                        ) : (
                          ''
                        )}

                        {data.wltp.combined ? (
                          <li>
                            <div>
                              <FormattedMessage id="fuelConsumption" /> WLTP
                              <sup>[5]</sup>:
                            </div>
                            <div>{data.wltp.combined} l/100km</div>
                          </li>
                        ) : (
                          ''
                        )}
                      </>
                    ) : null}

                    {['ELECTRIC', 'HYBRID_DIESEL', 'HYBRID_PETROL'].includes(Fuel[data.engine.fuel || 0]) ? (
                      <>
                        {data.combinedPowerConsumption ? (
                          <li>
                            <div>
                              <FormattedMessage id="powerConsumption" /> NEFZ
                              <sup>[4]</sup>:
                            </div>
                            <div>
                              <FormattedNumber value={data.combinedPowerConsumption} /> kWh/100km
                            </div>
                          </li>
                        ) : (
                          ''
                        )}

                        {data.wltp.combinedPowerConsumption ? (
                          <li>
                            <div>
                              <FormattedMessage id="powerConsumption" /> WLTP
                              <sup>[5]</sup>:
                            </div>
                            <div>{data.wltp.combinedPowerConsumption} kWh/100km</div>
                          </li>
                        ) : (
                          ''
                        )}
                      </>
                    ) : null}

                    <li>
                      <div>
                        <FormattedMessage id="energyEfficiencyClass" />
                        <sup>[4]</sup>:
                      </div>
                      <div>
                        <Co2EffClass type={data.energyEfficiencyClass} />
                      </div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="gwNumberFilterLabel" />:
                      </div>
                      <div>{data._id}</div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="locationFilterLabel" />:
                      </div>
                      <div>{data.location.city}</div>
                    </li>
                  </Breakpoint>

                  {/* only on mobile devices */}
                  <Breakpoint small down>
                    <li>
                      <div>
                        <FormattedMessage id="firstRegistration" />:
                      </div>
                      <div>{data.firstRegistrationDate}</div>
                    </li>

                    <li>
                      <div>
                        <FormattedMessage id="mileage" />:
                      </div>
                      <div>
                        <FormattedNumber value={data.mileage} /> km
                      </div>
                    </li>

                    {data.emission ? (
                      <li>
                        <div>
                          <FormattedMessage id="emission" /> NEFZ
                          <sup>[4]</sup>:
                        </div>
                        <div>
                          <FormattedNumber value={data.emission} /> g/km
                        </div>
                      </li>
                    ) : (
                      ''
                    )}

                    {data.wltp.emission ? (
                      <li>
                        <div>
                          <FormattedMessage id="emission" /> WLTP
                          <sup>[5]</sup>:
                        </div>
                        <div>{data.wltp.emission} g/km</div>
                      </li>
                    ) : (
                      ''
                    )}
                    {/* fuel consumption only for non pure electric */}
                    {Fuel[data.engine.fuel || 0] !== 'ELECTRIC' ? (
                      <>
                        {data.fuelConsumption ? (
                          <li>
                            <div>
                              <FormattedMessage id="fuelConsumption" /> NEFZ
                              <sup>[4]</sup>:
                            </div>
                            <div>
                              <FormattedNumber value={data.fuelConsumption} /> l/100km
                            </div>
                          </li>
                        ) : (
                          ''
                        )}

                        {data.wltp.combined ? (
                          <li>
                            <div>
                              <FormattedMessage id="fuelConsumption" /> WLTP
                              <sup>[5]</sup>:
                            </div>
                            <div>{data.wltp.combined} l/100km</div>
                          </li>
                        ) : (
                          ''
                        )}
                      </>
                    ) : null}

                    {['ELECTRIC', 'HYBRID_DIESEL', 'HYBRID_PETROL'].includes(Fuel[data.engine.fuel || 0]) ? (
                      <>
                        {data.combinedPowerConsumption ? (
                          <li>
                            <div>
                              <FormattedMessage id="powerConsumption" /> NEFZ
                              <sup>[4]</sup>:
                            </div>
                            <div>
                              <FormattedNumber value={data.combinedPowerConsumption} /> kWh/100km
                            </div>
                          </li>
                        ) : (
                          ''
                        )}

                        {data.wltp.combinedPowerConsumption ? (
                          <li>
                            <div>
                              <FormattedMessage id="powerConsumption" /> WLTP
                              <sup>[5]</sup>:
                            </div>
                            <div>{data.wltp.combinedPowerConsumption} kWh/100km</div>
                          </li>
                        ) : (
                          ''
                        )}
                      </>
                    ) : null}
                  </Breakpoint>
                </ul>
              </Col>

              <Col xs={24} lg={12}>
                {!isMobile && data.qualitySeal?.youngStar ? <YoungStar /> : null}
              </Col>

              <Col xs={24} lg={12}>
                <div className="cta-wrapper">
                  <Button onClick={() => navigate(`/fahrzeug-detailansicht/${data._id}/${data.link}`)}>
                    <FormattedMessage id="view" />
                  </Button>
                  <ParkButton onClick={() => onParkClick(data.uid)} />
                </div>
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </Wrapper>
  ))
}

const Color = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;

  max-width: 50%;
`

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
  }

  cursor: pointer;
`
const ContentWrapper = styled.div`
  padding: 25px;
  height: 100%;

  > .ant-row-flex {
    height: 100%;
    align-content: space-between;
  }

  .nameWrapper {
    .title {
      margin: 0;
      font-size: 18px;
      line-height: 1;
      margin-bottom: 3px;
      color: #fff;
      cursor: pointer;
    }

    .subtitle {
      cursor: pointer;
    }
  }

  .priceWrapper {
    ${map({ mobile: 'left', tablet: 'right' }, (textAlign) => `text-align: ${textAlign};`)}

    .price {
      display: block;
      margin: 0;
      font-size: 18px;
      line-height: 1;
      margin-bottom: 3px;
      color: #fff;

      font-weight: 700;

      .light {
        font-weight: 400;
      }
    }

    .rate {
      ${map({ mobile: '30px', tablet: '0px' }, (marginBottom) => `margin-bottom: ${marginBottom};`)}
      color: #5d5e5f;
      border: none;
    }
  }

  .attributes-list-wrapper {
    padding: 10px 25px;

    .attributes-list {
      margin: 0;
      padding: 0;
      ${map({ mobile: '1', tablet: '2' }, (columns) => `columns: ${columns};`)}
      column-gap: 25px;

      li {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #353535;
        break-inside: avoid;

        ${map({ mobile: '16px', tablet: '4px' }, (size) => `margin-bottom: ${size}; padding-bottom: ${size};`)}
      }
    }
  }

  .cta-wrapper {
    display: flex;
    align-items: center;

    .ant-btn:not(.ant-btn-default) {
      padding: 10px 20px;
      flex-grow: 1;
      text-transform: uppercase;
    }
  }
`

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #1e1e1e;

  .fullHeight {
    height: 100%;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export { CarBox }
