import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { $enum } from 'ts-enum-util'

import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'
import { Seats } from '../../types'
import { useFilterStore } from '../../hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  ${CheckedButton} {
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
`

interface SeatFilterProps {}

const SeatFilter: React.FC<SeatFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: Seats | undefined) => {
    filterStore.setSeats(value)
  }

  return useObserver(() => (
    <Page header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="seats" />} />} footer={<Footer showMeta={false} />}>
      <Wrapper>
        <CheckedButton
          onClick={() => onFilterChange(undefined)}
          label={<FormattedMessage id="filterAny" />}
          active={filterStore.getSeats === undefined}
          key="any"
        />

        {$enum(Seats)
          .getValues()
          .map((filter) => (
            <CheckedButton
              onClick={() => onFilterChange(filter)}
              label={<FormattedMessage id={`seatOptions.${Seats[filter]}`} />}
              active={filterStore.getSeats === filter}
              key={filter}
            />
          ))}
      </Wrapper>
    </Page>
  ))
}

export { SeatFilter }
