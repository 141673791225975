import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { CarFilterView } from '../'
import { useFilterStore } from '../../hooks'
import { CarFilter } from '../../types'

interface CarFilterListProps {}

const CarFilterList: React.FC<CarFilterListProps> = () => {
  const filterStore = useFilterStore()

  const removeCar = (car: CarFilter) => {
    filterStore.removeCar(car)
  }

  const setVariant = (car: CarFilter, variant: string) => {
    filterStore.setVariant(car, variant)
  }

  return useObserver(() => (
    <>
      {filterStore.filters.cars.length > 0 && (
        <div className="selectedCarsLabel">
          <FormattedMessage id="selectedCars" />
        </div>
      )}

      <div className="selectedCars">
        {filterStore.filters.cars.map(car => (
          <CarFilterView car={car} onVariant={setVariant} onRemove={() => removeCar(car)} key={`${car.brand}${car.model}`}></CarFilterView>
        ))}
      </div>
    </>
  ))
}

export { CarFilterList }
