import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Button, Icon } from '../'
import { useRouterStore } from '../../hooks/useStore'

const StyledButton = styled(Button as any)`
  padding: 25px;
  border-radius: 0;
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
  box-shadow: none;

  .inner {
    display: flex;
    flex-direction: column;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
  }

  .filters {
    margin-top: 15px;
    color: #5d5d5d;
    text-align: left;
  }

  :hover,
  :focus,
  :active {
    background-color: #1e1e1e !important;
    border-color: #1e1e1e !important;
    color: #fff;
  }

  .anticon {
    font-size: 23px;
    margin-right: 0px !important;
    color: #5b5f60;
    fill: #5b5f60;

    svg {
      width: auto;
      height: 15px;
    }
  }
`

interface FilterLinkProps {
  link: string
  label: string | ReactNode
}

const FilterLink: React.FC<FilterLinkProps> = ({ link, label, ...rest }) => {
  const routerStore = useRouterStore()

  const navigate = (to: string): void => {
    routerStore.push(to)
  }

  return (
    <>
      <div className="filter-link" onClick={() => navigate(link)}>
        <StyledButton block type="primary">
          <div className="inner">
            <div className="head">
              <span>{label}</span>
              <Icon type="arrow-right" />
            </div>
          </div>
        </StyledButton>
      </div>
    </>
  )
}

export { FilterLink }
