import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { $enum } from 'ts-enum-util'

import { Page, Header, CheckedButton, Footer, NavigateToFilter } from '../../components'
import { Co2EuroNorm } from '../../types'
import { useFilterStore } from '../../hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: #5d5d5d;

  ${CheckedButton} {
    margin: 8px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
`

interface Co2EuroNormFilterProps {}

const Co2EuroNormFilter: React.FC<Co2EuroNormFilterProps> = () => {
  const filterStore = useFilterStore()

  const onFilterChange = (value: Co2EuroNorm | undefined) => {
    filterStore.setCo2EuroNorm(value)
  }

  return useObserver(() => (
    <Page
      header={<Header toolbar={<NavigateToFilter />} title={<FormattedMessage id="co2EuroNorm" />} />}
      footer={<Footer showMeta={false} />}
    >
      <Wrapper>
        <CheckedButton
          onClick={() => onFilterChange(undefined)}
          label={<FormattedMessage id="filterAny" />}
          active={filterStore.filters.co2EuroNorm === undefined}
          key="any"
        />

        {$enum(Co2EuroNorm)
          .getValues()
          .map((value) => (
            <CheckedButton
              onClick={() => onFilterChange(value)}
              label={<FormattedMessage id={`co2EuroNormOptions.${$enum(Co2EuroNorm).getKeyOrDefault(value)}`} />}
              active={filterStore.filters.co2EuroNorm === value}
              key={value}
            />
          ))}
      </Wrapper>
    </Page>
  ))
}

export { Co2EuroNormFilter }
