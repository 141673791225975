import { Input as AntInput, InputNumber as AntInputNumber } from 'antd'
import styled from 'styled-components'

const AntInputGroup = AntInput.Group

const Input = styled(AntInput)`
  background-color: #1d1e1e;
  border-radius: 0px;
  border-color: #575958;
  color: #fff;
  height: 45px;

  &:focus,
  &:hover {
    border-color: #1daeee;
  }

  .ant-input {
    background-color: #1d1e1e;
    border-radius: 0px;
    border-color: #575958;
    color: #fff;
    height: 45px;

    &:focus,
    &:hover {
      border-color: #1daeee;
    }

    &-suffix,
    &-prefix {
      color: #575958;
      font-size: 18px;
    }
  }
`

const InputNumber = styled(AntInputNumber)`
  width: 100%;
  background-color: #1d1e1e;
  border-radius: 0px;
  border-color: #575958;
  color: #fff;
  height: 50px;

  .ant-input-number {
    &-input-wrap {
      height: 50px;

      input {
        height: 50px;
      }
    }

    &-handler-wrap {
      display: none;
    }
  }
`

const InputGroup = styled(AntInputGroup)``

export { Input, InputNumber, InputGroup }
