import React from 'react'
import { useObserver } from 'mobx-react'

import { FilterView, Icon, RangeFilterLabel } from '../'
import { useFilterStore } from '../../hooks'

interface MileageFilterLinkProps {}

const MileageFilterLink: React.FC<MileageFilterLinkProps> = () => {
  const filterStore = useFilterStore()

  return useObserver(() => {
    const mileage = filterStore.filters.mileage
    return (
      <FilterView label="mileageFilterLabel" link="/filter/mileage" badge={mileage.gte || mileage.lte ? <Icon type="check" /> : 0}>
        <RangeFilterLabel filter={mileage} unit={'km'} />
      </FilterView>
    )
  })
}

export { MileageFilterLink }
