import React from 'react'
import { useObserver } from 'mobx-react'
import { useIntl } from 'react-intl'

import { RangeFilterFormDropdown } from '../../../'
import { useFilterStore } from '../../../../hooks'
import { RangeFilter } from '../../../../types'

const values = [5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 125000, 150000]

const MileageFilter: React.FC = () => {
  const filterStore = useFilterStore()
  const { formatMessage } = useIntl()

  const onChange = (values: RangeFilter) => {
    filterStore.setMileageFilter(values)
  }

  return useObserver(() => {
    const mileage = filterStore.filters.mileage
    return (
      <RangeFilterFormDropdown
        label={formatMessage({ id: 'mileage' })}
        unit="km"
        value={mileage}
        values={values}
        onChange={onChange}
        formatValue
      />
    )
  })
}

export { MileageFilter }
