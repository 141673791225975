import React from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { useFilterStore } from '../../hooks'
import { RangeFilter } from '../../types'
import { CheckedButton, RangeFilterForm } from '../'
import { doesFilterMatch } from '../../helpers/rangeFilter'

const Wrapper = styled.div`
  color: #5d5d5d;
  padding: 25px;

  ${CheckedButton} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

interface PriceFilterTabProps {}

const PriceFilterTab: React.FC<PriceFilterTabProps> = () => {
  const filterStore = useFilterStore()

  const priceFilter = [
    {
      label: <FormattedMessage id="filterLabel.from" values={{ from: <FormattedNumber value={500} />, symbol: '€' }} />,
      value: {
        gte: 500,
        lte: null,
      },
    },
    {
      label: <FormattedMessage id="filterLabel.to" values={{ to: <FormattedNumber value={10000} />, symbol: '€' }} />,
      value: {
        gte: null,
        lte: 10000,
      },
    },
    {
      label: (
        <FormattedMessage
          id="filterLabel.range"
          values={{
            from: <FormattedNumber value={15000} />,
            to: <FormattedNumber value={25000} />,
            symbol: '€',
          }}
        />
      ),
      value: {
        gte: 15000,
        lte: 25000,
      },
    },
  ]

  const priceFilterChange = (value: RangeFilter) => {
    filterStore.setPriceFilter(value)
  }

  return useObserver(() => (
    <Wrapper>
      <RangeFilterForm onChange={priceFilterChange} unit="€" values={filterStore.filters.price} />
      <FormattedMessage id="popular" />
      {priceFilter.map((filter, i) => (
        <CheckedButton
          onClick={() => priceFilterChange(filter.value)}
          label={filter.label}
          active={doesFilterMatch(filterStore.filters.price, filter.value)}
          key={i}
        />
      ))}
    </Wrapper>
  ))
}

export { PriceFilterTab }
