import React from 'react'
import gql from 'graphql-tag'
import { reaction } from 'mobx'
import { useApolloClient } from '@apollo/react-hooks'

import { useCarStore } from '../../hooks'

const CAR_QUERY = gql`
  query Car($_id: ID!) {
    car(_id: $_id) {
      _id
      uid
      link
      brand
      model
      financing {
        rate
        regulatory
      }
      name
      vin
      images {
        imagepath
        imagebigthumbpath
        imagesmallthumbpath
      }
      mileage
      firstRegistration
      firstRegistrationDate
      power
      price
      priceWithoutTax
      engine {
        fuel
        gearbox
      }
      color
      categories
      emission
      fuelConsumption
      combinedPowerConsumption
      cityConsumption
      consumptionOnTheHighway
      energyEfficiencyClass
      warranty
      vatReportable
      equipmentTranslations
      location {
        name
        street
        zipCode
        city
        phone
        isGmbh
        clickAndMeetOrClickAndCollect
        clickAndMeetUrl
      }
      previousOwner
      maintenanceGuide
      similarVehicles {
        _id
        name
        model
        brand
        price
        priceWithoutTax
        energyEfficiencyClass
        emission
        fuelConsumption
        combinedPowerConsumption
        firstRegistrationDate
        power
        mileage
        link
        engine {
          fuel
        }
        images {
          imagepath
          imagebigthumbpath
          imagesmallthumbpath
        }
        wltp {
          combined
          combinedPowerConsumption
          emission
        }
      }
      wltp {
        combined
        combinedPowerConsumption
        emission
      }
    }
  }
`

const CarDetailStoreHandler: React.FC<{}> = () => {
  const carStore = useCarStore()

  const apolloClient = useApolloClient()

  reaction(
    () => carStore.carToLoad,
    (_id) => {
      carStore.setLoading(true)

      apolloClient
        .query({
          query: CAR_QUERY,
          variables: { _id },
        })
        .then(({ data }) => {
          carStore.setCar(data.car)
        })
        .finally(() => {
          carStore.setLoading(false)
        })
    }
  )

  return null
}

export default CarDetailStoreHandler
