import React from 'react'
import { useObserver } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

import { useFilterStore } from '../../../../hooks'
import { Checkbox } from '../../../'

const QualitySealFilter: React.FC = () => {
  const filterStore = useFilterStore()

  const onChange = (value: boolean) => {
    filterStore.filters.qualitySeal.youngStar = value
  }

  return useObserver(() => (
    <Checkbox checked={filterStore.filters.qualitySeal.youngStar} onChange={(e: any) => onChange(e.target.checked)}>
      <FormattedMessage id="qualitySeal.youngStar" />
    </Checkbox>
  ))
}

export { QualitySealFilter }
