import { RangeFilter } from './RangeFilter'

export enum Seats {
  'two' = 'two',
  '3-6' = '3-6',
  '>6' = '>6',
}

export const SeatValues = new Map<Seats, RangeFilter>([
  [
    Seats.two,
    {
      lte: 2,
    },
  ],
  [
    Seats['3-6'],
    {
      gte: 3,
      lte: 6,
    },
  ],
  [
    Seats['>6'],
    {
      gte: 6,
    },
  ],
])
